import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";

import {
  Chip,
  LinearProgress,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";
import { DayPicker } from "react-day-picker";
import { WithAPICall } from "../utils/apiUtil";
import { niceDate } from "../utils/fn";
import { POOL_SETTINGS } from "./data/pool-settings";

class ProtocolSettings extends React.Component {
  state = {
    searchInput: "",
    selectedNetwork: null,
    searchResults: [],
    isSearching: false,
    isEditingNetwork: false,
    isLoading: true,
    submitting: false,
    ...this.props.data,
  };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  SETTINGS = [
    {
      subheader: "Protocol Information",
      caption: "Details about this Protocol",
      items: [
        {
          id: "companyProtocolName",
          label: "Name of Protocol",
          type: "TEXT",
        },
        {
          id: "companyProtocolCode",
          label: "Company Protocol Code",
          type: "TEXT",
        },
        {
          id: "isPool",
          label: "Is this protocol for a pooled draw?",
          type: "SWITCH",
        },
        {
          id: "isUrgent",
          label: "Does this protocol require urgent processing?",
          type: "SWITCH",
        },
        {
          id: "companyProtocolType",
          label: "Type of Protocol",
          type: "OPTIONS",
          options: ["Preplacement", "Periodic", "Exit", "Targeted"],
        },
        {
          id: "companyProtocolCategory",
          label: "Protocol Category",
          type: "OPTIONS",
          options: ["Exam", "Drug and Alcohol", "Injections", "Other"],
        },
        {
          id: "companyProtocolFrequencyValue",
          label: "Protocol Frequency (Value)",
          type: "TEXT",
          isNumber: true,
          isPool: false,
        },
        {
          id: "companyProtocolFrequencyUnit",
          label: "Protocol Frequency (Unit)",
          type: "OPTIONS",
          options: ["Day(s)", "Week(s)", "Month(s)", "Year(s)"],
          isPool: false,
        },
        {
          id: "activeStatus",
          label: "Active Status",
          type: "OPTIONS",
          options: ["ACTIVE", "TEMP_CLOSED", "PERM_CLOSED"],
        },
        {
          id: "isDot",
          label: "Is this a DOT protocol?",
          type: "SWITCH",
        },
        {
          id: "regulatoryAuthority",
          label: "Regulatory Authority",
          type: "OPTIONS",
          options: [
            "FMCSA",
            "FAA",
            "PHMSA",
            "FRA",
            "USCG",
            "FTA",
            "NRC",
            "NHS",
            "<None>",
          ],
        },
        {
          id: "splitSample",
          label: "Split Sample",
          type: "SWITCH",
        },
        {
          id: "requestObservation",
          label: "Request Observation",
          type: "SWITCH",
        },
      ],
    },
  ];
  canSubmitPoolForm = () => {
    let canSubmit = true;
    const keys = POOL_SETTINGS.items.map((i) => i.id);
    keys.forEach((key) => {
      if (key === "canPoolDrawVisitGoIntoNextPeriod") return;
      if (!this.state[key] || this.state[key]?.length === 0) canSubmit = false;
    });
    return canSubmit;
  };
  updateScalarValue = async (key, value) => {
    try {
      await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
      this.props.onUpdate(key, value);
    } catch (err) {
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
    }
  };
  renderControl = (type, item, updateScalarValue = true) => {
    switch (type) {
      case "SWITCH":
        return (
          <Switch
            disabled={item.nonEditable}
            checked={this.state[item.id]}
            onChange={() => {
              this.setState(
                {
                  [item.id]: !this.state[item.id],
                },
                () => {
                  if (updateScalarValue) {
                    this.updateScalarValue(item.id, this.state[item.id]);
                  }
                }
              );
            }}
          />
        );
      case "OPTIONS":
        return (
          <Select
            sx={{ minWidth: 200 }}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState(
                {
                  [item.id]: e.target.value,
                },
                () => {
                  if (updateScalarValue) {
                    this.updateScalarValue(item.id, this.state[item.id]);
                  }
                }
              );
            }}
          >
            {item.options.map((each) => (
              <MenuItem value={each}>{each}</MenuItem>
            ))}
          </Select>
        );
      case "TEXT":
        return (
          <TextField
            value={this.state[item.id]}
            onChange={(e) => {
              let x = e.target.value;
              if (item.isNumber) {
                x = parseInt(x);
              }
              this.setState({
                [item.id]: x,
              });
            }}
            onBlur={() => {
              if (updateScalarValue) {
                this.updateScalarValue(item.id, this.state[item.id]);
              }
            }}
          />
        );
      case "DATE":
        return (
          <>
            <Chip
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${item.label}`]: true,
                });
              }}
              label={
                this.state[item.id] ? niceDate(this.state[item.id]) : "Not Set"
              }
            />
            <Popover
              open={this.state[`open_${item.label}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${item.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    [item.id]: e,
                    anchorEl: null,
                    [`open_${item.label}`]: false,
                  });
                  if (updateScalarValue) {
                    this.updateScalarValue(item.id, e);
                  }
                }}
              />
            </Popover>
          </>
        );
      default:
        return <></>;
    }
  };
  COST_MODEL_OPTIONS = ["FLAT_RATE", "USAGE_BASED", "HYBRID"];
  renderCostModelOptions = () => {
    if (!this.state.costModel) return null;
    let cm = this.state.costModel;
    switch (cm) {
      case "FLAT_RATE":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            <TextField
              value={this.state.baseCostUsd}
              onChange={(e) => {
                this.setState({
                  baseCostUsd: e.target.value,
                });
              }}
              label="Flat Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  "baseCostUsd",
                  parseFloat(this.state.baseCostUsd)
                );
              }}
            />
          </Box>
        );
      case "USAGE_BASED":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <TextField
              value={this.state.unitCostUsd}
              onChange={(e) => {
                this.setState({
                  unitCostUsd: e.target.value,
                });
              }}
              label="Unit Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  "unitCostUsd",
                  parseFloat(this.state.unitCostUsd)
                );
              }}
            />
            <TextField
              value={this.state.costModelUnitOfWork}
              onChange={(e) => {
                this.setState({
                  costModelUnitOfWork: e.target.value,
                });
              }}
              label="Unit"
              onBlur={() => {
                this.updateScalarValue(
                  "costModelUnitOfWork",
                  this.state.costModelUnitOfWork
                );
              }}
            />
          </Box>
        );
      case "HYBRID":
        return (
          <Stack spacing={2}>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                value={this.state.baseCostUsd}
                onChange={(e) => {
                  this.setState({
                    baseCostUsd: e.target.value,
                  });
                }}
                label="Flat Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    "baseCostUsd",
                    parseFloat(this.state.baseCostUsd)
                  );
                }}
              />
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <TextField
                value={this.state.unitCostUsd}
                onChange={(e) => {
                  this.setState({
                    unitCostUsd: e.target.value,
                  });
                }}
                label="Unit Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    "unitCostUsd",
                    parseFloat(this.state.unitCostUsd)
                  );
                }}
              />
              <TextField
                value={this.state.costModelUnitOfWork}
                onChange={(e) => {
                  this.setState({
                    costModelUnitOfWork: e.target.value,
                  });
                }}
                label="Unit"
                onBlur={() => {
                  this.updateScalarValue(
                    "costModelUnitOfWork",
                    this.state.costModelUnitOfWork
                  );
                }}
              />
            </Box>
          </Stack>
        );
      default:
        return null;
    }
  };
  renderCostModel = () => {
    return (
      <Stack spacing={2}>
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Select
            value={this.state.costModel}
            onChange={(e) => {
              this.setState(
                {
                  costModel: e.target.value,
                  costModelUnitOfWork: null,
                  baseCostUsd: null,
                  unitCostUsd: null,
                },
                () => {
                  this.updateScalarValue("costModel", e.target.value);
                  this.updateScalarValue("costModelUnitOfWork", null);
                  this.updateScalarValue("baseCostUsd", null);
                  this.updateScalarValue("unitCostUsd", null);
                }
              );
            }}
            sx={{
              minWidth: "100px",
            }}
          >
            {this.COST_MODEL_OPTIONS.map((each) => {
              return <MenuItem value={each}>{each}</MenuItem>;
            })}
          </Select>
        </Box>
        {this.renderCostModelOptions()}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) return <LinearProgress />;
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item, subIndex) => {
                        return (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            {item.isPool && (
                              <Chip
                                label="Pool"
                                size="small"
                                variant="outlined"
                              />
                            )}
                            <FormControlLabel
                              key={`${item.id}::${item.label}`}
                              label={item.label}
                              labelPlacement="start"
                              control={this.renderControl(item.type, item)}
                              sx={{
                                m: 0,
                                width: 1,
                                justifyContent: "space-between",
                              }}
                            />
                          </Stack>
                        );
                      })}
                    </>
                    <FormControlLabel
                      label="Cost Model"
                      labelPlacement="start"
                      control={this.renderCostModel()}
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
        {this.state.isPool && (
          <Stack component={Card} spacing={3} sx={{ p: 3, mt: 3 }}>
            <Grid key={POOL_SETTINGS.subheader} container spacing={3}>
              <Grid xs={12} md={4}>
                <ListItemText
                  primary={POOL_SETTINGS.subheader}
                  secondary={POOL_SETTINGS.caption}
                  primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                  secondaryTypographyProps={{ component: "span" }}
                />
              </Grid>
              <Grid xs={12} md={8}>
                <Stack
                  spacing={1}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    bgcolor: "background.neutral",
                  }}
                >
                  <>
                    {POOL_SETTINGS.items.map((item, subIndex) => {
                      return (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <FormControlLabel
                            key={`${item.id}::${item.label}`}
                            label={item.label}
                            labelPlacement="start"
                            control={this.renderControl(item.type, item, true)}
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          />
                        </Stack>
                      );
                    })}
                    {/* <Box mt={2}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={!this.canSubmitPoolForm()}
                      loading={this.state.submitting}
                    >
                      {this.state.submitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </Box> */}
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </div>
    );
  }
}
export default WithAPICall(ProtocolSettings);

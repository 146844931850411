import React from "react";
import { apiCallPostUnuath } from "../utils/fn";
import {
  Alert,
  Button,
  Card,
  Paper,
  Typography,
  Chip,
  LinearProgress,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import DocumentGroup from "../Documents/DocumentGroup";
import _ from "lodash";
import { niceDateUS } from "../utils/fn";
import {
  DumbDataRenderer,
  ParentDataRenderer,
} from "../DataFiles/DataRenderers/ParentDataRenderer";
import { VitalsDataViewer } from "../DataFiles/DataRenderers/vitals-data-viewer";
import { VaccinationDataViewer } from "../DataFiles/DataRenderers/vaccination-data-viewer";
import { PFTDataViewer } from "../DataFiles/DataRenderers/pft-data-viewer";
import { ChestXRayDataViewer } from "../DataFiles/DataRenderers/cxr-data-viewer";
import { DOTCardViewer } from "../DataFiles/DataRenderers/dot-card-data-viewer";
import { ECGDataViewer } from "../DataFiles/DataRenderers/ecg-data-viewer";
import { LabReportDataViewer } from "../DataFiles/DataRenderers/lab-report-data-viewer";
import { UrinalysisDataViewer } from "../DataFiles/DataRenderers/urinanalysis-data-viewer";
import { VisualAcuityDataViewer } from "../DataFiles/DataRenderers/vision-data-viewer";
import { AudiogramDataViewer } from "../DataFiles/DataRenderers/audiogram-data-viewer";
import { WhisperTestDataViewer } from "../DataFiles/DataRenderers/whispertest-data-viewer";

function extractFirstName(fullName) {
  // Trim whitespace and split the name into parts
  const nameParts = fullName.trim().split(/\s+/);
  // If there's only one part, return it
  if (nameParts.length === 1) {
    return nameParts[0];
  }
  // Check for prefixes and remove them
  const prefixes = ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."];
  while (nameParts.length > 1 && prefixes.includes(nameParts[0])) {
    nameParts.shift();
  }
  // Handle names with particles like "de", "van", "von"
  const particles = ["de", "van", "von", "der", "le", "la"];
  if (nameParts.length > 2 && particles.includes(nameParts[0].toLowerCase())) {
    return `${nameParts[0]} ${nameParts[1]}`;
  }
  // Return the first remaining part as the first name
  return nameParts[0];
}

class PatientLetter extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    data: null,
    groups: null,
    isError: false,
  };

  handlePrint = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        @page {
          size: A4 portrait;
          margin-top: 0.5in;
          margin-bottom: 0.5in;
          margin-left: 0.5in;
          margin-right: 0.5in;
        }
        body * {
          visibility: hidden;
        }
        #printableArea, #printableArea * {
          visibility: visible;
        }

        input[type="text"] {
          border: none;
          outline: none;
          background: transparent;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      }
    `;
    document.head.appendChild(style);
  };
  componentDidMount() {
    this.handlePrint();
    this.getVisitData();
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getVisitByIDForPrint", {
        visitId: this.props.id,
      });
      this.setState(
        {
          data: res,
        },
        () => {
          this.searchGroups();
        }
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  searchGroups = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getMedicalRecords", {
        visitId: this.props.id,
      });
      res = res.map((r) => {
        delete r.serviceId;
        delete r.protocolId;
        delete r.isAbnormal;
        delete r._id;
        return r;
      });
      this.setState({
        groups: res,
        // groups: [],
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  // renderGroups = () => {
  //   if (!this.state.groups) return null;
  //   if (this.state.groups.length === 0) return null;
  //   const groups_no_coc = this.state.groups.filter(
  //     (x) => x.dataType !== "CHAIN_OF_CUSTODY"
  //   );
  //   return <DumbDataRenderer listOfData={groups_no_coc} disableChips={true} />;
  // };

  renderVitals = () => {
    const vitals = _.find(this.state.groups, (g) => g.dataType === "VITALS");
    if (!vitals) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="body1" paragraph>
          The history and physical should have been discussed with you at the
          time of the exam. If you have any questions that were not addressed by
          the examining physician or have any further questions, please contact
          WorkCare at the number listed above.
        </Typography>
        <Typography variant="button" paragraph>
          Your vital signs revealed the following:
        </Typography>
        <VitalsDataViewer
          data_={vitals}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  renderVaccination = () => {
    const vaccination = _.find(
      this.state.groups,
      (g) => g.dataType === "VACCINATION"
    );
    if (!vaccination) return null;

    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your vaccination records:
        </Typography>
        <VaccinationDataViewer
          data_={vaccination}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  renderPFT = () => {
    const pft = _.find(this.state.groups, (g) => g.dataType === "PFT");
    if (!pft) return null;
    return (
      <Stack spacing={1} direction="column">
        <Chip
          label="A PFT was performed and the results were normal for the purposes of this exam."
          color="default"
          variant="outlined"
          style={{
            pageBreakInside: "avoid",
          }}
        />
        {/* <Typography variant="button" paragraph>
          Your pulmonary function test revealed the following:
        </Typography>
        <PFTDataViewer
          data_={pft}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        /> */}
      </Stack>
    );
  };

  renderCxr = () => {
    const cxr = _.find(this.state.groups, (g) => g.dataType === "CXR");
    if (!cxr) return null;
    return (
      <Stack spacing={1} direction="column">
        {/* <Typography variant="button" paragraph>
          Your Chest X-Ray revealed the following:
        </Typography> */}
        <Chip
          label="A CXR was performed and the results were normal for the purposes of this exam."
          color="default"
          variant="outlined"
          style={{
            pageBreakInside: "avoid",
          }}
        />
        {/* <ChestXRayDataViewer
          data_={cxr}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        /> */}
      </Stack>
    );
  };

  renderDotCard = () => {
    const dotCard = _.find(this.state.groups, (g) => g.dataType === "DOT_CARD");
    if (!dotCard) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          DOT Card:
        </Typography>
        <DOTCardViewer
          data_={dotCard}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  renderEcg = () => {
    const ecg = _.find(this.state.groups, (g) => g.dataType === "ECG");
    if (!ecg) return null;
    return (
      <Stack spacing={1} direction="column">
        <Chip
          label="An ECG was performed and the results were normal for the purposes of this exam."
          color="default"
          variant="outlined"
          style={{
            pageBreakInside: "avoid",
          }}
        />
        {/* <Typography variant="button" paragraph>
          Your ECG revealed the following:
        </Typography>
        <ECGDataViewer
          data_={ecg}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        /> */}
      </Stack>
    );
  };

  renderLabReport = () => {
    const labReport = _.find(
      this.state.groups,
      (g) => g.dataType === "LAB_REPORT"
    );
    if (!labReport) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your lab results:
        </Typography>
        <LabReportDataViewer
          data_={labReport}
          disableHeader={true}
          style={{}}
        />
      </Stack>
    );
  };

  renderUrinalysis = () => {
    const urinalysis = _.find(
      this.state.groups,
      (g) => g.dataType === "URINANALYSIS"
    );
    if (!urinalysis) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your urinalysis revealed the following:
        </Typography>
        <UrinalysisDataViewer
          data_={urinalysis}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  renderVision = () => {
    const vision = _.find(this.state.groups, (g) => g.dataType === "VISION");
    if (!vision) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your vision checkup revealed the following:
        </Typography>
        <VisualAcuityDataViewer
          data_={vision}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  renderAudiogram = () => {
    const audiogram = _.find(
      this.state.groups,
      (g) => g.dataType === "AUDIOGRAM"
    );
    if (!audiogram) return null;
    const hasHCP = _.find(
      this.state.data.protocolInfos,
      (p) =>
        p.companyProtocolName.includes("hcp") ||
        p.companyProtocolName.toLowerCase().includes("hearing conservation")
    );
    if (hasHCP == null) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your audiogram revealed the following:
        </Typography>
        <AudiogramDataViewer
          data_={audiogram}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
            scrollbarWidth: "none",
          }}
        />
      </Stack>
    );
  };

  renderWhisperTest = () => {
    const whisperTest = _.find(
      this.state.groups,
      (g) => g.dataType === "WHISPER_TEST"
    );
    if (!whisperTest) return null;
    return (
      <Stack spacing={1} direction="column">
        <Typography variant="button" paragraph>
          Your whisper test revealed the following:
        </Typography>
        <WhisperTestDataViewer
          data_={whisperTest}
          disableHeader={true}
          style={{
            pageBreakInside: "avoid",
          }}
        />
      </Stack>
    );
  };

  getWorkStatus = () => {};

  render() {
    if (this.state.isLoading) return <LinearProgress />;
    return (
      <Paper
        sx={{
          //  p: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div id="printableArea">
          <Stack spacing={1}>
            <Box
              component="img"
              sx={{
                // height: 150,
                width: "auto",
                // maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Company Logo"
              src="/logo/crop_logo_full.png"
            />
            <Typography variant="body2" paragraph>
              300 S. Harbor Blvd., Suite 600, Anaheim, CA 92805
              <br />
              Ph: (800) 455-6155 Fax: (714) 456 2154
            </Typography>
            <Typography variant="button" paragraph>
              Personal and Confidential
            </Typography>
            <Typography variant="body1" paragraph>
              {this.state.data?.employee.employeeName}
              <br />
              {this.state.data?.employee.employeeAddress}
              <br />
              {this.state.data?.employee.employeeCity},{" "}
              {this.state.data?.employee.employeeState},{" "}
              {this.state.data?.employee.employeeZipCode}
            </Typography>
            <Typography variant="body1" paragraph>
              Dear {extractFirstName(this.state.data?.employee.employeeName)},
            </Typography>
            <Typography variant="body1" paragraph>
              I am writing with regards to your recent examination on{" "}
              {niceDateUS(this.state.data?.providerEncounterOnDate)} which was
              performed only to assess whether you have any medical
              conditions/issues that could interfere with your ability to
              perform work. It is not meant to be a substitute for regular
              medical care or a complete medical examination.
            </Typography>
            {/* yes */}
            {this.renderVitals()}
            {/* no */}
            {/* {this.renderVision()}   */}
            {/* normal/abnormal */}
            {/* {this.renderUrinalysis()} */}
            {/* no */}
            {/* {this.renderWhisperTest()} */}
            {/* only if in HCP?? */}
            {/* {this.renderAudiogram()} */}
            {/* checkbox indicating we did it */}
            {this.renderPFT()}
            {/* checkbox indicating we did it */}
            {this.renderEcg()}
            {/* checkbox indicating we did it + b-read */}
            {this.renderCxr()}
            {/* {this.renderVaccination()} // only if we order the visit */}
            {/* <div style={{ pageBreakAfter: "always" }} /> */}
            {/* include after letter */}
            {/* {this.renderLabReport()} */}
            {/* <Typography variant="body1" paragraph>
              If you have any questions that were not addressed by the examining
              physician or have any further questions, please contact WorkCare
              at the number listed above.
            </Typography> */}
            <Typography variant="body1" paragraph>
              In addition to the results, the following are additional comments
              regarding your medical examination. You should share this
              information with your personal physician:
            </Typography>
            {"Routine follow up with your personal physician."}
            {/* <Typography variant="body1" paragraph>
            {clearance.clearanceNote}
          </Typography> */}
            <Typography variant="body1" paragraph>
              As a result of the above medical conditions, the following work
              status and restrictions are being recommended to your employer:
            </Typography>
            {"NO WORK RESTRICTIONS"}
            {/* <Typography variant="body1" paragraph>
            {clearance.clearanceLanguage}
          </Typography> */}
            {/* <Typography variant="body1" paragraph>
            I appreciate the opportunity to review your examination for{" "}
            {company.name}. If you have any questions now or in the future,
            please feel free to call me at (800) 455-6155.
          </Typography> */}
            <Typography variant="body1" paragraph>
              Sincerely,
            </Typography>
            {this.state.data?.assignedMD?.signatureImagePsUrl && (
              <img
                src={this.state.data?.assignedMD?.signatureImagePsUrl}
                alt="signature"
                style={{ width: "100px" }}
              />
            )}
            <Typography variant="body1" paragraph>
              <br />
              {this.state.data?.assignedMD?.name || "Dr. Jeffrey Jacobs"}
            </Typography>
            <Typography variant="body2" paragraph>
              If you would like further information on the laboratory tests and
              a general explanation of test results, please visit our web site
              at <a href="https://www.workcare.com">https://www.workcare.com</a>
              . Click on "Knowledge Center/Resources" and use the "Health Fact
              Sheets" link to access this information.
            </Typography>
          </Stack>
        </div>
      </Paper>
    );
  }
}

export default PatientLetter;

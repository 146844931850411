import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";

import React from "react";
import {
  Autocomplete,
  Chip,
  Popover,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import Iconify from "src/components/iconify";
import { Box } from "@mui/system";
import { ALL_LAB_NAMES } from "src/assets/data/lab-names";

class ProviderSettings extends React.Component {
  state = {
    searchInput: "",
    selectedNetwork: null,
    searchResults: [],
    isSearching: false,
    isEditingNetwork: false,
    isLoading: true,
  };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  SETTINGS = [
    {
      subheader: "Provider Information",
      caption: "Details about Provider",
      items: [
        { id: "isActive", label: "This is an active provider", type: "SWITCH" },
        {
          id: "PART_OF_NETWORK",
          label: "Part of Network",
          type: "AUTOCOMPLETE",
        },
        {
          id: "providerType",
          label: "Type of Provider",
          type: "OPTIONS",
          options: ["Lab", "Clinic"],
        },
        {
          id: "isInternalPreferred",
          label: "Preferred Provider for WorkCare",
          type: "SWITCH",
        },
        {
          id: "providerLastAuditDate",
          label: "Last Audit Date",
          type: "DATE",
        },
        {
          id: "hasMobileUnit",
          label: "Has Mobile Unit?",
          type: "SWITCH",
        },
        {
          id: "hasOnsiteServices",
          label: "Provides Onsite Services?",
          type: "SWITCH",
        },
        {
          id: "canProvidePhysicalTherapy",
          label: "Can provide Physical Therapy Services?",
          type: "SWITCH",
        },
        {
          id: "supportedLabs",
          label: "Works with Labs",
          type: "MULTI_OPTIONS",
          options: ALL_LAB_NAMES,
        },
      ],
    },
    {
      subheader: "Examiner Availability",
      caption: "Types of examiners available with this provider",
      items: [
        {
          id: "isDOTCertifiedExaminerAvailable",
          label: "DOT Certified Examiner",
          type: "SWITCH",
        },
        {
          id: "isNIOSHCertifiedTechnicianAvailable",
          label: "NIOSH Certified Technician",
          type: "SWITCH",
        },
        {
          id: "isOGUKCertifiedExaminerAvailable",
          label: "OGUK Certified Examiner",
          type: "SWITCH",
        },
        {
          id: "isCertifiedNorwegianSeafarerExaminerAvailable",
          label: "Certified Norwegian Seafarer Examiner",
          type: "SWITCH",
        },
      ],
    },
    {
      subheader: "Injury Capabilities",
      caption: "Injury capabilities of this provider",
      items: [
        {
          id: "isInjuryCapable",
          label: "Injury Capable",
          type: "SWITCH",
        },
        {
          id: "canTreatFractures",
          label: "Can Treat Fractures",
          type: "SWITCH",
        },
        {
          id: "canTreatEyeInjuries",
          label: "Can Treat Eye Injuries",
          type: "SWITCH",
        },
        {
          id: "canTreatLacerations",
          label: "Can Treat Lacerations",
          type: "SWITCH",
        },
        {
          id: "canTreatSkinBurns",
          label: "Can Treat Skin Burns",
          type: "SWITCH",
        },
        {
          id: "canTreatRashes",
          label: "Can Treat Rashes",
          type: "SWITCH",
        },
        {
          id: "canTreatToxicExposures",
          label: "Can Treat Toxic Exposures",
          type: "SWITCH",
        },
      ],
    },
  ];

  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost("/provider/providerUpdateScalarValue", {
        providerId: this.props.data._id,
        key: key,
        value: value,
      });
    } catch (err) {
      cogoToast.error("Error Updating Provider Data");
      console.log(err);
    }
  };
  renderControl = (type, item) => {
    switch (type) {
      case "SWITCH":
        return (
          <Switch
            disabled={item.nonEditable}
            checked={this.state[item.id]}
            onChange={() => {
              this.setState(
                {
                  [item.id]: !this.state[item.id],
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          />
        );
      case "OPTIONS":
        return (
          <Select
            sx={{ minWidth: 200 }}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState(
                {
                  [item.id]: e.target.value,
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          >
            {item.options.map((each) => (
              <MenuItem value={each}>{each}</MenuItem>
            ))}
          </Select>
        );
      case "MULTI_OPTIONS":
        return (
          <Select
            sx={{ minWidth: 200 }}
            multiple
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState(
                {
                  [item.id]: e.target.value,
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          >
            {item.options.map((ln) => (
              <MenuItem key={ln.key} value={ln.key}>
                {ln.value}
              </MenuItem>
            ))}
          </Select>
        );

      case "TEXT":
        return (
          <TextField
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState({
                [item.id]: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(item.id, this.state[item.id]);
            }}
          />
        );
      case "DATE":
        return (
          <>
            <Chip
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${item.label}`]: true,
                });
              }}
              label={
                this.state[item.id] ? niceDate(this.state[item.id]) : "Not Set"
              }
            />
            <Popover
              open={this.state[`open_${item.label}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${item.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    [item.id]: e,
                    anchorEl: null,
                    [`open_${item.label}`]: false,
                  });
                  this.updateScalarValue(item.id, e);
                }}
              />
            </Popover>
          </>
        );
    }
  };
  searchNetworks = async () => {
    if (!this.state.searchInput) return;
    if (this.state.searchInput.length < 3) return;
    if (this.state.searchInput === "undefined") return;
    try {
      let res = await this.props.apiCallPostCancellable(
        "/provider/providerNetworkSearch",
        {
          query: this.state.searchInput,
        }
      );

      if (res) {
        this.setState({
          isSearching: false,
          searchResults: res.map((each) => {
            return {
              mainText: each.networkName,
              secondaryText: each.networkState,
              itemId: each._id,
              item: each,
            };
          }),
        });
      }
    } catch (err) {}
  };
  renderNetworkSelector = () => {
    if (!this.state.isEditingNetwork) {
      if (
        !this.state.providerNetwork ||
        !this.state.providerNetwork.networkName
      ) {
        return (
          <FormControlLabel
            label="Provider Network"
            labelPlacement="start"
            control={
              <Chip
                size="large"
                label="No Network Selected"
                deleteIcon={<Iconify icon="mdi:pencil-outline" />}
                onDelete={() => {
                  this.setState({
                    isEditingNetwork: true,
                  });
                }}
              />
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
        );
      } else {
        return (
          <FormControlLabel
            label="Provider Network"
            labelPlacement="start"
            control={
              <Stack direction="row" spacing={1}>
                <Chip
                  label={this.state.providerNetwork.networkName}
                  deleteIcon={<Iconify icon="mdi:pencil-outline" />}
                  onDelete={() => {
                    this.setState({
                      isEditingNetwork: true,
                    });
                  }}
                />{" "}
                <Button
                  size="small"
                  href={`/networks/${this.state.providerNetwork._id}`}
                  variant="outlined"
                  // target="non_blank"
                  startIcon={<Iconify icon="mdi:arrow-top-right" />}
                >
                  Network Details
                </Button>
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: "space-between",
            }}
          />
        );
      }
    }
    return (
      <FormControlLabel
        label="Provider Network"
        labelPlacement="start"
        control={
          <Stack>
            <Autocomplete
              sx={{ width: "300px" }}
              disableClearable
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option?.mainText
              }
              filterOptions={(x) => x}
              options={this.state.isSearching ? [] : this.state.searchResults}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={this.state.selectedNetwork}
              noOptionsText={
                this.state.isSearching ? "Searching..." : "No results found"
              }
              onChange={(_, value) => {
                this.setState(
                  {
                    selectedNetwork: value,
                    searchResults: [value, ...this.state.searchResults],
                  },
                  () => {
                    this.updateScalarValue("providerNetwork", value.itemId);
                    this.setState({
                      providerNetwork: {
                        networkName: value.item.networkName,
                        _id: value.item._id,
                      },
                      isEditingNetwork: false,
                      searchResults: [],
                      searchInput: "",
                      selectedNetwork: null,
                    });
                  }
                );
              }}
              onInputChange={(_, e) => {
                this.setState(
                  {
                    searchInput: e,
                  },
                  () => {
                    this.searchNetworks();
                  }
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search for a network" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <Iconify icon="mdi:map-marker" />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: "calc(100% - 44px)",
                          wordWrap: "break-word",
                        }}
                      >
                        <Box component="span">{option?.mainText}</Box>
                        <Typography variant="body2" color="text.secondary">
                          {option?.secondaryText}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
            <Button variant="contained">Cancel</Button>
          </Stack>
        }
        sx={{
          m: 0,
          width: 1,
          justifyContent: "space-between",
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item, subIndex) => {
                        if (item.type === "AUTOCOMPLETE") {
                          return this.renderNetworkSelector();
                        }
                        if (
                          index === 2 &&
                          subIndex >= 1 &&
                          !this.state.isInjuryCapable
                        )
                          return null;

                        return (
                          <FormControlLabel
                            key={item.id}
                            label={item.label}
                            labelPlacement="start"
                            control={this.renderControl(item.type, item)}
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          />
                        );
                      })}
                    </>
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(ProviderSettings);

import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

const fieldNameLookup = {
  colorVision: "Color Vision",
  leftEyePeripheralVision: "Left Eye Peripheral Vision",
  rightEyePeripheralVision: "Right Eye Peripheral Vision",
  isMonocular: "Is Monocular",
  wasReferredToOpthalmologistOrOptometrist:
    "Referred to Ophthalmologist/Optometrist",
};

export const VisualAcuityDataViewer = ({
  data_,
  onUpdate,
  disableHeader = false,
  style = {},
}) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);

  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };
  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    setData({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => abnormalFields.includes(field);

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const handleAcuityChange = (index, field, value) => {
    const updatedAcuity = [...(data.acuity || [])];
    updatedAcuity[index] = { ...updatedAcuity[index], [field]: value };
    setData({ ...data, acuity: updatedAcuity });
    setHasChange(true);
  };

  const toggleAcuityAbnormal = (index) => {
    const updatedAcuity = [...(data.acuity || [])];
    updatedAcuity[index] = {
      ...updatedAcuity[index],
      isAbnormal: !updatedAcuity[index].isAbnormal,
    };
    setData({ ...data, acuity: updatedAcuity });
    setHasChange(true);
  };

  const addAcuityItem = () => {
    setData({
      ...data,
      acuity: [
        ...(data.acuity || []),
        {
          measurementType: "",
          correctionType: "",
          leftEyeAcuity: "",
          rightEyeAcuity: "",
          bothEyesAcuity: "",
          isAbnormal: false,
        },
      ],
    });
    setHasChange(true);
  };

  const removeAcuityItem = (index) => {
    const updatedAcuity = (data.acuity || []).filter((_, i) => i !== index);
    setData({ ...data, acuity: updatedAcuity });
    setHasChange(true);
  };

  const renderAcuityRows = () => {
    if ((data.acuity || []).length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <Typography variant="body2" color="textSecondary">
              No acuity measurements
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return (data.acuity || []).map((acuity, index) => (
      <TableRow
        key={index}
        sx={{
          bgcolor: acuity.isAbnormal ? theme.palette.warning.light : "inherit",
        }}
      >
        <TableCell>
          <FormControl fullWidth size="small">
            <Select
              value={acuity.measurementType || ""}
              onChange={(e) =>
                handleAcuityChange(index, "measurementType", e.target.value)
              }
            >
              <MenuItem value="Near">Near</MenuItem>
              <MenuItem value="Far">Far</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <FormControl fullWidth size="small">
            <Select
              value={acuity.correctionType || ""}
              onChange={(e) =>
                handleAcuityChange(index, "correctionType", e.target.value)
              }
            >
              <MenuItem value="Corrected">Corrected</MenuItem>
              <MenuItem value="Uncorrected">Uncorrected</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={acuity.leftEyeAcuity || ""}
            onChange={(e) =>
              handleAcuityChange(index, "leftEyeAcuity", e.target.value)
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={acuity.rightEyeAcuity || ""}
            onChange={(e) =>
              handleAcuityChange(index, "rightEyeAcuity", e.target.value)
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={acuity.bothEyesAcuity || ""}
            onChange={(e) =>
              handleAcuityChange(index, "bothEyesAcuity", e.target.value)
            }
          />
        </TableCell>
        <TableCell>
          <Box display={disableHeader ? "none" : "flex"} alignItems="center">
            <Tooltip
              title={acuity.isAbnormal ? "Mark as normal" : "Mark as abnormal"}
              arrow
            >
              <IconButton
                size="small"
                onClick={() => toggleAcuityAbnormal(index)}
              >
                <Iconify
                  icon={acuity.isAbnormal ? "ep:warning-filled" : "mdi:tick"}
                />
              </IconButton>
            </Tooltip>
            {onUpdate && (
              <Tooltip title="Remove measurement" arrow>
                <IconButton
                  size="small"
                  onClick={() => removeAcuityItem(index)}
                >
                  <Iconify icon="hugeicons:delete-02" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
    ));
  };

  const renderRow = (field) => (
    <TableRow
      key={field}
      sx={{
        bgcolor: isAbnormal(field) ? theme.palette.warning.light : "inherit",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {fieldNameLookup[field] || field}
          <Tooltip title={getTooltipText(field)} arrow>
            <Box
              component="span"
              sx={{
                display: disableHeader ? "none" : "inline-flex",
                ml: 1,
                cursor: "pointer",
              }}
            >
              <Iconify
                icon={isAbnormal(field) ? "ep:warning-filled" : "mdi:tick"}
                onClick={() => toggleAbnormal(field)}
              />
            </Box>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        {["isMonocular", "wasReferredToOpthalmologistOrOptometrist"].includes(
          field
        ) ? (
          <FormControl fullWidth size="small">
            <Select
              value={data[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <TextField
            fullWidth
            size="small"
            value={data[field] || ""}
            onChange={(e) => handleChange(field, e.target.value)}
          />
        )}
      </TableCell>
    </TableRow>
  );

  const renderHeader = () => {
    const abnormalCount =
      abnormalFields.length +
      (data.acuity || []).filter((a) => a.isAbnormal).length;

    if (abnormalCount === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Vision</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = [
      ...abnormalFields.map((field) => fieldNameLookup[field] || field),
      ...(data.acuity || [])
        .filter((a) => a.isAbnormal)
        .map((_, index) => `Acuity Measurement ${index + 1}`),
    ].join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Visual Acuity | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
        ...style,
      }}
    >
      <Card
        sx={{
          display: disableHeader ? "none" : "",
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0 ||
            (data.acuity || []).some((a) => a.isAbnormal)
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="subtitle1">Acuity Measurements</Typography>
        {onUpdate && (
          <Tooltip title="Add acuity measurement" arrow>
            <IconButton onClick={addAcuityItem}>
              <Iconify icon="simple-line-icons:plus" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Measurement Type</TableCell>
              <TableCell>Correction Type</TableCell>
              <TableCell>Left Eye</TableCell>
              <TableCell>Right Eye</TableCell>
              <TableCell>Both Eyes</TableCell>
              {onUpdate && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{renderAcuityRows()}</TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom>
          Additional Information
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {Object.keys(data)
                .filter(
                  (key) => !["dataType", "hasAbnormal", "acuity"].includes(key)
                )
                .map(renderRow)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

import React, { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";

const fieldNameLookup = {
  height: "Height",
  weight: "Weight",
  pulse: "Pulse",
  bloodPressure: "Blood Pressure",
  bodyTemperature: "Body Temperature",
};

function convertToInches_(heightString) {
  if (!heightString) {
    return heightString;
  }
  // Remove all spaces and convert to lowercase
  const height = heightString.replace(/\s/g, "").toLowerCase();

  // Check for cm format
  if (height.includes("cm")) {
    const cm = parseFloat(height);
    return Math.round(cm / 2.54);
  }

  // Check for feet and inches format
  const feetAndInches =
    height.match(/(\d+)'(\d+)(?:"|in)?/) ||
    height.match(/(\d+)ft(\d+)(?:"|in)?/);
  if (feetAndInches) {
    const feet = parseInt(feetAndInches[1]);
    const inches = parseInt(feetAndInches[2]);
    return feet * 12 + inches;
  }

  // Check for inches format
  if (height.includes("in") || height.endsWith('"')) {
    return parseInt(height);
  }

  // Check for feet only format
  const feetOnly = height.match(/(\d+)'/) || height.match(/(\d+)ft/);
  if (feetOnly) {
    return parseInt(feetOnly[1]) * 12;
  }

  // Check for mixed format like 5'9
  const mixed = height.match(/(\d+)'(\d+)/);
  if (mixed) {
    const feet = parseInt(mixed[1]);
    const inches = parseInt(mixed[2]);
    return feet * 12 + inches;
  }

  // If no known format is detected, return null
  return null;
}

function convertToPounds_(weightString) {
  if (!weightString) {
    return weightString;
  }
  // Remove all spaces, convert to lowercase, and remove any trailing periods
  const weight = weightString
    .replace(/\s/g, "")
    .toLowerCase()
    .replace(/\.$/, "");

  // Check for kg or kilograms format
  if (weight.includes("kg") || weight.includes("kilogram")) {
    const kg = parseFloat(weight);
    return Math.round(kg * 2.20462);
  }

  // Check for lbs or lb format
  const poundsMatch = weight.match(/^(\d+(?:\.\d+)?)(lbs?|pounds?)?$/);
  if (poundsMatch) {
    return Math.round(parseFloat(poundsMatch[1]));
  }

  // If no known format is detected, return null
  return null;
}

const convertToAmerican = (value, field) => {
  if (!value) {
    return "";
  }
  if (field === "height") {
    return convertToInches_(value).toString() + " inches";
  }
  if (field === "weight") {
    return convertToPounds_(value).toString() + " pounds";
  }
  return value;
};

export const VitalsDataViewer = ({
  data_,
  onUpdate,
  disableHeader = false,
  style = {},
}) => {
  const theme = useTheme();
  const [data, setData] = useState(data_);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };
  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    setData({ ...data, hasAbnormal: newAbnormalFields });
  };

  const isAbnormal = (field) => {
    if (disableHeader) return false;
    return abnormalFields.includes(field);
  };

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };

  const handleBPChange = (index, field, value) => {
    const updatedBP = [...(data.bloodPressure || [])];
    updatedBP[index] = { ...updatedBP[index], [field]: value };
    setData({ ...data, bloodPressure: updatedBP });
    setHasChange(true);
  };

  const toggleBPAbnormal = (index) => {
    const updatedBP = [...(data.bloodPressure || [])];
    updatedBP[index] = {
      ...updatedBP[index],
      isAbnormal: !updatedBP[index].isAbnormal,
    };
    setData({ ...data, bloodPressure: updatedBP });
  };

  const addBPItem = () => {
    setData({
      ...data,
      bloodPressure: [
        ...(data.bloodPressure || []),
        { bloodPressure: "", bloodPressureNotes: "", isAbnormal: false },
      ],
    });
    setHasChange(true);
  };

  const removeBPItem = (index) => {
    const updatedBP = (data.bloodPressure || []).filter((_, i) => i !== index);
    setData({ ...data, bloodPressure: updatedBP });
    setHasChange(true);
  };

  const renderRow = (field) => (
    <TableRow
      key={field}
      sx={{
        bgcolor: isAbnormal(field) ? theme.palette.warning.light : "inherit",
      }}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {fieldNameLookup[field] || field}
          <Tooltip title={getTooltipText(field)} arrow>
            <Box
              component="span"
              sx={{
                display: disableHeader ? "none" : "inline-flex",
                ml: 1,
                cursor: "pointer",
              }}
            >
              <Iconify
                icon={isAbnormal(field) ? "ep:warning-filled" : "mdi:tick"}
                onClick={() => toggleAbnormal(field)}
              />
            </Box>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          size="small"
          value={
            disableHeader
              ? convertToAmerican(data[field], field)
              : data[field] || ""
          }
          onChange={(e) => handleChange(field, e.target.value)}
        />
      </TableCell>
    </TableRow>
  );

  const renderBPRows = () => {
    if ((data.bloodPressure || []).length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <Typography variant="body2" color="textSecondary">
              No blood pressure readings
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return (data.bloodPressure || []).map((bp, index) => (
      <TableRow
        key={index}
        sx={{
          bgcolor:
            bp.isAbnormal && !disableHeader
              ? theme.palette.warning.light
              : "inherit",
        }}
      >
        <TableCell>
          <TextField
            fullWidth
            size="small"
            value={bp.bloodPressure || ""}
            onChange={(e) =>
              handleBPChange(index, "bloodPressure", e.target.value)
            }
          />
        </TableCell>
        <TableCell>
          {!disableHeader && (
            <TextField
              fullWidth
              size="small"
              value={bp.bloodPressureNotes || ""}
              onChange={(e) =>
                handleBPChange(index, "bloodPressureNotes", e.target.value)
              }
            />
          )}
        </TableCell>
        <TableCell>
          <Box display={disableHeader ? "none" : "flex"} alignItems="center">
            <Tooltip
              title={bp.isAbnormal ? "Mark as normal" : "Mark as abnormal"}
              arrow
            >
              <IconButton size="small" onClick={() => toggleBPAbnormal(index)}>
                <Iconify
                  icon={bp.isAbnormal ? "ep:warning-filled" : "mdi:tick"}
                />
              </IconButton>
            </Tooltip>
            {onUpdate && (
              <Tooltip title="Remove reading" arrow>
                <IconButton size="small" onClick={() => removeBPItem(index)}>
                  <Iconify icon="hugeicons:delete-02" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
    ));
  };

  const renderHeader = () => {
    if (disableHeader) {
      return null;
    }
    const abnormalCount =
      abnormalFields.length +
      (data.bloodPressure || []).filter((bp) => bp.isAbnormal).length;

    if (abnormalCount === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Vitals</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = [
      ...abnormalFields.map((field) => fieldNameLookup[field] || field),
      ...(data.bloodPressure || [])
        .filter((bp) => bp.isAbnormal)
        .map((_, index) => `Blood Pressure Reading ${index + 1}`),
    ].join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Vitals | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
        ...style,
      }}
    >
      <Card
        sx={{
          display: disableHeader ? "none" : "",
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0 ||
            (data.bloodPressure || []).some((bp) => bp.isAbnormal)
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(data)
              .filter(
                (key) =>
                  !["dataType", "hasAbnormal", "bloodPressure"].includes(key)
              )
              .map(renderRow)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography variant="subtitle1">Blood Pressure Readings</Typography>
          {onUpdate && (
            <Tooltip title="Add blood pressure reading" arrow>
              <IconButton onClick={addBPItem}>
                <Iconify icon="simple-line-icons:plus" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableBody>{renderBPRows()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import React from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Iconify from "src/components/iconify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { NoResultView, View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import _ from "lodash";
import ServiceCreate from "./ServiceCreate";

class ListServices extends React.Component {
  state = {
    query: "",
    isSearching: false,
    quickSearchText: "",
    enableAdvancedFilter: false,
    hasLoadingStarted: false,
    list: [],
    openServiceCreate: false,
    selectedRows: [],
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
    },
  };
  buffer = [];
  timer = null;
  componentDidMount() {
    this.search();
  }
  search = async () => {
    // return;
    // this.setState({ isSearching: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      isSearching: true,
      hasLoadingStarted: false,
    });
    // this.buffer = [];
    // this.timer = setInterval(() => {
    //   let bufferLength = this.buffer.length;
    //   let listLength = this.state.list.length;
    //   let diff = bufferLength - listLength;

    //   if (diff > 0) {
    //     this.setState({
    //       list: [
    //         ...this.state.list,
    //         ...this.buffer.slice(listLength, bufferLength),
    //       ],
    //     });
    //   }
    // }, 250);
    let payload = {
      query: "",
      skip: 0,
      limit: 1000,
    };

    const data = await this.props.apiCallPost(
      "/master-service/masterServiceFts",
      payload
    );
    this.setState({
      list: data,
      isSearching: false,
    });

    // await this.props.apiCallPostStreaming(
    //   "/master-service/masterServiceGetAllStreaming",
    //   payload,
    //   (data) => {
    //     if (!this.state.hasLoadingStarted) {
    //       this.setState({ hasLoadingStarted: true });
    //     }
    //     this.buffer.push(...data);
    //   },
    //   () => {
    //     this.setState(
    //       { isSearching: false, hasLoadingStarted: false },
    //       async () => {
    //         // wait 4 seconds
    //         await new Promise((resolve) => setTimeout(resolve, 4000));
    //         clearInterval(this.timer);
    //       }
    //     );
    //   }
    // );
  };
  COLUMNS = [
    {
      headerName: "Service Name",
      field: "serviceName",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.renderName(row),
      headerCheckboxSelection: this.props.fromProviderPage,
      checkboxSelection: this.props.fromProviderPage,
    },
    {
      headerName: "Service Code",
      field: "serviceCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Type",
      field: "serviceType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Performed By",
      field: "serviceRequiresProviderType",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },

    {
      headerName: "Internally Processed By WorkCare",
      field: "isHandledByWorkCare",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => this.booleanGetter(row),
    },

    {
      headerName: "Documents",
      field: "documentTypes",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (row) => (row?.value || []).join(", "),
    },

    {
      headerName: "CRL Panel Code",
      field: "crlPanelCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "LabCorp Panel Code",
      field: "labCorpPanelCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Quest Panel Code",
      field: "questPanelCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Pactox Panel Code",
      field: "pactoxPanelCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "National Jewish Panel Code",
      field: "njPanelCode",
      enableRowGroup: true,
      enablePivot: true,
      filter: "agMultiColumnFilter",
    },
  ];
  booleanGetter = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        {row.value ? "Yes" : "No"}
      </span>
    );
  };
  renderName = (row) => {
    if (this.state.enableAdvancedFilter) {
      return row.value;
    }
    return (
      <span
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          href={`/services/${row.data._id}`}
          variant="outlined"
          startIcon={<Iconify icon="mingcute:external-link-line" />}
        >
          {row.value}
        </Button>
      </span>
    );
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi }, () => {
      if (
        this.props.fromProviderPage &&
        this.props.selectedData &&
        this.props.selectedData.length
      ) {
        let dd = this.props.selectedData.map((each) => each._id);
        const nodesToSelect = [];
        this.state.gridApi.forEachNode((node) => {
          if (node.data && dd.includes(node.data._id)) {
            nodesToSelect.push(node);
          }
        });
        this.state.gridApi.setNodesSelected({
          nodes: nodesToSelect,
          newValue: true,
        });
      }
    });
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  renderGrid = () => {
    let list = this.state.list;
    if (!list || list.length === 0) {
      return <NoResultView />;
    }
    let cs = "ag-theme-quartz";
    let theme = localStorage.getItem("themeMode");
    if (theme && theme === "dark") {
      cs = "ag-theme-quartz-dark";
    }

    return (
      <div className={cs} style={{ height: "70vh" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.list}
          columnDefs={this.COLUMNS}
          defaultColDef={{
            sortable: true,
          }}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
          pagination={true}
          paginationPageSize={500}
          rowGroupPanelShow={
            this.state.enableAdvancedFilter ? "always" : "never"
          }
          aggFuncs={this.state.aggregations}
          suppressDragLeaveHidesColumns={true}
          rowDragManaged={true}
          enableCharts={true}
          enableRangeSelection={true}
          enableCellTextSelection={true}
          quickFilterText={this.state.quickSearchText}
          sideBar={this.props.fromProviderPage ? false : true}
          checkboxSelection={this.props.fromProviderPage ? true : false}
          groupSelectsChildren={true}
          animateRows={true}
          enableAdvancedFilter={this.state.enableAdvancedFilter}
          onSelectionChanged={() => {
            let rows = this.state.gridApi.getSelectedRows();
            if (!rows || rows.length === 0) {
              this.setState({
                selectedRows: [],
              });
            } else {
              this.setState({
                selectedRows: rows,
              });
            }
          }}
          rowSelection="multiple"
          rowMultiSelectWithClick
        />
      </div>
    );
  };
  renderHeader = () => {
    if (this.props.fromProviderPage) {
      return null;
    }
    return (
      <CustomBreadcrumbs
        heading="List of Services"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Services" },
          { name: "List" },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isSearching}
              onClick={() => this.setState({ openServiceCreate: true })}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Service
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  renderControlBox = () => {
    let list = this.state.list;
    if (!list) return null;
    if (list.length === 0) return null;
    return (
      <Stack direction="row" spacing={1}>
        <TextField
          disabled={this.state.isSearching}
          value={this.state.quickSearchText}
          label="Quick Search"
          onChange={(e) =>
            this.setState({
              quickSearchText: e.target.value,
            })
          }
        />
        <Button
          disabled={this.state.isSearching}
          variant="contained"
          startIcon={<Iconify icon="mingcute:filter-line" />}
          onClick={() => {
            this.setState({
              enableAdvancedFilter: !this.state.enableAdvancedFilter,
            });
          }}
        >
          {this.state.enableAdvancedFilter
            ? "Advanced Filters"
            : "Simple Filters"}
        </Button>{" "}
        <LoadingButton
          loading={this.state.isSearching}
          onClick={() => this.search()}
          variant="contained"
          startIcon={<Iconify icon="mingcute:refresh-anticlockwise-1-line" />}
        >
          Refresh Data
        </LoadingButton>{" "}
        {this.renderIfSelectionChanged()}
      </Stack>
    );
  };
  renderIfSelectionChanged = () => {
    if (!this.props.fromProviderPage) {
      return null;
    }
    if (!this.state.selectedRows || this.state.selectedRows.length === 0) {
      return null;
    }
    let txt = "Link Services To Provider";
    if (this.props.linkText) {
      txt = this.props.linkText;
    }
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon="mingcute:link-3-line" />}
        onClick={() => {
          this.props.onSelectData(this.state.selectedRows);
        }}
      >
        {txt}
      </Button>
    );
  };
  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <ServiceCreate
          isOpen={this.state.openServiceCreate}
          handleClose={() => {
            this.setState({ openServiceCreate: false });
          }}
          onCreate={(c) => {
            this.setState({
              list: [c, ...this.state.list],
              openServiceCreate: false,
            });
            window.open(`/services/${c._id}`, "non_blank");
          }}
        />
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderControlBox()}
          {this.renderGrid()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(ListServices);

"use client";
import React, { useState, useEffect } from "react";
import { Check, AlertTriangle, HelpCircle, FileText } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

const computeChainOfCustodyChecklist = (serviceId, medicalRecords) => {
  const thisServiceMR_COC = medicalRecords.find(
    (mr) => /*mr.serviceId === serviceId &&*/ mr.dataType === "CHAIN_OF_CUSTODY"
  );

  const thisServiceMR_LR = medicalRecords.find(
    (mr) => /*mr.serviceId === serviceId &&*/ mr.dataType === "LAB_REPORT"
  );

  console.log(
    ">>>computeChainOfCustodyChecklist",
    thisServiceMR_COC,
    thisServiceMR_LR
  );

  const chainOfCustodyItems = [
    {
      index: 0,
      label: "Has Specimen ID",
      value: false,
      tooltip: "Specimen ID is present on the Chain of Custody form",
    },
    {
      index: 1,
      label: "Specimen ID Matches Lab Report",
      value: false,
      tooltip: "Specimen ID on COC matches the ID on the lab report",
    },
    {
      index: 2,
      label: "DER Name & Phone on COC",
      value: false,
      tooltip:
        "Designated Employer Representative name and phone number are present",
    },
    {
      index: 3,
      label: "Reason For Test on COC",
      value: false,
      tooltip:
        "The reason for the drug test is specified on the Chain of Custody form",
    },
    {
      index: 4,
      label: "Temperature Between 90-100°F",
      value: false,
      tooltip: "Specimen temperature is marked between 90°F and 100°F",
    },
    {
      index: 5,
      label: "Donor Signature Present",
      value: false,
      tooltip: "Donor's signature is present on the Chain of Custody form",
    },
    {
      index: 6,
      label: "Donor Identifier Present",
      value: false,
      tooltip:
        "Donor's identifier (SSN, Employee Number, or DL Number) is present",
    },
  ];

  if (
    thisServiceMR_COC?.specimen_id !== null &&
    thisServiceMR_COC?.specimen_id !== ""
  ) {
    chainOfCustodyItems[0].value = true;
  }

  if (thisServiceMR_COC?.specimen_id === thisServiceMR_LR?.specimen_id) {
    chainOfCustodyItems[1].value = true;
  }

  if (
    thisServiceMR_COC?.reason_for_test !== null &&
    thisServiceMR_COC?.reason_for_test !== ""
  ) {
    chainOfCustodyItems[3].value = true;
  }

  // if (
  //   thisServiceMR_COC?.specimen_temperature !== null &&
  //   thisServiceMR_COC?.specimen_temperature >= 90 &&
  //   thisServiceMR_COC?.specimen_temperature <= 100
  // ) {
  //   chainOfCustodyItems[4].value = true;
  // }

  if (
    thisServiceMR_COC?.specimen_temperature !== null &&
    thisServiceMR_COC?.specimen_temperature === "Yes"
  ) {
    chainOfCustodyItems[4].value = true;
  }

  if (
    thisServiceMR_COC?.donor_signature !== null &&
    thisServiceMR_COC?.donor_signature !== ""
  ) {
    chainOfCustodyItems[5].value = true;
  }

  if (
    thisServiceMR_COC?.donor_name !== null &&
    thisServiceMR_COC?.donor_name !== ""
  ) {
    chainOfCustodyItems[6].value = true;
  }

  return chainOfCustodyItems;
};

const derOptionsNegative = [
  { value: "MRO negative", text: "MRO negative" },
  { value: "MRO negative, dilute", text: "MRO negative, dilute" },
];

const ClearanceStatusSelect = ({ value, onChange }) => {
  return (
    <div className="space-y-2">
      <Label
        htmlFor="clearance-case"
        className="text-sm font-medium text-gray-700"
      >
        Clearance Status
      </Label>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Select value={value} onValueChange={onChange}>
              <SelectTrigger id="clearance-case" className="w-full">
                <SelectValue placeholder="Select clearance case" />
              </SelectTrigger>
              <SelectContent>
                {derOptionsNegative.map((option, index) => (
                  <SelectItem
                    key={index}
                    value={option.value}
                    className="text-xs"
                  >
                    {option.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </TooltipTrigger>
          <TooltipContent>
            <p>Select the clearance status for this case</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export function DrugScreenClearanceNegative({
  currentStatus,
  drugService,
  onClear,
  medicalRecords,
}) {
  const { visit } = useVisitStore();
  const { callApi } = useProtectedApi();

  const [finalClearanceText, setFinalClearanceText] = useState(
    currentStatus?.finalClearanceText || ""
  );

  const chainOfCustodyItems = computeChainOfCustodyChecklist(
    drugService._id,
    medicalRecords
  );

  const onSaveClearance = async () => {
    try {
      const resp = await callApi("/visit/updateVisitClearance", {
        visitId: visit._id,
        entityId: drugService._id,
        entity: "Drug",
        drugClearance: {
          finalClearanceText,
        },
      });
      onClear(resp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card className="w-full shadow-md">
      <CardHeader>
        <CardTitle className="text-lg font-medium">
          Drug Screen Clearance
        </CardTitle>
        <CardTitle className="text-sm font-medium">
          {drugService.serviceName}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
            <span className="text-sm font-medium text-green-800">
              Drug Screen Result:
            </span>
            <span className="text-sm text-green-800 flex items-center">
              No positives found
              <Check className="ml-2 h-4 w-4 text-green-600" />
            </span>
          </div>

          <div>
            <h3 className="text-sm font-medium mb-2">Chain of Custody:</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {chainOfCustodyItems.map((item, index) => (
                <TooltipProvider key={index}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                        <span className="text-xs text-gray-700">
                          {item.label}
                        </span>
                        {item.value ? (
                          <Check className="h-4 w-4 text-green-600" />
                        ) : (
                          <AlertTriangle className="h-4 w-4 text-amber-500" />
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="text-xs max-w-xs">
                      <p>{item.tooltip}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
            </div>
          </div>

          <div>
            <div>
              <ClearanceStatusSelect
                value={finalClearanceText}
                onChange={(value) => setFinalClearanceText(value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-end text-xs text-gray-500">
            <HelpCircle className="h-3 w-3 mr-1" />
            <span>Hover over items for more information</span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  onSaveClearance();
                }}
                className="bg-blue-600 hover:bg-blue-700 text-white"
              >
                <FileText className="mr-2 h-4 w-4" />
                Preview & Process Clearance
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top" className="text-xs max-w-xs">
              <p>
                Preview and process the drug screen clearance based on the
                current information
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </CardFooter>
    </Card>
  );
}

import React, { useState } from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Box,
  Stack,
  ListItemText,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
} from "@mui/material";
import {
  Alert,
  Autocomplete,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import cogoToast from "cogo-toast";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/iconify";
import TokenContext from "src/TokenContext";
import {
  maskSSN,
  niceDate,
  niceDateTime,
  niceDateTimeUS,
  niceDateTZ,
  niceDateUS,
} from "../utils/fn";
import { VISIT_LINK_REASON_TYPES } from "src/statics/link-reasons";
import _ from "lodash";
import axios from "axios";

class CreateVisit extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: false,
    isError: false,

    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,

    // employee
    isSearchingEmployee: false,
    employeeSearchResults: [],
    currentlyInEmployee: null,
    //
    isCreatingVisit: false,
    visitId: null,

    externalAuthzCode: null,
    isBackDatedVisit: false,
    isForPoolDraw: false,
    poolDrawId: null,
    selectPoolList: [],
    // linked visits
    hasLinkedVisit: false,
    linkedVisitReason: null,
    linkedVisit: null,
    previousVisits: [],

    openModal: false,
    newEmployeeName: "",
  };
  async componentDidMount() {
    this.getCurrentUser();
    // check if url params has an employeeId, if yes
    // search for the employee and set it
    const urlParams = new URLSearchParams(window.location.search);
    const employeeId = urlParams.get("employeeId");
    if (employeeId) {
      cogoToast.loading("Loading Employee Data");
      try {
        const res = await this.props.apiCallPost("/employee/getById", {
          _id: employeeId,
        });
        this.setState({
          currentlyInEmployee: {
            data: res,
            mainText: res.employeeName,
            secondaryText: `${res.employeeNumber} | ${res.employeeOfficeEmail}`,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  getCurrentUser = async () => {
    this.setState({ isLoading: true });
    try {
      let res = await this.props.apiCallPost("/user/getCurrentUser");
      if (res.isExternal) {
        this.setState({
          currentlyInCompany: {
            mainText: res.company.companyName,
            secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
            data: res.company,
          },
          isExternal: true,
          isLoading: false,
        });
      } else {
        this.setState({
          currentlyInCompany: null,
          isExternal: false,
          isLoading: false,
        });
      }
    } catch (err) {
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite || ""}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchEmployeesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/employee/data/searchAugmentedFts",
        {
          companyId: this.state.currentlyInCompany.data._id,
          query: t,
        }
      );
      if (res) {
        this.setState({
          isSearchingEmployee: false,
          employeeSearchResults: res.map((each) => {
            return {
              mainText: each.employeeName,
              secondaryText: `${each.employeeNumber ? each.employeeNumber : "No Employee Number"} | ${each.employeeOfficeEmail || "No Employee Email"}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingEmployee: false,
        employeeSearchResults: [],
      });
      cogoToast.error("Error Searching Employees");
    }
  };
  createVisit = async () => {
    this.setState({
      isCreatingVisit: true,
    });
    try {
      if (this.state.isForPoolDraw) {
        const tasks = await this.props.apiCallPost("/task/searchTasks", {
          company: this.state.currentlyInCompany.data._id,
          poolDraw: this.state.poolDrawId,
          employee: this.state.currentlyInEmployee.data._id,
          taskInterpreter: [],
          taskFinishedIn: [],
          taskDueDateIn: [],
          taskStatus: [],
          taskTags: [],
          prefix: "",
        });
        console.log(tasks, "tasks");
        if (tasks.length === 0) {
          cogoToast.error("This employee wasn't found in the draw.");
          return;
        }
        const task = tasks[0];

        let res = await this.props.apiCallPost("/task/createVisitFromTask", {
          taskId: task._id,
          externalAuthzCode: this.state.externalAuthzCode,
          isBackDatedVisit: this.state.isBackDatedVisit,
        });
        console.log(res, "res");
        window.open(`/visits/${res.taskFinalArtifactId}`, "_self");
        this.setState({
          isCreatingVisit: false,
          visitId: res.taskFinalArtifactId,
        });
      } else {
        let res = await this.props.apiCallPost("/visit/createNewVisit", {
          companyId: this.state.currentlyInCompany.data._id,
          employeeId: this.state.currentlyInEmployee.data._id,
          externalAuthzCode: this.state.externalAuthzCode || "",
          isBackDatedVisit: this.state.isBackDatedVisit || false,
        });
        window.open(`/visits/${res._id}`, "_self");
        this.setState({
          isCreatingVisit: false,
          visitId: res._id,
        });
      }
    } catch (err) {
      this.setState({
        isCreatingVisit: false,
      });
      cogoToast.error("Error Creating Visit");
    }
  };
  renderCompanySearcher = () => {
    if (this.context.currentUser.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={async (_, value) => {
          const selectPools = await this.props.apiCallPost(
            "/company-protocol/pool/companyProtocolGetAllPools",
            {
              companyId: value.data._id,
            }
          );
          const selectPoolDraws_ = await this.props.apiCallPost(
            "/company-protocol/pool/getPoolDrawsForCompany",
            {
              companyId: value.data._id,
              year: "2024",
            }
          );
          const selectPoolDraws = selectPoolDraws_.draws;
          const selectPoolList = [];
          for (let i = 0; i < selectPools.length; i++) {
            for (let j = 0; j < selectPoolDraws.length; j++) {
              if (selectPoolDraws[j].protocol === selectPools[i]._id) {
                selectPoolList.push({
                  protocol: selectPools[i]._id,
                  drawId: selectPoolDraws[j]._id,
                  drawName: `${selectPools[i].companyProtocolName} - Drawn: ${niceDateUS(selectPoolDraws[j].drawnAt)}`,
                });
              }
            }
          }
          console.log(selectPools, "selectPools");
          console.log(selectPoolDraws, "selectPoolDraws");
          console.log(selectPoolList, "selectPoolList");

          this.setState({
            currentlyInCompany: value,
            companySearchResults: [value, ...this.state.companySearchResults],
            selectPoolList: selectPoolList,
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText || ""}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderEmployeeSearcher = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }

    return (
      <Stack>
        <Autocomplete
          disableClearable
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option?.mainText
          }
          filterOptions={(x) => x}
          options={
            this.state.isSearchingEmployee
              ? []
              : this.state.employeeSearchResults
          }
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={this.state.currentlyInEmployee}
          noOptionsText={
            this.state.isSearchingEmployee ? "Searching..." : "No results found"
          }
          onChange={async (_, value) => {
            const previousVisits = await this.props.apiCallPost(
              "/visit/getVisitByEmployeeId",
              {
                employeeId: value.data._id,
              }
            );
            this.setState({
              currentlyInEmployee: value,
              isSearchingEmployee: false,
              employeeSearchResults: [
                value,
                ...this.state.employeeSearchResults,
              ],
              previousVisits: previousVisits,
            });
          }}
          onInputChange={(_, e) => {
            if (e === "") return null;
            if (!e) return null;
            // if (e.length < 4) return null;
            this.setState(
              {
                isSearchingEmployee: true,
              },
              () => {
                this.searchEmployeesFTS(e);
              }
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Employees (Use Name, SSN, Personnel Number or Applicant ID)"
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ wordWrap: "break-word" }}>
                    <Box component="span">{option?.mainText || ""}</Box>
                    <Typography variant="body2" color="text.secondary">
                      {option?.secondaryText}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
        <Alert severity="info" sx={{ mt: 1, mb: 1 }}>
          You can search by employee name, applicant ID, personnel number, SSN
          or other identifiers.
        </Alert>
      </Stack>
    );
  };
  renderExternalAuthCodeInput = () => {
    if (this.context.currentUser.isExternal) return;
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }
    return (
      <TextField
        label="Enter External Authentication Code"
        fullWidth
        onChange={(e) => {
          this.setState({
            externalAuthzCode: e.target.value,
          });
        }}
      />
    );
  };
  renderPageHeader = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" id="STEP_1">
                Create A New Visit
              </Typography>
              {/*this.renderAddEmployeeButton()*/}
            </Stack>
          }
          secondary="Choose the employer and the employee for the visit."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />
      </Stack>
    );
  };

  renderVisitLinkSection = () => {
    if (this.state.previousVisits.length === 0) {
      return (
        <Stack spacing={2}>
          <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
            This Employee does not have any previous visits.
          </Alert>
        </Stack>
      );
    }
    return (
      <Stack spacing={2}>
        <Typography variant="body2">
          Select a reason for linking this visit
        </Typography>
        <Select
          value={this.state.linkedVisitReason}
          onChange={(e) => this.setState({ linkedVisitReason: e.target.value })}
          fullWidth
          placeholder="Select a reason for linking this visit"
        >
          {VISIT_LINK_REASON_TYPES.map((f) => (
            <MenuItem key={f.key} value={f.value}>
              {f.key}
            </MenuItem>
          ))}
        </Select>
        <Typography variant="body2">
          Select the previous visit to link to.
        </Typography>
        <Select
          value={this.state.linkedVisit}
          onChange={(e) => this.setState({ linkedVisit: e.target.value })}
          fullWidth
          placeholder="Select a reason for linking this visit"
        >
          {this.state.previousVisits.map((f) => {
            const value = f._id;
            const clinicName = f.provider?.providerName || "<No Provider>";
            const key = `${f.humanReadableId} | on: ${niceDate(f.providerEncounterOnTime)} | at: ${clinicName}`;
            return (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    );
  };

  renderVisitPoolDrawSection = () => {
    if (this.state.selectPoolList.length === 0) {
      return (
        <Stack spacing={2}>
          <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
            No pools or draws found for this company.
          </Alert>
        </Stack>
      );
    }

    return (
      <Stack spacing={2}>
        <Typography variant="body2">
          Select the linked draw for this visit
        </Typography>
        <Select
          value={this.state.poolDrawId}
          onChange={(e) => this.setState({ poolDrawId: e.target.value })}
          fullWidth
          placeholder="Select a draw for this visit"
        >
          {this.state.selectPoolList.map((f) => {
            const value = f.drawId;
            const key = f.drawName;
            return (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    );
  };

  renderVisitPoolDrawButton = () => {
    if (this.context.currentUser.isExternal) return;
    // if (!this.state.currentlyInEmployee) return null;

    return (
      <div className="p-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.isForPoolDraw}
              onChange={(e) => {
                this.setState({
                  isForPoolDraw: e.target.checked,
                });
              }}
            />
          }
          label="This visit is for a random pool draw."
        />
        {this.state.isForPoolDraw && this.renderVisitPoolDrawSection()}
      </div>
    );
  };

  renderVisitLinkButton = () => {
    if (this.context.currentUser.isExternal) return;
    // if (!this.state.currentlyInEmployee) return null;

    return (
      <div className="p-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.hasLinkedVisit}
              onChange={(e) => {
                this.setState({
                  hasLinkedVisit: e.target.checked,
                });
              }}
            />
          }
          label="Link this visit to an existing visit"
        />
        {this.state.hasLinkedVisit && this.renderVisitLinkSection()}
      </div>
    );
  };

  renderVisitBackDatedButton = () => {
    if (this.context.currentUser.isExternal) return;
    // if (!this.state.currentlyInEmployee) return null;

    return (
      <div className="p-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.isBackDatedVisit}
              onChange={(e) => {
                this.setState({
                  isBackDatedVisit: e.target.checked,
                });
              }}
            />
          }
          label="This is a back-dated visit"
        />
      </div>
    );
  };

  renderCreateVisitButton = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }

    if (this.state.visitId) {
      return (
        <Stack spacing={1} width="100%">
          <Typography variant="subtitle2">Opening Visit</Typography>
          <LinearProgress />
        </Stack>
      );
    }
    return (
      <Stack direction="row" width="100%" justifyContent="end">
        <Box>
          <LoadingButton
            variant="contained"
            loading={
              this.state.isCreatingVisit || this.state.isSearchingEmployee
            }
            onClick={() => {
              this.createVisit();
            }}
          >
            Create Visit
          </LoadingButton>
        </Box>
      </Stack>
    );
  };

  renderEmployeeTable = () => {
    if (!this.state.currentlyInEmployee) return;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Employee Name</TableCell>
            <TableCell>Employee ID</TableCell>
            <TableCell>Employee SSN</TableCell>
            <TableCell>Employee Sex</TableCell>
            <TableCell>Employee DoB</TableCell>
            <TableCell>Employee Address</TableCell>
            <TableCell>Employee Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeName}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeNumber}
            </TableCell>
            <TableCell>
              {maskSSN(this.state.currentlyInEmployee.data.employeeSSN)}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeSex}
            </TableCell>
            <TableCell>
              {niceDateTZ(this.state.currentlyInEmployee.data.employeeDoB)}
            </TableCell>
            <TableCell>
              {this.state.currentlyInEmployee.data.employeeAddress}
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                href={`tel:${this.state.currentlyInEmployee.data.employeePersonalPhone}`}
                startIcon={<Iconify icon="ic:baseline-phone" />}
              >
                {this.state.currentlyInEmployee.data.employeePersonalPhone}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });

  // handleAddEmployee = async () => {
  //   try {
  //     await axios.post("/api/employees", { name: this.state.newEmployeeName });
  //     // Handle success (e.g., show a success message, refresh employee list)
  //     this.handleCloseModal();
  //   } catch (error) {
  //     console.error("Error adding new employee:", error);
  //     // Handle error (e.g., show an error message)
  //   }
  // };

  renderAddEmployeeButton = () => {
    if (
      !this.state.currentlyInEmployee ||
      !this.state.currentlyInEmployee.data ||
      !this.state.currentlyInEmployee.data._id
    ) {
      return null;
    }
    return (
      <Button variant="contained" size="small" onClick={this.handleOpenModal}>
        Add new Employee
      </Button>
    );
  };

  handleAddEmployee = async () => {
    this.setState({ isCreating: true });
    try {
      cogoToast.loading("Creating Employee");
      const res = await this.props.apiCallPost(`/employee/createEmployee`, {
        employeeName: this.state.newEmployeeName,
        companyId: this.state.currentlyInCompany.data._id,
      });
      window.open(`/employees/${res._id}`, "_self");
      cogoToast.success("Employee Created");
    } catch (err) {
      cogoToast.error("We ran into an error.");
      console.log(err);
    }
    this.setState({ isCreating: false });
  };

  render() {
    return (
      <Stack spacing={2}>
        {this.renderPageHeader()}
        {this.state.isLoading && (
          <Box width="100%">
            <LinearProgress />
          </Box>
        )}
        {this.renderCompanySearcher()}
        {this.renderEmployeeSearcher()}
        {this.renderEmployeeTable()}
        {this.renderExternalAuthCodeInput()}
        {this.renderVisitBackDatedButton()}
        {this.renderVisitPoolDrawButton()}
        {this.renderVisitLinkButton()}
        {this.renderCreateVisitButton()}
        <Modal
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          aria-labelledby="add-employee-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Add New Employee
            </Typography>
            <TextField
              fullWidth
              label="Employee Name"
              value={this.state.newEmployeeName}
              onChange={(e) =>
                this.setState({ newEmployeeName: e.target.value })
              }
              margin="normal"
            />
            <Button
              variant="contained"
              disabled={this.state.isCreating}
              onClick={this.handleAddEmployee}
              sx={{ mt: 2 }}
            >
              OK
            </Button>
          </Box>
        </Modal>
      </Stack>
    );
  }
}

export default WithAPICall(CreateVisit);

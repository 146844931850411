import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  Typography,
  Alert,
  LinearProgress,
  Grid,
  Drawer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Chip,
} from "@mui/material";
import { Stack } from "@mui/system";
import { niceDate, niceDateTimeUS } from "../utils/fn";
import { useProtectedApi } from "@/hooks/use-apiCall";
import cogoToast from "cogo-toast";
import VisitList from "../Visit/VisitList";
import Iconify from "src/components/iconify";
import _ from "lodash";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function DocLinkingEach({ eeIndex, ee, document }) {
  const { callApi } = useProtectedApi();
  const [loading, setLoading] = useState(true);
  const [suggestedVisit, setSuggestedVisit] = useState(null);
  const [linkedVisitId, setLinkedVisitId] = useState(ee.linkedVisitId);
  const [isVisitDrawerOpen, setIsVisitDrawerOpen] = useState(false);
  const fetchSuggestedVisit = async () => {
    setLoading(true);
    if (!linkedVisitId) {
      setSuggestedVisit(null);
      setLoading(false);
      return;
    }
    try {
      const suggestedVisit = await callApi("/visit/getVisitById", {
        visitId: linkedVisitId,
      });
      setSuggestedVisit(suggestedVisit);
      setLoading(false);
    } catch (err) {
      cogoToast.error("Failed to fetch suggested visit");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSuggestedVisit();
  }, [linkedVisitId]);
  const updateSuggestedVisit = async (linkedVisitId, visit) => {
    if (suggestedVisit) {
      cogoToast.loading("Clearing out data of previous visit.");
      await updateServiceDataFileInfo([]);
    }
    try {
      cogoToast.loading("Updating linked visit");
      const payload = document.extractedEEInfo;
      payload[eeIndex].linkedVisitId = linkedVisitId;
      await callApi("/datafiles/updateScalars", {
        dataFileId: document._id,
        uops: [
          {
            key: "extractedEEInfo",
            value: payload,
          },
        ],
      });
      cogoToast.success("Linked visit updated");
      setLinkedVisitId(linkedVisitId);
      setIsVisitDrawerOpen(false);
      setSuggestedVisit(visit);
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to update linked visit");
    }
  };
  const updateServiceDataFileInfo = async (serviceDataFileInfo) => {
    try {
      let res = await callApi("/datafiles/linkDataFileToVisitExplicit", {
        visitId: linkedVisitId,
        serviceDataFileInfo,
      });
      setSuggestedVisit(res);
      cogoToast.success("Service data file info updated");
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to update service data file info");
    }
  };
  const extractedElem = (
    <Card
      sx={{
        p: 1,
      }}
    >
      <Stack spacing={1}>
        <Typography
          variant="subtitle1"
          sx={{
            color: "secondary.main",
          }}
        >
          AI Extracted Information
        </Typography>
        <Typography variant="overline">
          Name: {ee.employeeFirstName} {ee.employeeLastName}
        </Typography>
        <Typography variant="overline">
          DOB: {niceDate(ee.employeeDoB)}
        </Typography>
        <Typography variant="overline">SSN: {ee.employeeSSN}</Typography>
        <Typography variant="overline">Employer: {ee.employerName}</Typography>
      </Stack>
    </Card>
  );
  let matchedVisitElem = null;
  if (!suggestedVisit) {
    matchedVisitElem = <Alert severity="info">No suggested visit</Alert>;
  }
  if (suggestedVisit) {
    matchedVisitElem = (
      <Card
        sx={{
          p: 1,
        }}
      >
        <Stack spacing={1}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "primary.main",
            }}
          >
            Matched Visit
          </Typography>
          <Typography variant="overline">
            Name: {suggestedVisit.employee.employeeName}
          </Typography>
          <Typography variant="overline">
            DOB: {niceDate(suggestedVisit.employee.employeeDoB)}
          </Typography>
          <Typography variant="overline">
            SSN: {suggestedVisit.employee.employeeSSN}
          </Typography>
          <Typography variant="overline">
            Employer: {suggestedVisit.company.companyName}
          </Typography>
          <Typography variant="overline">
            Provider: {suggestedVisit.provider.providerName} (
            {suggestedVisit.provider.providerAddress})
          </Typography>
          <Typography variant="overline">
            Visit Date: {niceDate(suggestedVisit.providerEncounterOnDate)}
          </Typography>
        </Stack>
      </Card>
    );
  }
  let actionsElem = null;
  if (suggestedVisit) {
    actionsElem = (
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => setIsVisitDrawerOpen(true)}
          startIcon={<Iconify icon="material-symbols:change-circle" />}
        >
          Change Linked Visit
        </Button>
        <Button
          variant="contained"
          href={`/visits/${linkedVisitId}`}
          target="_blank"
          startIcon={<Iconify icon="icon-park-outline:share" />}
        >
          View Visit Details
        </Button>
      </Stack>
    );
  }
  if (!suggestedVisit) {
    actionsElem = (
      <Button
        variant="contained"
        onClick={() => setIsVisitDrawerOpen(true)}
        startIcon={<Iconify icon="ph:link-light" />}
      >
        Search and Link Visit
      </Button>
    );
  }
  let visitSearchDrawerElem = (
    <Drawer
      open={isVisitDrawerOpen}
      onClose={() => setIsVisitDrawerOpen(false)}
      anchor="right"
    >
      <Card
        sx={{
          p: 1,
          width: "85vw",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* <div
          style={{
            height: "80vw",
            width: "100%",
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
            overflow: "auto",
          }}
        > */}
        <VisitList
          fromAssociationPage={true}
          extractedEEName={`${ee.employeeFirstName} ${ee.employeeLastName}`}
          choosingVisit={true}
          onAssociation={(visitId) => {}}
          onVisitChosen={(each) => {
            updateSuggestedVisit(each._id, each);
          }}
        />
        {/* </div> */}
      </Card>
    </Drawer>
  );
  let dataTableElem = null;
  if (suggestedVisit) {
    let sv = suggestedVisit;
    let servicesInfo = sv.servicesInfo || [];
    if (servicesInfo.length === 0) {
      dataTableElem = (
        <Alert severity="info">No services found in this visit.</Alert>
      );
    }
    let protocolsInSv = sv.protocolsInfo || [];
    if (protocolsInSv.length === 0) {
      dataTableElem = (
        <Alert severity="info">No protocols found in this visit.</Alert>
      );
    }
    dataTableElem = (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>Data Type Expected</TableCell>
            <TableCell>Data Linked From This Document</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {servicesInfo.map((eachService, serviceIndex) => {
            let ps = eachService;
            let serviceParentProtocols = sv.serviceParentProtocols;
            let svcId = ps._id;
            let svcParentProtocol = serviceParentProtocols[svcId] || "";
            let protocolName = "";
            let whichProtocol = protocolsInSv.find(
              (eachProtocol) => eachProtocol._id === svcParentProtocol
            );
            if (whichProtocol) {
              protocolName = whichProtocol.companyProtocolName;
            }
            let firstCellElem = (
              <Stack direction="column">
                <Typography variant="subtitle2">{ps.serviceName}</Typography>
                <Typography variant="caption">{protocolName}</Typography>
              </Stack>
            );
            let documentTypes = ps.documentTypes || [];
            let noDocsFound = false;
            if (documentTypes.length === 0) {
              noDocsFound = true;
            }
            let doc = document;
            let extractedEEInfo = doc.extractedEEInfo[eeIndex];
            let extractedDataTypes = extractedEEInfo.employeeDataTypes;
            let serviceDataFileInfo = sv.serviceDataFileInfo;
            let filteredSDFI = serviceDataFileInfo.filter(
              (x) => x.serviceId === svcId
            );

            let filteredStringElem = "";
            if (!filteredSDFI.length) {
              filteredStringElem = (
                <Alert severity="info">No data types linked</Alert>
              );
            } else {
              filteredStringElem = (
                <Alert severity="info">
                  Linked:{" "}
                  {filteredSDFI
                    .map((x) => {
                      return `${x.dataType} (Pages: ${x.pageIndexFirst} - ${x.pageIndexLast})`;
                    })
                    .join(", ")}
                </Alert>
              );
            }
            let dataTypesInDocDestructured = [];
            extractedDataTypes.forEach((x) => {
              let pageIndices = x.pageIndices;
              for (let i = 0; i < pageIndices.length; i++) {
                let pageIndex = pageIndices[i];
                let pageIndexFirst = pageIndex.split("-")[0];
                let pageIndexLast = pageIndex.split("-")[1];
                let isInSDFI = _.findIndex(
                  filteredSDFI,
                  (y) =>
                    y.dataType === x.dataType &&
                    y.pageIndexFirst.toString() === pageIndexFirst.toString() &&
                    y.pageIndexLast.toString() === pageIndexLast.toString()
                );
                console.log(isInSDFI);
                dataTypesInDocDestructured.push({
                  dataType: x.dataType,
                  pageIndexFirst,
                  pageIndexLast,
                  isInSDFI: isInSDFI !== -1,
                });
              }
            });
            let selectTwoElem = (
              <Accordion>
                <AccordionSummary>{filteredStringElem}</AccordionSummary>
                <AccordionDetails>
                  <Tooltip title="Click to select or deselect.">
                    <Typography variant="overline">Data Types</Typography>
                  </Tooltip>
                  <Stack spacing={1} direction="column">
                    {dataTypesInDocDestructured.map((eachItem, itemIdx) => {
                      return (
                        <Button
                          size="small"
                          onClick={async () => {
                            cogoToast.loading("Updating data link");
                            let sdfiNew = _.cloneDeep(sv.serviceDataFileInfo);
                            if (eachItem.isInSDFI) {
                              // remove from sdfi

                              let idxToRemove = _.findIndex(
                                sdfiNew,
                                (s) =>
                                  s.serviceId === svcId &&
                                  s.dataType === eachItem.dataType &&
                                  s.pageIndexFirst ===
                                    eachItem.pageIndexFirst &&
                                  s.pageIndexLast === eachItem.pageIndexLast
                              );
                              sdfiNew.splice(idxToRemove, 1);
                              await updateServiceDataFileInfo(sdfiNew, svcId);
                              cogoToast.success(
                                `Removed ${eachItem.dataType} from visit`
                              );
                            } else {
                              let sdfiNew = _.cloneDeep(sv.serviceDataFileInfo);
                              sdfiNew.push({
                                serviceId: svcId,
                                dataFileId: document._id,
                                eeIndex,
                                dataType: eachItem.dataType,
                                pageIndexFirst: eachItem.pageIndexFirst,
                                pageIndexLast: eachItem.pageIndexLast,
                              });
                              await updateServiceDataFileInfo(sdfiNew);
                              cogoToast.success(
                                `Added ${eachItem.dataType} to visit`
                              );
                            }
                          }}
                          variant={eachItem.isInSDFI ? "contained" : "outlined"}
                          color={eachItem.isInSDFI ? "success" : "warning"}
                          startIcon={
                            eachItem.isInSDFI ? (
                              <Iconify icon="mdi:check-bold" />
                            ) : (
                              ""
                            )
                          }
                        >
                          {eachItem.dataType} (Pages: {eachItem.pageIndexFirst}{" "}
                          - {eachItem.pageIndexLast})
                        </Button>
                      );
                    })}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );

            return (
              <TableRow key={serviceIndex}>
                <TableCell>{firstCellElem}</TableCell>
                <TableCell>
                  {noDocsFound ? (
                    <Alert severity="info">No documents expected</Alert>
                  ) : (
                    <Alert severity="info">
                      Expected: {documentTypes.join(", ")}
                    </Alert>
                  )}
                </TableCell>
                <TableCell>{selectTwoElem}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
  if (loading) {
    return <LinearProgress />;
  }
  return (
    <Stack spacing={2}>
      {visitSearchDrawerElem}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            {extractedElem}
            {actionsElem}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          {matchedVisitElem}
        </Grid>
      </Grid>
      {dataTableElem}
    </Stack>
  );
}

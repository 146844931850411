export default [
  // pre-visit
  "REQUESTED",
  "OUTREACH_FOR_SCHEDULING",
  "SCHEDULED",
  // post-booking
  "PENDING_DOCUMENTS_FROM_CLINIC",
  "PARTIAL_DOCUMENTS_RECEIVED",
  "REVIEW_NOT_STARTED",
  // in review
  "IN_RN_REVIEW",
  "IN_MD_REVIEW",
  "PENDING_INFORMATION_FROM_EMPLOYEE",
  "PENDING_AFFIDAVIT",
  // terminal
  "COMPLETED",
  "CANCELLED",
  "NO_SHOW",
  "RETURN_TO_CLINIC",

  // "CLEARED",
  // "CLEARED_WITH_RESTRICTIONS",
  // "NOT_CLEARED",
  // "REVIEW_COMPLETED",
  // "RETURN_TO_CLINIC",
  // "NO_SHOW",
  // "CANCELLED",
  // "PANEL_CHANGE_REQUESTED",
  // "ADDITIONAL_TESTING_REQUIRED",
  // "POSSIBLE_NO_SHOW",
];

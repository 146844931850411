export const CRL_PANEL_SUBSTANCES = {
  3000: "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), MDMA/MDA (500/250), COD/MOR (2000/2000), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3007: "6-AM (10/10), BARBITURATES (200/100), ETG/ETS (500/500-250), AMP/MAMP (300/100), COCAINE METABOLITE (100/50), OPIATES-4 (100/100), PHENCYCLIDINE (20/15), BENZODIAZEPINES (200/50), MARIJUANA METABOLITES (20/3), METHADONE MTB (200/100), METHAQUALONE (200/100), PROPOXYPHENE MTB (200/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300G":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300J":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300N":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300R":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300V":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (300/100), BENZODIAZEPINES (300/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), METHAQUALONE (300/200), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (300/250), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "300Y":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "301C":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "301H":
    "AMP/MAMP (1000/500), COCAINE METABOLITE (300/150), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "301M":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "301V":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "302C":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "302K":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3030: "6-AM (10/10), AMP/MAMP (1000/500), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), COD/MOR (2000/2000), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3053: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3055: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "305C":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3067: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "306C":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), COD/MOR (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3072: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3074: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "307C":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/15), METHADONE MTB (300/200), METHAQUALONE (300/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "307Y":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3080: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/100), OPIATES-4 (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "309C":
    "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), MDMA/MDA (500/250), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "309V":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), OPIATES-4 (300/300), MARIJUANA METABOLITES (100/15), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30C7":
    "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30CF":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), OXYC/OXYM (100/100), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30CJ":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/15), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30CL":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (250/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30CV":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), COD/MOR (300/300), PHENCYCLIDINE (25/25), MARIJUANA METABOLITES (100/15), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30G7":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), MDMA/MDA (500/250), HYC/HYM (300/100), FENTANYL (0.75/0.5), ETG/ETS, BUPRENORPHINE SCREEN, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30GQ":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30H4":
    "AMP/MAMP (1000/500), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OPIATES-4 (300/300), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MEPERIDINE MTB (200/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30H5":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MEPERIDINE MTB (100/100), MDMA/MDA (500/250), HYC/HYM (300/100), FENTANYL (0.75/0.5), NALBUPHINE (5/100), PENTAZOCINE (75/100), COTININE (500/200), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30H8":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30HJ":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30J6":
    "AMP/MAMP (1000/500), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE",
  "30J7":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30JH":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), FENTANYL (0.75/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30JK":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30K0":
    "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30L7":
    "AMP/MAMP (300/300), BARBITURATES (300/100), BENZODIAZEPINES (300/100), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE",
  "30LM":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (200/100), BENZODIAZEPINES (200/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), METHAQUALONE (300/200), MDMA/MDA (250/200), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30LV":
    "AMP/MAMP (1000/500), BARBITURATES (300/100), BENZODIAZEPINES (300/100), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/100), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30M3":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/200), MDMA/MDA (500/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), BENZO(CEDIA)SCRN-OIL & GAS, K2 SPICE SCREEN, URINE (O&G), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30MX":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (200/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), METHAQUALONE (300/200), MDMA/MDA (250/200), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), BENZO(CEDIA)-O&G (200/100), K2 SPICE SCREEN, URINE, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "30T8":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OPIATES-4 (2000/2000), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE",
  "30XQ":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "310H":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/200), METHAQUALONE (300/200), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MARIJUANA METABOLITES (20/5), COD/MOR (300/300), URN CREATININE",
  3198: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), OPIATES-4 (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "319L":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHAQUALONE (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "319P":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (250/200), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3204: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/100), BENZODIAZEPINES (300/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OXYC/OXYM (300/300), PHENCYCLIDINE (25/25), PROPOXYPHENE MTB (300/100), OPIATES-4 (300/300), URN CREATININE",
  "322J":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), OXYC/OXYM (100/300), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3272: "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3273: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (250/250), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3274: "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), COD/MOR (300/300), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), MARIJUANA METABOLITES (100/15), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3276: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3279: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), MDMA/MDA (1000/500), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327H":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327J":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327K":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), METHAQUALONE (300/200), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327L":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327M":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/15), METHADONE MTB (300/300), METHAQUALONE (300/100), COD/MOR (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327N":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), OPIATES-4 (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327P":
    "COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), OPIATES-4 (300/300), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), AMP/MAMP (300/500), MDMA/MDA (300/500), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327R":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327V":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (200/200), MDMA/MDA (500/250), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (300/300), METHAQUALONE (200/200), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327W":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "327X":
    "AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), COD/MOR (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3280: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/100), COD/MOR (300/300), PHENCYCLIDINE (25/25), MARIJUANA METABOLITES (100/15), PROPOXYPHENE MTB (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3286: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3289: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), TRAMADOL METABOLITE (100/100), MDMA/MDA (1000/500), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "328G":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/100), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "328H":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), HYC/HYM (300/100), MDMA/MDA (1000/500), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "328J":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "330Q":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/100), MDMA/MDA (500/250), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3312: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (250/250), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3313: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), HYC/HYM (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "332G":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "336M":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), FENTANYL (0.75/0.5), ETG/ETS (500/500-100), BUPRENORPHINE (10/10-5), URN CREATININE",
  3372: "AMP/MAMP (1000/500), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (300/2000), HYC/HYM (300/300), BARBITURATES ABV (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "337N":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), BUPRENORPHINE (5/5), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "337T":
    "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "33FL":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "33GM":
    "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/200), MDMA/MDA (500/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), BARBITURATES ABV (300/200), BENZO(CEDIA)SCRN-OIL & GAS, K2 SPICE SCREEN, URINE (O&G), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "33JF":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (300/200), BENZODIAZEPINES (300/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (250/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), K2 SPICE SCREEN, URINE, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "33JQ":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (300/200), BENZODIAZEPINES (300/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/200), METHAQUALONE (300/200), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (300/250), K2 SPICE SCREEN, URINE, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "343K":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), BUPRENORPHINE (10/10-5), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3454: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), OPIATES-4 (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3466: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), PHENCYCLIDINE (25/25), BUPRENORPHINE (10/10), OPIATES-4 (300/300), FENTANYL (2.0/0.5), TRAMADOL METABOLITE (300/300), OXYC/OXYM (100/75), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3476: "AMP/MAMP (1000/500), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OPIATES-4 (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34J4":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34LK":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (200/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), COD/MOR (2000/2000), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (1000/500), BENZODIAZEPINES-C (200/200), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34LR":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (200/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OXYC/OXYM (300/300), PHENCYCLIDINE (25/25), BUPRENORPHINE (10/10), TRAMADOL METABOLITE (200/100), COD/MOR (300/300), HYC/HYM (300/300), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34MK":
    "7-CARBOXY-CBD, DELTA 9-THCA, CBD, DELTA 9-THC, CANNABIDIOL (CBD)/THC RATIO, 7-CARBOXY-CBD + CBD (CALC), D9 THCA + D9 THC (CALC)",
  "34NF":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), METHAQUALONE (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34NT":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), MDMA/MDA (500/250), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), OPIATES-4 (300/150), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "34YJ":
    "AMP/MAMP (300/300), BARBITURATES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MEPERIDINE MTB (100/100), TRAMADOL METABOLITE (100/100), BENZODIAZEPINES-C (100/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3500: "ALCOHOL, ETHYL (0.04/0.04), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OPIATES-4 (300/300), OXYC/OXYM (300/300), PHENCYCLIDINE (25/25), AMP/MAMP (300/500), PROPOXYPHENE MTB (300/500), ETG/ETS (500/500-100), URN CREATININE",
  "35C1":
    "AMP/MAMP (1000/500), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), BARBITURATES ABV (300/200), BUPRENORPHINE (10/5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35FW":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35HH":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), OPIATES-4 (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35L5":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (300/300), HYC/HYM (300/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35LY":
    "6-AM (10/10), AMP/MAMP (500/250), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35M2":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (250/250), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), TRICYCLICS (100/100), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35TQ":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), COCAINE METABOLITE (150/100), METHADONE MTB (300/200), MDMA/MDA (500/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), BENZO(CEDIA)SCRN-OIL & GAS, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35W5":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), OPIATES-4 (2000/2000), PHENCYCLIDINE (25/20), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "35W8":
    "AMP/MAMP (500/250), BARBITURATES (200/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), BENZODIAZEPINES (300/75), OPIATES-4 (2000/300), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3624: "AMP/MAMP (500/250), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OPIATES-4 (300/300), OXYC/OXYM (100/100), MDMA/MDA (500/250), BUPRENORPHINE (10/10), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3682: "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), PHENCYCLIDINE (25/25), OPIATES-4 (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3723: "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COTININE (500/500), BUPRENORPHINE (10/10), OPIATES-4 (300/300), URN CREATININE",
  "372K":
    "AMP/MAMP (300/300), BARBITURATES (300/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), MDMA/MDA (250/200), COD/MOR (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), BENZODIAZEPINES (100/100), TRICYCLICS (100/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "372R":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "372X":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), METHADONE MTB (300/300), MDMA/MDA (500/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COD/MOR (2000/2000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3745: "AMP/MAMP (1000/500), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/100), METHAQUALONE (300/200), MDMA/MDA (500/250), OPIATES-4 (300/300), OXYC/OXYM (300/300), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MEPERIDINE MTB (200/100), TRAMADOL METABOLITE (200/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "374J":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3756: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/200), METHAQUALONE (300/200), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "376P":
    "AMP/MAMP (1000/500), BARBITURATES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (20/15), METHADONE MTB (300/200), MDMA/MDA (500/250), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MEPERIDINE MTB (100/100), ALCOHOL, ETHYL (0.02/0.02), TRAMADOL METABOLITE (100/100), BENZODIAZEPINES-C (100/100), OPIATES-4 (300/100), FENTANYL (0.5/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3776: "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), K2 SPICE SCREEN, URINE, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37GP":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (200/200), BENZODIAZEPINES (200/200), COCAINE METABOLITE (150/100), METHADONE MTB (300/200), METHAQUALONE (300/200), MDMA/MDA (500/250), OPIATES-4 (300/100), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37HY":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (200/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OXYC/OXYM (300/300), PHENCYCLIDINE (25/25), BUPRENORPHINE (10/10), TRAMADOL METABOLITE (200/100), OPIATES-4 (300/300), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37JY":
    "6-AM (10/10), AMP/MAMP (1000/500), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), COD/MOR (2000/2000), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37VJ":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), MDMA/MDA (500/250), OPIATES-4 (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), COTININE (200/200), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37Y5":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OPIATES-4 (300/300), OXYC/OXYM (300/300), BUPRENORPHINE (10/10), FENTANYL (0.75/0.5), COTININE (500/200), ETG/ETS (500/500-100), COCAINE METABOLITE (150/75), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "37YJ":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OPIATES-4 (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3801: "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), FENTANYL (0.75/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "381X":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3843: "AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), TRICYCLICS (100/100), OPIATES-4 (2000/2000), BUPRENORPHINE (10/5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3862: "6-AM (10/10), AMP/MAMP (1000/500), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), METHADONE MTB (300/200), METHAQUALONE (300/200), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), OPIATES-4 (300/300), BARBITURATES ABV (300/200), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3869: "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), MDMA/MDA (500/250), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), HYC/HYM (300/100), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "38C7":
    "AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (150/100), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OPIATES-4 (300/300), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "38PX":
    "6-AM (10/10), ALCOHOL, ETHYL (0.04/0.04), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (200/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OXYC/OXYM (300/300), PHENCYCLIDINE (25/25), BUPRENORPHINE (10/10), TRAMADOL METABOLITE (200/100), OPIATES-4 (300/300), FENTANYL (2.0/0.5), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "38QC":
    "6-AM (10/10), AMP/MAMP (500/250), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), COD/MOR (2000/2000), OXYC/OXYM (100/100), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "38WG":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/200), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), MDMA/MDA (500/250), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "38X1":
    "AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), COD/MOR (300/300), PHENCYCLIDINE (25/25), ALCOHOL, ETHYL (0.02/0.02), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "392P":
    "6-AM (10/10), AMP/MAMP (300/250), BARBITURATES (300/100), BENZODIAZEPINES (300/100), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (20/10), METHADONE MTB (300/100), MDMA/MDA (250/200), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  3979: "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OPIATES-4 (2000/2000), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "39MJ":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/200), BENZODIAZEPINES (300/200), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/200), METHAQUALONE (300/200), COD/MOR (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/200), PHENCYCLIDINE (25/25), HYC/HYM (300/100), MDMA/MDA (1000/500), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "39N4":
    "6-AM (10/10), AMP/MAMP (500/250), BARBITURATES (300/300), BENZODIAZEPINES (300/300), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), MDMA/MDA (500/250), OPIATES-4 (2000/2000), OXYC/OXYM (100/100), PROPOXYPHENE MTB (300/300), PHENCYCLIDINE (25/25), K2 SPICE SCREEN, URINE, URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  "409N":
    "6-AM (10/10), AMP/MAMP (1000/500), BARBITURATES (300/300), BENZODIAZEPINES (300/200), COCAINE METABOLITE (300/150), MARIJUANA METABOLITES (50/15), METHADONE MTB (300/300), METHAQUALONE (300/300), COD/MOR (2000/2000), PHENCYCLIDINE (25/25), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  HH15: "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), COD/MOR (2000/2000/4000), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
  PN29: "K2 SPICE SCREEN, URINE, URN CREATININE, COCAINE METABOLITES, BENZODIAZEPINES, METHADONE, BARBITURATES, MARIJUANA METABOLITE, OPIATES, PHENCYCLIDINE, AMPHETAMINES (CLASS), PROPOXYPHENE/METABOLITE",
  W189: "OXYCODONE/OXYMORPHONE SCRN, UR, URN CREATININE, COCAINE METABOLITES, BENZODIAZEPINES, METHADONE, BARBITURATES, MARIJUANA METABOLITE, OPIATES, PHENCYCLIDINE, AMPHETAMINES (CLASS)",
  W215: "6-AM (10/10), AMP/MAMP (500/250), COCAINE METABOLITE (150/100), MARIJUANA METABOLITES (50/15), COD/MOR (2000/2000), OXYC/OXYM (100/100), PHENCYCLIDINE (25/25), MDMA/MDA (500/250), HYC/HYM (300/100), URN CREATININE, ADULTA-PH, GENERAL OXIDANT",
};

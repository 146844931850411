import React from "react";
import {
  apiCallPostUnuath,
  formattedTZTime,
  maskSSN,
  niceDate,
} from "../utils/fn";
import {
  Alert,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import _ from "lodash";
import { GenericRenderer } from "../DataFiles/DataRenderers/GenericRenderer";

class VisitAlcoholClearance extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    protocolId: null,
    data: null,
    isError: false,
    medicalRecords: [],
  };
  componentDidMount() {
    this.handlePrint();
    this.setState(
      {
        visitId: this.props.id.split("_")[0],
        serviceId: this.props.id.split("_")[1],
      },
      () => {
        this.getVisitData();
      }
    );
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/print/clearance/payload", {
        visitId: this.state.visitId,
      });

      const relevantDocs = (res.serviceDataFileInfo || []).filter(
        (x) => x.serviceId === this.state.serviceId
      );
      const medicalRecords = await apiCallPostUnuath(
        "/visit/getMedicalRecords",
        {
          visitId: this.state.visitId,
        }
      );

      // const medicalRecords = [];
      // for (let i = 0; i < relevantDocs.length; i++) {
      //   const doc = relevantDocs[i];
      //   const eeIndex = doc.eeIndex;
      //   const medicals = groups[i].extractedEEInfo[eeIndex].employeeMedicalData;
      //   for (let m of medicals) {
      //     if (_.isArray(m)) {
      //       medicalRecords.push(...m);
      //     } else {
      //       medicalRecords.push(m);
      //     }
      //   }
      // }

      console.log({ medicalRecords });

      this.setState({
        data: res,
        // groups: groups,
        medicalRecords: medicalRecords,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  handlePrint = () => {
    const style = document.createElement("style");
    style.innerHTML = `
        @media print {
          body * {
            visibility: hidden;
          }
          #printableArea, #printableArea * {
            visibility: visible;
          }
          #printableArea {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
    document.head.appendChild(style);
  };
  renderClearance = () => {
    return (
      <Stack spacing={1}>
        {this.renderHeader()}
        {this.renderERTable()}
        {this.renderEETable()}
        {this.renderProviderTable()}
        {/* {this.renderCCFInfo()} */}
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        {this.renderClearanceLanguage()}
        {this.renderFooter()}
      </Stack>
    );
  };
  renderHeader = () => {
    let serviceId = this.state.serviceId;
    let bp = this.state.data.servicesInfo;
    let p = _.find(bp, { _id: serviceId });
    let pName = p.serviceName;
    // let pType = p.companyProtocolType;

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component="img"
          src="/logo/logo_single.svg"
          sx={{ width: 80, height: 80, cursor: "pointer" }}
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="h6">
          Medical Review Officer's Report of Alcohol Test Results
        </Typography>
      </Stack>
    );
  };
  renderERTable = () => {
    let companyName = this.state.data.company.companyName;
    let companyAddress = this.state.data.company.companyAddress;
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">Employer Information</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {" "}
          <TableRow>
            <TableCell>Employer</TableCell>
            <TableCell>{companyName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{companyAddress}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  renderEETable = () => {
    let employeeName = this.state.data.employee.employeeName;
    let employeeNumber = this.state.data.employee.employeeNumber;
    let employeeDoB = this.state.data.employee.employeeDoB
      ? niceDate(new Date(this.state.data.employee.employeeDoB))
      : "Not on file";
    let employeeSSN = maskSSN(this.state.data.employee.employeeSSN);
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">Employee Information</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Employee</TableCell>
            <TableCell>{employeeName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee ID</TableCell>
            <TableCell>{employeeNumber}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>DoB</TableCell>
            <TableCell>{employeeDoB}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell>SSN</TableCell>
            <TableCell>{employeeSSN}</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    );
  };
  renderProviderTable = () => {
    // let clinicPSLs = this.state.data.providerPSLsInfo;
    // let earliest = this.state.data.providerEncounterOnTime;
    // let whichPSL = earliest;
    let provider = this.state.data.provider;
    let providerName = provider.providerName;
    let providerAddress = provider.providerAddress;
    let scheduledTime = this.state.data.providerEncounterOnTime;
    let providerTimeZone = provider.providerReadableTimezone;
    let scTime = formattedTZTime(scheduledTime, providerTimeZone);

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="overline">
                Collection Site/Clinic{" "}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell>{providerName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{providerAddress}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Visit Date and Time</TableCell>
            <TableCell>{scTime}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
  //   renderCCFInfo = () => {
  //     let provider = this.state.data.provider;
  //     let providerName = provider.providerName;
  //     let providerAddress = provider.providerAddress;
  //     let scheduledTime = this.state.data.providerEncounterOnTime;
  //     let providerTimeZone = provider.providerReadableTimezone;
  //     let scTime = formattedTZTime(scheduledTime, providerTimeZone);

  //     const cocGroups = this.state.medicalRecords.find(
  //       (x) => x.dataType === "CHAIN_OF_CUSTODY"
  //     );

  //     if (!cocGroups) return null;

  //     // console.log({ cocGroups, coc: cocGroups[0] });

  //     const specimenId = cocGroups.specimen_id || "<No Specimen ID>";
  //     // const dotAgency = ccfGroup.dataStructured.find(
  //     //   (ds) => ds.key === "dotAgency"
  //     // )?.value;
  //     // const specimenType = ccfGroup.dataStructured.find(
  //     //   (ds) => ds.key === "specimenType"
  //     // )?.value;

  //     const lrGroup = this.state.medicalRecords.find(
  //       (x) => x.dataType === "LAB_REPORT"
  //     );

  //     if (!lrGroup) return null;

  //     const substancesTested = cocGroups.substances_tested;

  //     const alcSvc = null;
  //     // const alcResult = this.state.data.drugAlcoholClearance[alcSvc?._id];
  //     // return <GenericRenderer listOfData={cocGroups} />;
  //     return (
  //       <Table size="small">
  //         <TableHead>
  //           <TableRow>
  //             <TableCell colSpan={2}>
  //               <Typography variant="overline">Test Information</Typography>
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           <TableRow>
  //             <TableCell>Testing Lab</TableCell>
  //             <TableCell>CRL</TableCell>
  //           </TableRow>
  //           <TableRow>
  //             <TableCell>Specimen ID</TableCell>
  //             <TableCell>{specimenId}</TableCell>
  //           </TableRow>
  //           {/* {dotAgency && (
  //             <TableRow>
  //               <TableCell>DOT Agency</TableCell>
  //               <TableCell>{dotAgency}</TableCell>
  //             </TableRow>
  //           )} */}
  //           <TableRow>
  //             <TableCell>Type of Test</TableCell>
  //             <TableCell>{cocGroups.test_type}</TableCell>
  //           </TableRow>
  //           {/* {alcResult && (
  //             <>
  //               <TableRow>
  //                 <TableCell>Alcohol Test Type</TableCell>
  //                 <TableCell>{alcResult.selections.alcoholTestType}</TableCell>
  //               </TableRow>
  //               <TableRow>
  //                 <TableCell>Alcohol Test Result</TableCell>
  //                 <TableCell>
  //                   {alcResult.selections.alcoholTestFinalResult}
  //                 </TableCell>
  //               </TableRow>
  //             </>
  //           )} */}
  //           <TableRow>
  //             <TableCell>Substances Tested</TableCell>
  //             <TableCell>{substancesTested}</TableCell>
  //           </TableRow>
  //         </TableBody>
  //       </Table>
  //     );
  //   };
  renderClearanceLanguage = () => {
    let serviceId = this.state.serviceId;
    let bp = this.state.data.servicesInfo;
    let ps = this.state.data.alcoholClearance;
    let bookedProtocol = _.find(bp, { _id: serviceId });
    if (!bookedProtocol) {
      return <Alert severity="error">Service not found.</Alert>;
    }
    let protocolSummaryObj = ps[serviceId];
    if (!protocolSummaryObj) {
      return <Alert severity="error">Protocol not found.</Alert>;
    }

    // const lrGroup = this.state.groups.find(
    //   (x) =>
    //     x.pageDataType === "LAB_REPORT" &&
    //     x.service._id === this.state.serviceId
    // );

    // if (!lrGroup)
    //   return <Alert severity="error">Lab Report not attached.</Alert>;

    // const substancesPositive = lrGroup.dataStructured
    //   // .filter((ds) => ds.result.toLowerCase() === "positive")
    //   .filter((ds) => ds.isNormal == false)
    //   .map((ds) => ds.parameter)
    //   .join(", ");

    // console.log({ substancesPositive, cl: protocolSummaryObj.clearance });
    // let pName = bookedProtocol.companyProtocolName;
    // let pType = bookedProtocol.companyProtocolType;

    // const cocGroups = this.state.medicalRecords.find(
    //   (x) => x.dataType === "BAT"
    // );

    let headerElem = (
      <Typography variant="h5">
        Medical Review Officer's Report of Alcohol Test Results
      </Typography>
    );
    // let typeOfClearance = (
    //   <Typography variant="subtitle1">
    //     Result: {_.startCase(_.lowerCase(protocolSummaryObj.typeOfClearance))}
    //   </Typography>
    // );
    let clearanceLanguage = (
      <Typography variant="subtitle1">
        {protocolSummaryObj.finalClearanceText}
      </Typography>
    );
    return (
      <Stack
        spacing={0.5}
        sx={{
          p: 2,
        }}
      >
        {headerElem}
        {/* {typeOfClearance} */}
        {clearanceLanguage}
      </Stack>
    );
  };
  renderFooter = () => {
    let signatureImage = this.state.data.assignedMD.signatureImagePsUrl;
    let docName = `${this.state.data.assignedMD.name} ${this.state.data.assignedMD.reviewerType}`;
    let serviceId = this.state.serviceId;
    let protocolSummaryObj = this.state.data.alcoholClearance[serviceId];
    if (!protocolSummaryObj) {
      return <Alert severity="error">Protocol summary not found.</Alert>;
    }

    let clearedAt = protocolSummaryObj.clearedAt;
    let docNameElem = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Typography variant="body2">{docName}</Typography>
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">Medical Review Officer</Typography>
      </Stack>
    );
    let docSignature = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Box
          component="img"
          src={signatureImage}
          sx={{ width: 80, height: "auto", cursor: "pointer" }}
        />
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">MRO Signature</Typography>
      </Stack>
    );
    let reviewTime = (
      <Stack spacing={0.5} alignItems={"center"}>
        <Typography variant="body2">{niceDate(clearedAt)}</Typography>
        <Divider
          flexItem
          sx={{
            bgcolor: "#919EAB",
          }}
        />
        <Typography variant="overline">Date</Typography>
      </Stack>
    );
    let footerAddress = (
      <Typography variant="caption">
        WorkCare Inc. | 300 South Harbor Boulevard, Anaheim, CA 92805 |
        800-455-6155
      </Typography>
    );
    return (
      <Stack spacing={0.5} alignItems={"center"}>
        <Stack
          direction="row"
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          sx={{
            p: 2,
            width: "100%",
          }}
        >
          {docNameElem}
          {docSignature}
          {reviewTime}
        </Stack>
        {footerAddress}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <Alert severity="error">We ran into an error.</Alert>;
    }
    return (
      <Paper
        sx={{
          p: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div id="printableArea">{this.renderClearance()}</div>
      </Paper>
    );
  }
}

export default VisitAlcoholClearance;

"use client";

import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import {
  ExternalLink,
  Download,
  User,
  Users,
  Briefcase,
  Edit,
  Loader2,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

export function ClearanceScreenDone({
  mode,
  entityId,
  onChangeClearance,
  custom,
}) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [emailTo, setEmailTo] = useState("");
  const [emailCC, setEmailCC] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const fetchPdf = async () => {
    setIsDownloadingPdf(true);
    try {
      const res = await callApi("/visit/getClearancePdf", {
        visitId: visit._id,
        process: mode,
        entityId: entityId,
      });
      // window.open(res, "_blank");
      const a = document.createElement("a");
      a.href = res;
      a.download = res.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      console.log(e);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      const template = visit.company.emailTemplates.find(
        (t) => t.scenario === selectedTemplate
      );
      if (template) {
        setEmailSubject(template.subject);
        setEmailBody(template.body);
      }
    }
  }, [selectedTemplate]);

  const [recipientType, setRecipientType] = useState("");
  const { toast } = useToast();
  const employee = visit.employee;
  const company = visit.company;
  const [companyContacts, setCompanyContacts] = useState([]);

  const fetchCompanyContacts = async () => {
    try {
      const res = await callApi("/contacts/getByEntityId", {
        entityType: "Company",
        entityId: company._id,
      });
      console.log({ fetchCompanyContacts: res });
      setCompanyContacts(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanyContacts();
  }, [company]);

  let savedClearance = null;
  let clearance = null;
  if (mode === "medical") {
    savedClearance = visit.protocolClearance[entityId];
    const url = `${visit._id}_${entityId}`;
    let prefix = `/prints/visit-protocol-clearance/`;
    if (custom === "arkema") {
      prefix = `/prints/visit-protocol-clearance-arkema/`;
    }
    clearance = {
      case: savedClearance.clearanceCase,
      fileUrl: `${prefix}${url}`,
      clearedBy: savedClearance.clearedByName,
      clearedDate: savedClearance.clearedAt,
    };
  } else if (mode === "drug") {
    savedClearance = visit.drugAlcoholClearance[entityId];
    const url = `${visit._id}_${entityId}`;
    clearance = {
      case: savedClearance.finalClearanceText,
      fileUrl: `/prints/visit-protocol-drug-clearance/${url}`,
      clearedBy: savedClearance.clearedByName,
      clearedDate: savedClearance.clearedAt,
    };
  } else if (mode === "alcohol") {
    savedClearance = visit.alcoholClearance[entityId];
    const url = `${visit._id}_${entityId}`;
    clearance = {
      case: savedClearance.finalClearanceText,
      fileUrl: `/prints/visit-protocol-alcohol-clearance/${url}`,
      clearedBy: savedClearance.clearedByName,
      clearedDate: savedClearance.clearedAt,
    };
  }

  // const clearance = {
  //   // id: 1,
  //   case: "Cleared",
  //   fileUrl: "https://localhost:3000/",
  //   clearedBy: "Dr. Jane Smith",
  //   clearedDate: "2023-06-15T14:30:00Z",
  // };

  const handleSendClick = (type) => {
    setRecipientType(type);
    switch (type) {
      case "Employee":
        setEmailTo(
          employee.employeeOfficeEmail ??
            employee.employeePersonalEmail ??
            "<No EE Email on File>"
        );
        break;
      case "Supervisor":
        const sup = companyContacts.find((c) => c.contactType === "Supervisor");
        if (sup) setEmailTo(sup.emailAddress);
        else setEmailTo("<No Supervisor Contact on File>");
        break;
      case "DER":
        const der = companyContacts.find(
          (c) => c.contactType === "DER Contact"
        );
        if (der) setEmailTo(der.emailAddress);
        else setEmailTo("<No DER Contact on File>");
        break;
      default:
        break;
    }
    setIsDialogOpen(true);
  };

  const handleSendEmail = async () => {
    setIsSendingEmail(true);
    try {
      console.log(`Sending email to ${emailTo}: ${emailBody}`);
      await callApi("/visit/sendExplicitClearanceLetterToContacts", {
        visitId: visit._id,
        contacts: emailTo,
        cc: emailCC,
        entity: mode,
        entityId: entityId,
        body: emailBody,
        subject: emailSubject,
      });
      setIsDialogOpen(false);
      toast({
        title: "Email Sent",
        description: `The clearance has been sent to ${recipientType}.`,
      });
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: `There was an error sending the email to ${recipientType}.`,
      });
    } finally {
      setIsSendingEmail(false);
      setEmailTo("");
      setEmailCC("");
      setEmailBody("");
    }
  };

  const handleChangeClearance = () => {
    onChangeClearance();
  };

  return (
    <div className="p-4 max-w-4xl mx-auto bg-white rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Clearance Management
        </h2>
        <Button size="sm" onClick={handleChangeClearance}>
          <Edit className="h-4 w-4 mr-2" />
          Change Clearance
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/5 py-2">Case</TableHead>
            <TableHead className="w-1/5 py-2">Cleared By</TableHead>
            <TableHead className="w-1/5 py-2">Cleared Date</TableHead>
            <TableHead className="w-1/5 py-2">Preview</TableHead>
            <TableHead className="w-1/5 py-2">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium py-2">{clearance.case}</TableCell>
            <TableCell className="py-2">{clearance.clearedBy}</TableCell>
            <TableCell className="py-2">
              {new Date(clearance.clearedDate).toLocaleString()}
            </TableCell>
            <TableCell className="py-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-6 p-1"
                      onClick={() => {
                        window.open(`${clearance.fileUrl}`, "_blank");
                      }}
                    >
                      Open
                      <ExternalLink className="h-6 ml-1" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Preview file</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </TableCell>
            <TableCell className="py-2">
              <div className="flex space-x-1">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        onClick={() => handleSendClick("Employee")}
                      >
                        <User className="h-3 w-3 mr-1" />
                        EE
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Send to Employee</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        onClick={() => handleSendClick("Supervisor")}
                      >
                        <Users className="h-3 w-3 mr-1" />
                        Sup
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Send to Supervisor</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        onClick={() => handleSendClick("DER")}
                      >
                        <Briefcase className="h-3 w-3 mr-1" />
                        DER
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Send to DER</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-6 w-6 p-0"
                        disabled={isDownloadingPdf}
                        onClick={() => {
                          if (!isDownloadingPdf) {
                            fetchPdf();
                          }
                        }}
                      >
                        {isDownloadingPdf ? (
                          <Loader2 className="h-3 w-3 animate-spin" />
                        ) : (
                          <Download className="h-3 w-3" />
                        )}
                        <span className="sr-only">
                          {isDownloadingPdf
                            ? "Downloading..."
                            : "Download file"}
                        </span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        {isDownloadingPdf ? "Downloading..." : "Download file"}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[60vw] sm:max-h-[80vh]">
          <DialogHeader>
            <DialogTitle>Send Clearance to {recipientType}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="template" className="text-right">
                Template
              </Label>
              <Select
                onValueChange={setSelectedTemplate}
                value={selectedTemplate}
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select a template">
                    {selectedTemplate}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {visit.company.emailTemplates.map((template) => (
                    <SelectItem
                      key={template.scenario}
                      value={template.scenario}
                    >
                      {template.scenario}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="to" className="text-right">
                To
              </Label>
              <Input
                id="to"
                value={emailTo}
                onChange={(e) => setEmailTo(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="to" className="text-right">
                Cc
              </Label>
              <Input
                id="cc"
                value={emailCC}
                onChange={(e) => setEmailCC(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="subject" className="text-right">
                Subject
              </Label>
              <Input
                id="subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="body" className="text-right">
                Body
              </Label>
              <Textarea
                id="body"
                rows={10}
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="body" className="text-right">
                Attachment:
              </Label>
              <div className="col-span-3">
                The clearance document will be sent as an attachment to this
                email.
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              onClick={handleSendEmail}
              disabled={isSendingEmail}
            >
              Send Email
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

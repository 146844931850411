import React, { useState } from "react";
import { DataFileProcessingTimeline } from "./datafile-processing-timeline";
import { FileUploadPage } from "./file-upload-page";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { CloudUpload, List } from "lucide-react";
import { DataFileList } from "./DataFileList";

const TabButton = ({ icon, label, isActive, onClick }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className={`flex-1 px-6 py-3 text-sm font-medium transition-colors duration-200 ease-in-out
            ${isActive ? "border-b-2 border-primary" : "hover:bg-gray-50"}
            `}
          onClick={onClick}
        >
          <div className="flex items-center justify-center space-x-2">
            {icon}
            <span>{label}</span>
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent></TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default function DataFiles() {
  const [activeTab, setActiveTab] = useState("UPLOAD");

  return (
    <div className="w-full mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div className="flex border-b">
        <TabButton
          icon={<CloudUpload />}
          label="Upload A Document"
          isActive={activeTab === "UPLOAD"}
          onClick={() => setActiveTab("UPLOAD")}
        />
        <TabButton
          icon={<List />}
          label="List of Documents"
          isActive={activeTab === "LIST"}
          onClick={() => setActiveTab("LIST")}
        />
        <TabButton
          icon={<List />}
          label="Documents In Backlog"
          isActive={activeTab === "BACKLOG_LIST"}
          onClick={() => setActiveTab("BACKLOG_LIST")}
        />
        <TabButton
          icon={<List />}
          label="Archived"
          isActive={activeTab === "DONE_LIST"}
          onClick={() => setActiveTab("DONE_LIST")}
        />
      </div>
      <div className="p-1 space-y-1">
        {activeTab === "UPLOAD" && <FileUploadPage />}
        {activeTab === "LIST" && <DataFileList />}
        {activeTab === "BACKLOG_LIST" && <DataFileList isFromBacklog={true} />}
        {activeTab === "DONE_LIST" && <DataFileList isDone={true} />}
      </div>
    </div>
  );
}

"use client";

import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Upload } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { Card, CardContent } from "@/components/ui/card";
import { toast } from "@/hooks/use-toast";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { nanoid } from "nanoid";

export function FileUploadPage() {
  const { callUploadApi } = useProtectedApi();

  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.type !== "application/pdf") {
        toast({
          title: `Invalid file type for ${file.name}`,
          description: "Please upload a PDF file.",
          variant: "destructive",
        });
        return;
      }
      const fileObj = {
        id: nanoid(),
        file,
        status: "pending",
        progress: 0,
        error: null,
      };
      setFiles((prevFiles) => [...prevFiles, fileObj]);
      uploadFile(fileObj);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
    noClick: true, // We'll handle click manually
  });

  const uploadFile = async (fileObj) => {
    // Update status to 'uploading'
    setFiles((prevFiles) =>
      prevFiles.map((f) =>
        f.id === fileObj.id ? { ...f, status: "uploading" } : f
      )
    );

    const formData = new FormData();
    formData.append("file", fileObj.file);

    try {
      const response = await callUploadApi(
        "/datafiles/upload",
        formData,
        (progress) => {
          // Update progress
          setFiles((prevFiles) =>
            prevFiles.map((f) => (f.id === fileObj.id ? { ...f, progress } : f))
          );
        }
      );
      // Upload successful
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === fileObj.id ? { ...f, status: "done" } : f
        )
      );
      toast({
        title: `File ${fileObj.file.name} uploaded successfully`,
        description: "Your file has been uploaded.",
      });
    } catch (error) {
      // Upload failed
      console.error("Upload error:", error);
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.id === fileObj.id
            ? {
                ...f,
                status: "failed",
                error: error.message || "Unknown error",
              }
            : f
        )
      );
      toast({
        title: `Upload failed for ${fileObj.file.name}`,
        description:
          "There was an error uploading your file. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 w-full">
      <h1 className="text-3xl font-bold mb-8 text-center">File Upload</h1>
      <Card>
        <CardContent className="pt-6">
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors ${
              isDragActive
                ? "border-primary bg-primary/10"
                : "border-muted-foreground"
            }`}
            onClick={open}
          >
            <input {...getInputProps()} />
            <div>
              <Upload className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
              <p className="text-lg mb-2">
                Drag & drop PDF files here, or click to select
              </p>
              <p className="text-sm text-muted-foreground">
                Only PDF files are accepted
              </p>
            </div>
          </div>
          {files.length > 0 && (
            <div className="mt-4 space-y-4">
              {files.map((fileObj) => (
                <div key={fileObj.id} className="flex items-center">
                  <div className="flex-grow">
                    <p className="text-sm font-medium">{fileObj.file.name}</p>
                    {fileObj.status === "uploading" && (
                      <Progress value={fileObj.progress} className="w-full" />
                    )}
                    {fileObj.status === "failed" && (
                      <p className="text-sm text-red-500">
                        Upload failed: {fileObj.error}
                      </p>
                    )}
                  </div>
                  <div className="ml-4">
                    {fileObj.status === "pending" && (
                      <p className="text-sm text-gray-500">Pending</p>
                    )}
                    {fileObj.status === "uploading" && (
                      <p className="text-sm text-blue-500">
                        Uploading... {fileObj.progress}%
                      </p>
                    )}
                    {fileObj.status === "done" && (
                      <p className="text-sm text-green-500">Uploaded</p>
                    )}
                    {fileObj.status === "failed" && (
                      <p className="text-sm text-red-500">Failed</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Iconify from "src/components/iconify";
import CompanyProfile from "./CompanyProfile";
import CompanySettings from "./CompanySettings";
import CompanySpecificFields from "./CompanySpecificFields";
import { WithAPICall } from "../utils/apiUtil";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import cogoToast from "cogo-toast";
import { View500 } from "src/sections/error";
import CompanyNotes from "./CompanyNotes";
import CompanyJobs from "./CompanyJobs";
import CompanyContacts from "./CompanyContacts";
import CompanySpecificData from "./CompanySpecificData";
import CompanyEmployees from "./CompanyEmployees";
import ListProtocols from "../Protocols/ListProtocols";
import CompanyLinkedProviders from "./CompanyLinkedProviders";
import CompanyFormFox from "./CompanyFormFox";
import { addUrlParams, getUrlParams } from "src/utils/url-params";
import CompanyPools from "./CompanyPools";
import CompanyAddonFees from "./CompanyAddonFees";
import CompanyEmailTemplates from "./CompanyEmailTemplates";
import CompanySpecDocuments from "./CompanySpecDocuments";

class CompanyDetails extends React.Component {
  state = {
    currentTab: "INFO",
    companyData: null,
    companyNotes: [],
    isLoading: true,
    isError: false,
  };
  async componentDidMount() {
    await this.getById();
    const urlParams = getUrlParams();
    if (urlParams.currentTab) {
      this.setState({ currentTab: urlParams.currentTab });
    }
  }
  getById = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/company/getById", {
        _id: this.props.id,
      });
      this.setState({ companyData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  createEmployee = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await this.props.apiCallPost("/employee/createFromName", {
        companyId: this.props.id,
        employeeName: "Shibjash",
      });
      //    this.setState({ companyData: res, isLoading: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Employer Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  tabs = [
    {
      value: "INFO",
      label: "Info",
      icon: <Iconify icon="solar:info-circle-broken" width={24} />,
    },
    {
      value: "SETTINGS",
      label: "Settings",
      icon: <Iconify icon="solar:settings-broken" width={24} />,
    },
    {
      value: "JOBS",
      label: "Jobs",
      icon: <Iconify icon="solar:case-round-broken" width={24} />,
    },
    {
      value: "SPECIFIC_FIELDS",
      label: "Specific Fields",
      icon: <Iconify icon="solar:text-field-focus-broken" width={24} />,
    },
    {
      value: "DATA",
      label: "Data Tables",
      icon: <Iconify icon="solar:database-broken" width={24} />,
    },
    {
      value: "NOTES",
      label: "Notes & Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "COMPANY_SPECIFIC_DOCUMENTS",
      label: "Company Spec. Documents",
      icon: <Iconify icon="solar:document-add-linear" width={24} />,
    },
    {
      value: "CONTACTS",
      label: "Contacts",
      icon: <Iconify icon="solar:user-circle-broken" width={24} />,
    },
    {
      value: "EMAIL_TEMPLATES",
      label: "Email Templates",
      icon: <Iconify icon="ic:baseline-email" width={24} />,
    },
    {
      value: "EMPLOYEES",
      label: "Employees",
      icon: <Iconify icon="solar:users-group-rounded-broken" width={24} />,
    },
    {
      value: "PROVIDERS",
      label: "Linked Providers",
      icon: <Iconify icon="solar:link-round-angle-broken" width={24} />,
    },
    {
      value: "PROTOCOLS",
      label: "Protocols",
      icon: <Iconify icon="solar:document-medicine-outline" width={24} />,
    },
    {
      value: "ADDON_FEES",
      label: "Add-On Fees",
      icon: <Iconify icon="solar:dollar-outline" width={24} />,
    },
    {
      value: "POOLS",
      label: "Pools",
      icon: <Iconify icon="solar:settings-minimalistic-broken" width={24} />,
    },
    {
      value: "FORMFOX",
      label: "Form Fox",
      // TODO: convert this icon on grayscale to maintain design language
      icon: (
        <div>
          <img src="/assets/icons/brands/ic_brand_formfox.png" alt="formfox" />
        </div>
      ),
    },
  ];
  renderTabs = () => {
    return (
      <Tabs
        value={this.state.currentTab}
        onChange={(_, value) => {
          addUrlParams({ currentTab: value });
          this.setState({ currentTab: value });
        }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {this.tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
    );
  };
  renderTab = () => {
    switch (this.state.currentTab) {
      case "INFO":
        return (
          <CompanyProfile
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "NOTES":
        return (
          <CompanyNotes
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "COMPANY_SPECIFIC_DOCUMENTS":
        return (
          <CompanySpecDocuments
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "SPECIFIC_FIELDS":
        return (
          <CompanySpecificFields
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "SETTINGS":
        return (
          <CompanySettings
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "JOBS":
        return (
          <CompanyJobs
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );

      case "CONTACTS":
        return (
          <CompanyContacts
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "EMAIL_TEMPLATES":
        return (
          <CompanyEmailTemplates
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "DATA":
        return (
          <CompanySpecificData
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "EMPLOYEES":
        return (
          <CompanyEmployees
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );

      case "PROTOCOLS":
        return (
          <ListProtocols
            companyId={this.props.id}
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "ADDON_FEES":
        return (
          <CompanyAddonFees
            key={this.state.companyData.addOnFees.length}
            companyId={this.props.id}
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "PROVIDERS":
        return (
          <CompanyLinkedProviders
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      case "POOLS":
        return <CompanyPools id={this.props.id} />;
      case "FORMFOX":
        return (
          <CompanyFormFox
            id={this.props.id}
            data={this.state.companyData}
            onUpdate={(key, value) => {
              let companyData = this.state.companyData;
              companyData[key] = value;
              this.setState({ companyData });
            }}
          />
        );
      default:
        return <div>Not Live</div>;
    }
  };
  renderHeader = () => {
    let name =
      this.state.companyData.companyInformalName ||
      this.state.companyData.companyName ||
      "Company Details";
    return (
      <CustomBreadcrumbs
        heading={name}
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Employers", href: "/employers" },
          { name: `Details: ${name}` },
        ]}
        action={
          <Box>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.getById()}
              variant="contained"
              startIcon={
                <Iconify icon="mingcute:refresh-anticlockwise-1-line" />
              }
            >
              Refresh Data
            </Button>
          </Box>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTab()}
      </>
    );
  }
}

export default WithAPICall(CompanyDetails);

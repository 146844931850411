export const formFoxServices = [
  { id: "DOTPHY", name: "DOT Physical Examination" },
  { id: "FFPPE", name: "FormFox Pre-Placement Exam" },
  { id: "AUDIOGRAM", name: "Audiogram" },
  { id: "RESPMEQ", name: "Respirator Medical Evaluation Questionnaire" },
  { id: "RESPIRATOR", name: "Respirator Fit Assessment" },
  { id: "PULMONARY", name: "Pulmonary Function Test" },
  { id: "VISISH", name: "Vision: Ishihara" },
  { id: "VISSNE", name: "Vision: Snellan" },
  { id: "VISJAE", name: "Vision: Jaeger" },
  { id: "VISTIT", name: "Vision: Titmus" },
  { id: "TBPPD1", name: "TB: TB/PPD Skin Test 1 of 2" },
  { id: "TBPPD2", name: "TB: TB/PPD Skin Test 2 of 2" },
  { id: "CHEST1", name: "Exam: Chest X-Ray- 1 view" },
  { id: "CHEST2", name: "Exam: Chest X-Ray- 2 view" },
  { id: "VACMMR1", name: "Vaccine: MMR (Measles, Mumps, Rubella)- 1 of 2" },
  { id: "VANMMR2", name: "Vaccine: MMR (Measles, Mumps, Rubella)- 2 of 2" },
  { id: "VACVAR1", name: "Vaccine: Varicella (Chicken Pox)- 1 of 2" },
  { id: "VANVAR2", name: "Vaccine: Varicella (Chicken Pox)- 2 of 2" },
  { id: "VANHEPB1", name: "Vaccine: Hepatitis B-1 of 3" },
  { id: "VARHEPB2", name: "Vaccine: Hepatitis B-2 of 3" },
  { id: "VARHEPB3", name: "Vaccine: Hepatitis B-3 of 3" },
  { id: "VATHEPA1", name: "Vaccine: Hepatitis A- 1 of 2" },
  { id: "VARHEBA2", name: "Vaccine: Hepatitis A- 2 of 2" },
  { id: "VARAB1", name: "Vaccine: Hepatitis A/B (Twinrix)-1 of 3" },
  { id: "VARAB2", name: "Vaccine: Hepatitis A/B (Twinrix)-2 of 3" },
  { id: "VARAB3", name: "Vaccine: Hepatitis A/B (Twinrix)-3 of 3" },
  { id: "VANTDAP", name: "Vaccine: TDAP (Tetanus, Diphtheria)" },
  { id: "VANFLU", name: "Vaccine: Influenza (Annual Flu)" },
  { id: "KRAUSE", name: "Exam: Kraus Weber Evaluation" },
  { id: "LIFT1", name: "Exam: Lift Test Level 1 (50 lb)" },
  { id: "LIFT2", name: "Exam: Lift Test Level 2 (75 lb)" },
  { id: "DOTU", name: "DOT Urine Collection" },
  { id: "NDOTU", name: "Non DOT Urine Collection" },
  { id: "BALC", name: "Breath Alcohol Test" },
  { id: "SALC", name: "Saliva Alcohol Test" },
  { id: "IDRUG", name: "Instant Drug Test" },
  { id: "ONSITE", name: "Onsite Drug Collection" },
  { id: "AFTERH", name: "After Hours Drug Collection" },
  { id: "HAIR", name: "Hair Collection" },
  { id: "SDRG", name: "Saliva Drug Collection" },
  { id: "OBSRV", name: "Observed Collection" },
  { id: "MOBILE", name: "Mobile Drug Collection" },
  { id: "BLOOD", name: "Clinical Blood Collection" },
  { id: "", name: "None" },
];

/**
 * UR – UrineBL – BloodBR – Breath AlcoholSL – Saliva AlcoholHR – HairQT – Quick Test UrineSLD – Saliva DrugQTS – Quick Test SalivaPY – Physical/OHS
 */

export const formFoxSampleTypes = [
  { id: "1", name: "Urine", value: "UR" },
  { id: "2", name: "Blood", value: "BL" },
  { id: "2", name: "Blood/Urine", value: "BL/UR" }, // hack
  { id: "3", name: "Breath Alcohol", value: "BR" },
  { id: "4", name: "Saliva Alcohol", value: "SL" },
  { id: "5", name: "Quick Test Urine", value: "QT" },
  { id: "6", name: "Hair", value: "HR" },
  { id: "7", name: "Saliva Drug", value: "SLD" },
  { id: "8", name: "Quick Test Saliva", value: "QTS" },
  { id: "9", name: "Physical/OHS", value: "PY" },
  { id: "10", name: "" },
];

export const undoFFSampleType = (value) => {
  for (let i = 0; i < formFoxSampleTypes.length; i++) {
    if (formFoxSampleTypes[i].value === value) {
      return formFoxSampleTypes[i].name;
    }
  }
  return value;
};

export const formFoxReasonForTest = [
  { id: "CAU", name: "ReasonableSuspicion/Cause" },
  { id: "RAN", name: "Random" },
  { id: "ACC", name: "Post Accident" },
  { id: "PER", name: "Phys/Period" },
  { id: "PRE", name: "Pre-Employment" },
  { id: "RTD", name: "Return To Duty" },
  { id: "FOL", name: "Follow-up" },
  { id: "PREA", name: "Pre-Assignment" },
  { id: "CFFD", name: "Company Fit For Duty" },
  { id: "OTHR", name: "Other" },
  { id: "BLIND", name: "QC/Blind Sample" },
  { id: "UNIT", name: "Unit" },
  { id: "VOL", name: "Voluntary" },
];

export const PHYSICAL_EXAM_CERTIFICATION_TYPES = [
  { id: "N", value: "N", name: "New Certification" },
  { id: "R", value: "R", name: "Re Certification" },
  { id: "F", value: "F", name: "Follow Up" },
];

// export const formFoxLabNames = ["CRL", "LabCorp"];

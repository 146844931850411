import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Box,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  Chip,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { niceDate } from "../../utils/fn";
import useVisitStore from "@/store/useVisitStore";

const frequencies = [500, 1000, 2000, 3000, 4000, 6000, 8000];

const getDecibelColor = (value, disableHeader = false) => {
  if (disableHeader) return "inherit";
  const numValue = Number(value);
  if (numValue > 85) return "error.darker";
  if (numValue > 70) return "error.dark";
  if (numValue > 55) return "error.main";
  if (numValue > 40) return "error.light";
  if (numValue > 25) return "error.lighter";
  return "inherit";
};
const calculateSTS = (currentData, baselineData, ear) => {
  console.log({ currentData, baselineData, ear });
  const freqsToCheck = [2000, 3000, 4000];
  const currentAvg =
    freqsToCheck.reduce(
      (sum, freq) => sum + Number(currentData[`${ear}EarDbAt${freq}Hz`]),
      0
    ) / 3;
  const baselineAvg =
    freqsToCheck.reduce(
      (sum, freq) => sum + Number(baselineData[`${ear}EarDbAt${freq}Hz`]),
      0
    ) / 3;
  return currentAvg - baselineAvg;
};
const correctionTableMale = {
  1000: [
    5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 8, 8, 8, 8,
    8, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 11, 11,
  ],
  2000: [
    3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7,
    8, 8, 9, 9, 9, 9, 10, 10, 11, 11, 11, 12, 12, 13,
  ],
  3000: [
    4, 4, 4, 4, 5, 5, 6, 6, 6, 6, 7, 7, 7, 8, 8, 8, 9, 9, 9, 10, 10, 11, 12, 12,
    13, 13, 14, 15, 16, 16, 17, 18, 18, 19, 20, 21, 22, 22, 23,
  ],
  4000: [
    5, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 14, 14, 16,
    16, 17, 18, 19, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32,
  ],
  6000: [
    8, 8, 8, 9, 9, 10, 10, 11, 12, 12, 13, 14, 14, 15, 15, 16, 17, 17, 18, 19,
    20, 20, 21, 22, 23, 24, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37,
    38,
  ],
};
const correctionTableFemale = {
  1000: [
    7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11,
    11, 11, 12, 12, 12, 12, 12, 12, 13, 13, 13, 13, 13, 13, 14, 14,
  ],
  2000: [
    4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 8, 9,
    9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 11, 12, 12, 12,
  ],
  3000: [
    3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 9, 9, 9, 10,
    10, 11, 11, 11, 11, 12, 12, 12, 13, 13, 14, 14, 15, 16,
  ],
  4000: [
    3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 9, 9, 9, 10,
    10, 11, 11, 12, 12, 12, 13, 13, 14, 14, 15, 15, 16, 16,
  ],
  6000: [
    6, 6, 6, 7, 7, 7, 8, 8, 8, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 13, 13, 14,
    14, 15, 15, 16, 16, 16, 16, 17, 17, 18, 18, 19, 19, 20, 20, 21, 21, 22,
  ],
};

// Helper function to calculate age
function calculateAge(dob, encounterDate) {
  const birthDate = new Date(dob);
  const encounter = new Date(encounterDate);
  let age = encounter.getFullYear() - birthDate.getFullYear();
  const m = encounter.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && encounter.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Function to get age correction
function getAgeCorrection(sex, age, frequency) {
  const correctionTable =
    sex === "Male" ? correctionTableMale : correctionTableFemale;
  // check if frequency in key
  let keys = Object.keys(correctionTable);
  let f = frequency.toString();
  let keysS = keys.map((x) => x.toString());
  if (!keysS.includes(f)) {
    return 0;
  }

  // Cap age at 60 for table lookup purposes
  const ageForTable = Math.min(60, age);

  // Calculate index for table (starting at age 20)
  const ageIndex = Math.max(0, ageForTable - 20);

  // Get correction for the given frequency
  return correctionTable[frequency][ageIndex];
}

// Main function to calculate age corrected audiogram value
function calculateAgeCorrectedAudiogram(
  dob,
  sex,
  encounterDate,
  frequency,
  value
) {
  let ageAtEncounterDate = calculateAge(dob, encounterDate);

  let ageCorrection = getAgeCorrection(sex, ageAtEncounterDate, frequency);
  return Math.max(value - ageCorrection, 0);
}

export const AudiogramDataViewer = ({
  data_,
  onUpdate,
  fromClearancePage = false,
  disableHeader = false,
  style = {},
}) => {
  const theme = useTheme();
  const { callApi } = useProtectedApi();
  const [data, setData] = useState(data_);
  const [editMode, setEditMode] = useState(false);
  const [useOSHACorrection, setUseOSHACorrection] = useState(false);
  const [abnormalFields, setAbnormalFields] = useState(data.hasAbnormal || []);
  const [hasChange, setHasChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [historicalData, setHistoricalData] = useState([]);
  const { visit, loadVisit } = useVisitStore();
  const [employeeData, setEmployeeData] = useState(visit?.employee);
  if (!visit && fromClearancePage) {
    return null;
  }

  // Fetch historical audiogram data when on clearance page
  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (fromClearancePage && visit?.employee?._id) {
        try {
          const res = await callApi("/visit/getHistoricalMedicalRecords", {
            employeeId: visit?.employee?._id,
          });
          setHistoricalData(res);
        } catch (error) {
          console.error("Failed to fetch historical audiogram data:", error);
          cogoToast.error("Failed to load historical audiogram data");
        }
      }
    };
    console.log(
      "useEffect::fetchHistoricalData",
      fromClearancePage,
      visit?.employee?._id
    );
    fetchHistoricalData();
  }, []);

  const handleSave = () => {
    setIsSaving(true);
    onUpdate(data);
    setIsSaving(false);
    setHasChange(false);
  };
  const setAudiogramBaseline = async (ear, recordId) => {
    if (!fromClearancePage) {
      return null;
    }
    let payload = {
      employeeId: employeeData._id,
      ear: ear,
      recordId: recordId,
    };
    try {
      const res = await callApi("/visit/setAudiogramBaseline", payload);
      setEmployeeData({ ...res });
    } catch (error) {
      console.error("Failed to set audiogram baseline:", error);
      cogoToast.error("Failed to set audiogram baseline");
    }
  };
  let saveElem = null;
  if (hasChange && !isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="overline">Unsaved Changes</Typography>
        <Button
          variant="contained"
          onClick={handleSave}
          startIcon={<Iconify icon="bx:save" />}
        >
          Save
        </Button>
      </Stack>
    );
  }
  if (isSaving) {
    saveElem = (
      <Stack direction="row" spacing={2}>
        <Typography variant="overline">Saving...</Typography>
        <Iconify icon="pulse-3" />
      </Stack>
    );
  }

  const handleChange = (field, value) => {
    if (!onUpdate) {
      cogoToast.error("Updates are restricted.");
      return;
    }
    const updatedData = { ...data, [field]: value };
    setData(updatedData);
    setHasChange(true);
    setIsSaving(false);
  };
  const toggleAbnormal = (field) => {
    const newAbnormalFields = abnormalFields.includes(field)
      ? abnormalFields.filter((f) => f !== field)
      : [...abnormalFields, field];
    setAbnormalFields(newAbnormalFields);
    setData({ ...data, hasAbnormal: newAbnormalFields });
  };
  const isAbnormal = (field) => {
    if (disableHeader) return false;
    return abnormalFields.includes(field);
  };

  const getTooltipText = (field) => {
    return isAbnormal(field)
      ? "This result is potentially abnormal. Click to mark it as normal."
      : "This result is normal. Click to mark it as potentially abnormal.";
  };
  const renderHeader = () => {
    if (abnormalFields.length === 0) {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Audiogram</Typography>
          {saveElem}
        </Box>
      );
    }

    const abnormalFieldNames = abnormalFields.join(", ");

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          breakAfter: "avoid",
        }}
      >
        <Box display="flex" alignItems="center">
          <Iconify
            icon="mdi:warning"
            sx={{ mr: 1, color: theme.palette.error.main }}
          />
          <Typography variant="subtitle1">
            Audiogram | Red Flags: {abnormalFieldNames}
          </Typography>
        </Box>
        {saveElem}
      </Box>
    );
  };
  const renderCurrentOnly = () => {
    let dataRow = [data];
    return (
      <TableContainer sx={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={frequencies.length}>LE</TableCell>
              <TableCell colSpan={frequencies.length}>RE</TableCell>
            </TableRow>
            <TableRow>
              {frequencies.map((freq) => (
                <TableCell key={`le-${freq}`} align="center">
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
              {frequencies.map((freq) => (
                <TableCell key={`re-${freq}`} align="center">
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((rowData, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  {["left", "right"].map((ear) => (
                    <React.Fragment key={ear}>
                      {frequencies.map((freq, i) => {
                        const field = `${ear}EarDbAt${freq}Hz`;
                        const value = rowData[field] || 0;
                        const correctedValue = Number(value);
                        return (
                          <TableCell
                            key={`${ear}-${freq}`}
                            align="center"
                            sx={{
                              bgcolor: getDecibelColor(
                                correctedValue,
                                disableHeader
                              ),
                              color:
                                Number(correctedValue) > 55
                                  ? "white"
                                  : "inherit",
                              borderRight:
                                i === frequencies.length - 1
                                  ? "3px solid #00B8D9 !important"
                                  : "none",
                            }}
                          >
                            <Box>
                              {rowData && editMode ? (
                                <TextField
                                  size="small"
                                  value={value || ""}
                                  onChange={(e) =>
                                    handleChange(field, e.target.value)
                                  }
                                  sx={{ width: "50px" }}
                                />
                              ) : (
                                correctedValue
                              )}
                            </Box>
                          </TableCell>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderDecibelTable = () => {
    if (!fromClearancePage) {
      return renderCurrentOnly();
    }

    let currentData = { ...data, isCurrent: true };
    let dataToBeRendered = [currentData];
    let hd = historicalData;
    let leSTS = null;
    let leSTSChip = null;
    let markLESTSBaseline = null;
    let reSTS = null;
    let reSTSChip = null;
    let markRESTSBaseline = null;
    /*if (!hd || hd.length === 0) {
      // TODO: Check for current or historical data as baseline.
    } else {*/
    dataToBeRendered = [...dataToBeRendered, ...historicalData];
    let ee = employeeData;
    let baselineLeftId = ee.audiogramBaselineLeftEar;
    let baselineRightId = ee.audiogramBaselineRightEar;

    if (baselineLeftId) {
      let idx = dataToBeRendered.findIndex((d) => d._id === baselineLeftId);
      if (idx !== -1) {
        dataToBeRendered[idx].baselineLeft = true;
        leSTS = calculateSTS(
          dataToBeRendered[idx],
          dataToBeRendered[0],
          "left"
        );
        leSTSChip = (
          <Chip
            label={`LE STS: ${leSTS.toFixed(1)} dB`}
            color={reSTS > 10 ? "error" : "success"}
            size="small"
          />
        );
      }
    }
    if (baselineRightId) {
      let idx = dataToBeRendered.findIndex((d) => d._id === baselineRightId);
      if (idx !== -1) {
        dataToBeRendered[idx].baselineRight = true;
        reSTS = calculateSTS(
          dataToBeRendered[idx],
          dataToBeRendered[0],
          "right"
        );
        reSTSChip = (
          <Chip
            label={`LE STS: ${reSTS.toFixed(1)} dB`}
            color={reSTS > 10 ? "error" : "success"}
            size="small"
          />
        );
      }
    }

    if (leSTS > 10 || !hd || !hd.length) {
      markLESTSBaseline = (
        <Tooltip title="Mark current as baseline for Left Ear">
          <IconButton
            size="small"
            onClick={() => {
              setAudiogramBaseline("left", currentData._id);
            }}
          >
            <Iconify icon="mdi:flag-variant" />
          </IconButton>
        </Tooltip>
      );
    } else if (baselineLeftId === currentData._id) {
      markLESTSBaseline = (
        <Chip
          label="Current data is set as the baseline"
          color="success"
          size="small"
        />
      );
    } else {
      markLESTSBaseline = (
        <Chip label="Within Limits" color="success" size="small" />
      );
    }

    if (reSTS > 10 || !hd || !hd.length) {
      markRESTSBaseline = (
        <IconButton
          size="small"
          onClick={() => {
            setAudiogramBaseline("right", currentData._id);
          }}
        >
          <Iconify icon="mdi:flag-variant" />
        </IconButton>
      );
    } else if (baselineRightId === currentData._id) {
      markRESTSBaseline = (
        <Chip
          label="Current data is set as the baseline"
          color="success"
          size="small"
        />
      );
    } else {
      markRESTSBaseline = (
        <Chip label="Within Limits" color="success" size="small" />
      );
    }

    return (
      <TableContainer
        sx={{
          maxWidth: "100%",
          overflowX: "auto",
          scrollbarWidth: disableHeader ? "none" : "",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell colSpan={frequencies.length}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="overline">LE</Typography>
                  {leSTSChip}
                  {markLESTSBaseline}
                </Stack>
              </TableCell>
              <TableCell colSpan={frequencies.length}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="overline">RE</Typography>
                  {reSTSChip}
                  {markRESTSBaseline}
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {frequencies.map((freq, i) => (
                <TableCell
                  key={`le-${freq}`}
                  align="center"
                  sx={{
                    borderRight:
                      i === frequencies.length - 1
                        ? "3px solid #00B8D9 !important"
                        : "none",
                  }}
                >
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
              {frequencies.map((freq) => (
                <TableCell key={`re-${freq}`} align="center">
                  {freq === 500 ? ".5k" : `${freq / 1000}k`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToBeRendered.map((rowData, rowIndex) => {
              let dt = "Current";
              if (rowIndex > 0) {
                dt = niceDate(rowData.createdAt);
              }
              let dateChipColor = dt === "Current" ? "secondary" : "info";
              let dateChip = (
                <Chip label={dt} size="small" color={dateChipColor} />
              );
              let baselineIcon = <Iconify icon="ic:baseline-star" />;
              let rightBaselineChip = null;
              let leftBaselineChip = null;
              if (rowData.baselineLeft) {
                leftBaselineChip = (
                  <Chip
                    label="LE"
                    color="success"
                    size="small"
                    icon={baselineIcon}
                  />
                );
              }
              if (rowData.baselineRight) {
                rightBaselineChip = (
                  <Chip
                    label="RE"
                    color="success"
                    size="small"
                    icon={baselineIcon}
                  />
                );
              }
              return (
                <TableRow
                  key={rowIndex}
                  sx={{
                    verticalAlign: "middle",
                    borderBottom: "1px solid #00B8D9",
                  }}
                >
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {dateChip}
                      {leftBaselineChip}
                      {rightBaselineChip}
                    </Stack>
                  </TableCell>
                  {["left", "right"].map((ear) => (
                    <React.Fragment key={ear}>
                      {frequencies.map((freq, i) => {
                        const field = `${ear}EarDbAt${freq}Hz`;
                        const value = rowData[field];

                        let correctedValue = calculateAgeCorrectedAudiogram(
                          employeeData.employeeDoB,
                          employeeData.employeeSex,
                          dt === "Current" ? new Date() : rowData.createdAt,
                          freq,
                          Number(value) || 0
                        );
                        let valueChip = Number(value) || 0;
                        if (useOSHACorrection) {
                          valueChip = (
                            <Tooltip
                              title={`Not corrected for age: ${Number(value) || 0}`}
                            >
                              {correctedValue}
                            </Tooltip>
                          );
                        }
                        return (
                          <TableCell
                            key={`${ear}-${freq}`}
                            align="center"
                            sx={{
                              bgcolor: getDecibelColor(
                                correctedValue,
                                disableHeader
                              ),
                              color:
                                Number(correctedValue) > 55
                                  ? "white"
                                  : "inherit",
                              borderRight:
                                i === frequencies.length - 1 && ear === "left"
                                  ? "3px solid #00B8D9 !important"
                                  : "none",
                            }}
                          >
                            {valueChip}
                          </TableCell>
                        );
                      })}
                    </React.Fragment>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderEditMode = () => {
    if (!onUpdate) {
      return null;
    }
    return (
      <FormControlLabel
        control={
          <Switch checked={editMode} onChange={() => setEditMode(!editMode)} />
        }
        label="Edit Mode"
      />
    );
  };
  const renderOshaCorrection = () => {
    let ee = employeeData;
    let dob = ee?.employeeDoB;
    let sex = ee?.employeeSex;
    if (!dob || !sex) {
      return null;
    }
    return (
      <FormControlLabel
        control={
          <Switch
            checked={useOSHACorrection}
            onChange={() => setUseOSHACorrection(!useOSHACorrection)}
          />
        }
        label="OSHA Correction"
      />
    );
  };

  return (
    <div
      style={{
        backgroundColor: hasChange ? theme.palette.warning.lighter : "inherit",
        ...style,
      }}
    >
      <Card
        sx={{
          display: disableHeader ? "none" : "",
          p: 2,
          m: 1,
          bgcolor:
            abnormalFields.length > 0
              ? theme.palette.error.light
              : theme.palette.info.lighter,
        }}
      >
        {renderHeader()}
      </Card>
      {renderEditMode()}
      {!onUpdate && <Box mb={2}>{renderOshaCorrection()}</Box>}
      <Typography variant="h6" gutterBottom>
        Decibel Thresholds
      </Typography>
      {renderDecibelTable()}
    </div>
  );
};

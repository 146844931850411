import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import { NoResultView, View500 } from "src/sections/error";
import {
  Alert,
  AlertTitle,
  Card,
  Drawer,
  Typography,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Chip,
  Avatar,
  Box,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import _ from "lodash";
import ListServices from "../Services/ListServices";
import CompanyEmployees from "../Company/CompanyEmployees";
import cogoToast from "cogo-toast";
import Grid from "@mui/material/Unstable_Grid2";
import { CLEARANCE_TEMPLATES } from "./data/clearance-templates";

class ProtocolServices extends React.Component {
  state = {
    isLoading: true,
    isSaving: false,
    openServicesDrawer: false,
    masterServicesPopulated: [],
    masterServices: [],
    serviceMetadata: [],
    openCriteriaDrawer: false,
    currentlyEditingMasterServiceId: null,
    companyDocuments: [],
  };
  async componentDidMount() {
    try {
      let res = await this.props.apiCallPost("/files/getByEntityId", {
        entityId: this.props.companyId,
        entityType: "CompanySpecificDocuments",
      });
      const masterServices = await this.props.apiCallPost(
        "/master-service/masterServiceGetAll",
        {}
      );
      this.setState({
        companyDocuments: res,
        masterServicesPopulated: masterServices,
      });
    } catch (err) {
    } finally {
      this.setState({ ...this.props.data, isLoading: false });
    }
  }
  COST_MODEL_OPTIONS = [
    "Included In Protocol",
    "Flat Rate",
    "Additional Flat Rate",
    "Additional Percentage",
  ];
  SERVICE_CRITERIA_OPTIONS = [
    "Always Required",
    "Conditional: Criteria",
    "Conditional: NL",
    "Optional",
  ];
  FREQUENCY_CRITERIA_OPTIONS = ["Conditional: Criteria", "Conditional: NL"];
  TYPE_MAPS = {
    equals: "=",
    notEquals: "!=",
    true: "Yes",
    false: "No",
    greaterThan: ">",
    lessThan: "<",
    greaterThanOrEqual: ">=",
    lessThanOrEqual: "<=",
    AND: "And",
    OR: "Or",
  };
  FREQUENCY_UNIT_OPTIONS = ["Day(s)", "Week(s)", "Month(s)", "Year(s)"];
  updateScalarValue = async (key, value) => {
    this.setState({
      isSaving: true,
    });
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        {
          companyProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
      this.setState({
        ...res,
        isSaving: false,
      });
      cogoToast.success("Protocol Data Updated");
    } catch (err) {
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
      this.setState({
        isSaving: false,
        [key]: this.props.data[key],
      });
    }
  };
  updateArrayValue = async () => {
    this.setState({
      isSaving: true,
    });
    let payload = [
      {
        companyProtocolId: this.props.protocolId,
        key: "masterServices",
        value: this.state.masterServices,
      },
      {
        companyProtocolId: this.props.protocolId,
        key: "serviceMetadata",
        value: this.state.serviceMetadata,
      },
    ];
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/companyProtocolUpdateScalarValue",
        payload
      );
      this.setState({ ...res, isSaving: false });
      cogoToast.success("Protocol Data Updated");
    } catch (err) {
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
      this.setState({
        isSaving: false,
        masterServices: this.props.data.masterServices,
        serviceMetadata: this.props.data.serviceMetadata,
      });
    }
  };
  renderAlert = () => {
    return (
      <Alert severity="warning">
        <AlertTitle>Careful!</AlertTitle>
        Be thoughtful while changing data on this tab. It will have a cascading
        effect!
      </Alert>
    );
  };
  renderHeader = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="h6">Services In Scope</Typography>
        <IconButton
          size="small"
          color="primary"
          disabled={this.state.isSaving}
          onClick={() => {
            this.setState({ openServicesDrawer: true });
          }}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "primary.main",
            color: "primary.contrastText",
            "&:hover": {
              bgcolor: "primary.dark",
            },
          }}
        >
          <Iconify icon="mingcute:settings-5-line" />
        </IconButton>
      </Stack>
    );
  };
  renderServices = () => {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (!this.state.masterServices || !this.state.masterServices.length) {
      return <NoResultView />;
    }
    let l = this.state.masterServices;
    return l.map((row, idx) => {
      return (
        <Grid key={`${row}__${idx}`} container spacing={3}>
          <Grid xs={12} md={4}>
            <ListItemText
              primary={`${this.state.serviceMetadata[idx].serviceName}`}
              secondary="Set service level conditions, criteria and information here."
              primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
              secondaryTypographyProps={{ component: "span" }}
            />
          </Grid>
          <Grid xs={12} md={8}>
            {" "}
            <Stack
              spacing={1}
              sx={{
                p: 3,
                borderRadius: 2,
                bgcolor: "background.neutral",
              }}
            >
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Original Service Name"}
                    secondary="The name of the service as it is known in the Master Service List."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderOriginalServiceName(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Service Name Alias"}
                    secondary="You can edit the service name to the employer's preferred way of referring to it."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderServiceName(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Service Code"}
                    secondary="Set Billing Codes for employers to reconcile - if necessary."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderServiceCode(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />{" "}
              <FormControlLabel
                label={
                  <ListItemText
                    primary="Accept Valid Results (Days)"
                    secondary="The number of days in the past you can look back and accept results of this service - in case it was not completed in the visit. Set to 0 if you want to disallow this."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderServiceValidUpto(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Cost Model"}
                    secondary="Cost model for this service."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderCostModel(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"This is Non-DOT Service"}
                    secondary="If this toggle is set to on, then the service will be marked as non-DOT service, even if the protocol is marked as DOT."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderIsNonDotService(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Visibility To Employer"}
                    secondary="If this toggle is set to off, then the employer won't be able to see this service from their account."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderEmployerVisibility(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Service Condition"}
                    secondary="If a service is done conditionally as part of this protocol, you can configure that here."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={this.renderServiceCriteria(row, idx)}
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />{" "}
              <ListItemText
                primary={"Service Frequency"}
                secondary="If a service frequency differs from the protocol frequency, you can add that and the conditions for those scenarios here."
                primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                secondaryTypographyProps={{ component: "span" }}
              />
              {this.renderServiceFrequency(row, idx)}
              <FormControlLabel
                label={
                  <ListItemText
                    primary={"Company Specific Documents"}
                    secondary="Select company-specific documents to attach to the clinic authorization."
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={
                  <Select
                    multiple
                    value={
                      this.state.serviceMetadata[idx]
                        .companySpecificDocuments || []
                    }
                    onChange={(e) => {
                      let serviceMetadata = [...this.state.serviceMetadata];
                      serviceMetadata[idx].companySpecificDocuments =
                        e.target.value;
                      console.log(e.target.value);
                      this.setState({ serviceMetadata }, () => {
                        this.updateScalarValue(
                          "serviceMetadata",
                          this.state.serviceMetadata
                        );
                      });
                    }}
                  >
                    {this.state.companyDocuments.map((doc, idx) => (
                      <MenuItem key={idx} value={doc._id}>
                        {doc.description}
                      </MenuItem>
                    ))}
                  </Select>
                }
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              />
              {/* <FormControlLabel
                label={
                  <ListItemText
                    primary={"Clearance Template Document"}
                    secondary="Select the relevant clearance template"
                    primaryTypographyProps={{ typography: "h8", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                }
                labelPlacement="start"
                control={
                  <FormControl>
                    <InputLabel id="label">
                      Select Clearance Document Template
                    </InputLabel>
                    <Select
                      sx={{
                        minWidth: "250px",
                      }}
                      labelId="label"
                      value={
                        this.state.serviceMetadata[idx].clearanceTemplateId
                      }
                      onChange={(e) => {
                        let serviceMetadata = this.state.serviceMetadata;
                        serviceMetadata[idx].clearanceTemplateId =
                          e.target.value;
                        this.setState({ serviceMetadata }, () => {
                          this.updateScalarValue(
                            "serviceMetadata",
                            this.state.serviceMetadata
                          );
                        });
                      }}
                    >
                      {CLEARANCE_TEMPLATES.map((t, idx) => (
                        <MenuItem key={idx} value={t.templateId}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                  boxShadow: 2,
                }}
              /> */}
            </Stack>
          </Grid>
        </Grid>
      );
    });
  };

  renderOriginalServiceName = (row, idx) => {
    const originalServiceName = this.state.masterServicesPopulated.find(
      (each) => each._id === row
    )?.serviceName;
    return (
      <TextField
        disabled
        sx={{
          minWidth: "250px",
        }}
        multiline
        value={originalServiceName}
      />
    );
  };

  renderServiceName = (_, idx) => {
    return (
      <TextField
        disabled={this.state.isSaving}
        sx={{
          minWidth: "250px",
        }}
        label="Service Name"
        value={this.state.serviceMetadata[idx].serviceName}
        onBlur={() => {
          this.updateScalarValue("serviceMetadata", this.state.serviceMetadata);
        }}
        onChange={(e) => {
          let serviceMetadata = this.state.serviceMetadata;
          serviceMetadata[idx].serviceName = e.target.value;
          this.setState({
            serviceMetadata,
          });
        }}
      />
    );
  };
  renderServiceCode = (_, idx) => {
    return (
      <TextField
        disabled={this.state.isSaving}
        sx={{
          minWidth: "250px",
        }}
        label="Service Code"
        value={this.state.serviceMetadata[idx].serviceCode}
        onBlur={() => {
          this.updateScalarValue("serviceMetadata", this.state.serviceMetadata);
        }}
        onChange={(e) => {
          let serviceMetadata = this.state.serviceMetadata;
          serviceMetadata[idx].serviceCode = e.target.value;
          this.setState({
            serviceMetadata,
          });
        }}
      />
    );
  };
  renderServiceValidUpto = (_, idx) => {
    return (
      <TextField
        disabled={this.state.isSaving}
        sx={{
          minWidth: "250px",
        }}
        label="Accept Valid Results (Days)"
        value={this.state.serviceMetadata[idx].acceptValidUpto}
        onBlur={() => {
          this.updateScalarValue("serviceMetadata", this.state.serviceMetadata);
        }}
        onChange={(e) => {
          let serviceMetadata = this.state.serviceMetadata;
          serviceMetadata[idx].acceptValidUpto = parseInt(e.target.value);
          this.setState({
            serviceMetadata,
          });
        }}
      />
    );
  };
  renderIsNonDotService = (_, idx) => {
    return (
      <Switch
        disabled={this.state.isSaving || this.state.isDot == false}
        checked={this.state.serviceMetadata[idx].serviceIsNonDOT}
        onChange={() => {
          let serviceMetadata = this.state.serviceMetadata;
          serviceMetadata[idx].serviceIsNonDOT =
            !serviceMetadata[idx].serviceIsNonDOT;
          this.setState(
            {
              serviceMetadata,
            },
            () => {
              this.updateScalarValue("serviceMetadata", serviceMetadata);
            }
          );
        }}
      />
    );
  };
  renderEmployerVisibility = (_, idx) => {
    return (
      <Switch
        disabled={this.state.isSaving}
        checked={this.state.serviceMetadata[idx].isVisibleToCompany}
        onChange={() => {
          let serviceMetadata = this.state.serviceMetadata;
          serviceMetadata[idx].isVisibleToCompany =
            !serviceMetadata[idx].isVisibleToCompany;
          this.setState(
            {
              serviceMetadata,
            },
            () => {
              this.updateScalarValue("serviceMetadata", serviceMetadata);
            }
          );
        }}
      />
    );
  };
  renderCostOptionsBasedOnModel = (_, idx) => {
    let x = this.state.serviceMetadata[idx];
    let cm = x.serviceCostModel;
    if (cm === "Included In Protocol") {
      return;
    } else if (cm === "Flat Rate") {
      return (
        <TextField
          disabled={this.state.isSaving}
          key={`${x._id}__${idx}__base_usd`}
          label="Flat Rate (USD)"
          value={x.serviceBaseCostUsd}
          onBlur={() => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceBaseCostUsd = parseFloat(
              serviceMetadata[idx].serviceBaseCostUsd
            );
            this.setState(
              {
                serviceMetadata,
              },
              () => {
                this.updateScalarValue(
                  "serviceMetadata",
                  this.state.serviceMetadata
                );
              }
            );
          }}
          onChange={(e) => {
            let serviceMetadata = this.state.serviceMetadata;
            let newValue = e.target.value;
            serviceMetadata[idx].serviceBaseCostUsd = newValue;
            this.setState({
              serviceMetadata,
            });
          }}
        />
      );
    } else if (cm === "Additional Flat Rate") {
      return (
        <TextField
          disabled={this.state.isSaving}
          key={`${x._id}__${idx}__additional_usd`}
          label="Additional USD"
          value={x.serviceFlatCostPlusUsd}
          onBlur={() => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceFlatCostPlusUsd = parseFloat(
              serviceMetadata[idx].serviceFlatCostPlusUsd
            );
            this.setState(
              {
                serviceMetadata,
              },
              () => {
                this.updateScalarValue(
                  "serviceMetadata",
                  this.state.serviceMetadata
                );
              }
            );
          }}
          onChange={(e) => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceFlatCostPlusUsd = e.target.value;
            this.setState({
              serviceMetadata,
            });
          }}
        />
      );
    } else if (cm === "Additional Percentage") {
      return (
        <TextField
          disabled={this.state.isSaving}
          key={`${x._id}__${idx}__percentage`}
          label="Additional Percentage"
          value={x.serviceFlatCostPlusPercentage}
          onBlur={() => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceFlatCostPlusPercentage = parseFloat(
              serviceMetadata[idx].serviceFlatCostPlusPercentage
            );
            this.setState(
              {
                serviceMetadata,
              },
              () => {
                this.updateScalarValue(
                  "serviceMetadata",
                  this.state.serviceMetadata
                );
              }
            );
          }}
          onChange={(e) => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceFlatCostPlusPercentage = e.target.value;
            this.setState({
              serviceMetadata,
            });
          }}
        />
      );
    }
  };
  renderCostModel = (_, idx) => {
    return (
      <Stack spacing={2}>
        <FormControlLabel
          key={`${_._id}__${idx}__cost_model`}
          control={
            <Select
              disabled={this.state.isSaving}
              sx={{
                minWidth: "250px",
              }}
              key={`${_._id}__${idx}__cost_model`}
              value={this.state.serviceMetadata[idx].serviceCostModel}
              onChange={(e) => {
                let sm = this.state.serviceMetadata[idx];
                if (sm.serviceCostModel === e.target.value) {
                  return;
                }

                sm.serviceCostModel = e.target.value;
                sm.serviceBaseCostUsd = null;
                sm.serviceFlatCostPlusUsd = null;
                sm.serviceFlatCostPlusPercentage = null;

                if (e.target.value === "Included In Protocol") {
                  sm.isCostIncluded = true;
                } else {
                  sm.isCostIncluded = false;
                }
                let serviceMetadata = this.state.serviceMetadata;
                serviceMetadata[idx] = sm;
                this.setState(
                  {
                    serviceMetadata: this.state.serviceMetadata,
                  },
                  () => {
                    this.updateScalarValue("serviceMetadata", serviceMetadata);
                  }
                );
              }}
            >
              {this.COST_MODEL_OPTIONS.map((each) => {
                return <MenuItem value={each}>{each}</MenuItem>;
              })}
            </Select>
          }
          sx={{
            m: 0,
            width: 1,
            justifyContent: "space-between",
          }}
        />
        {this.renderCostOptionsBasedOnModel(_, idx)}
      </Stack>
    );
  };
  renderCriteriaNicely = (criteria) => {
    if (!criteria) {
      return (
        <Box>
          {" "}
          <Chip label="Not Set" variant="outlined" color="error" />
        </Box>
      );
    }
    return <Stack>{this.renderNode(criteria)}</Stack>;
  };
  renderCondition = (node, condition, isLast, level) => {
    let prefix = isLast ? "" : `${node.type}`;
    return (
      <span key={Math.random()}>
        {this.renderNode(condition, level)}
        {prefix ? (
          <Chip
            size="small"
            label={prefix}
            variant="outlined"
            color={prefix === "AND" ? "secondary" : "primary"}
            avatar={<Avatar>{prefix === "AND" ? "&" : "|"}</Avatar>}
          />
        ) : null}
      </span>
    );
  };
  renderNode = (node, level = 0) => {
    if (node.filterType === "join") {
      const conditions = node.conditions.map((condition, index, array) =>
        this.renderCondition(
          node,
          condition,
          index === array.length - 1,
          level + 1
        )
      );

      return (
        <Stack key={Math.random()} style={{ marginLeft: `${level * 20}px` }}>
          {conditions}
        </Stack>
      );
    } else {
      let colName = _.last(node.colId.split("%"));
      let opType = this.TYPE_MAPS[node.type];
      let val = node.filter || "";
      return (
        <Box>
          <Chip
            label={`${colName} ${opType} ${val}`}
            variant="outlined"
            size="small"
          />
        </Box>
      );
    }
  };
  renderServiceCriteriaSpec = (rowId, idx) => {
    let x = this.state.serviceMetadata[idx];
    if (x.serviceCriteriaType === "Always Required") {
      return null;
    } else if (x.serviceCriteriaType === "Conditional: Criteria") {
      return (
        <Stack>
          <Box>
            <Chip
              disabled={this.state.isSaving}
              label="Set Criteria"
              onClick={() => {
                this.setState({
                  currentlyEditingMasterServiceId: rowId,
                  openCriteriaDrawer: true,
                });
              }}
            />
          </Box>
          {this.renderCriteriaNicely(
            this.state.serviceMetadata[idx].serviceCriteria.filterSpec
          )}
        </Stack>
      );
    } else if (x.serviceCriteriaType === "Conditional: NL") {
      return (
        <Stack spacing={2}>
          <TextField
            multiline
            disabled={this.state.isSaving}
            label="Natural Language Criteria"
            value={x.serviceCriteria?.filterSpec}
            onBlur={() => {
              this.updateScalarValue(
                "serviceMetadata",
                this.state.serviceMetadata
              );
            }}
            onChange={(e) => {
              let serviceMetadata = this.state.serviceMetadata;
              serviceMetadata[idx].serviceCriteria.filterSpec = e.target.value;
              this.setState({
                serviceMetadata,
              });
            }}
          />
        </Stack>
      );
    } else if (x.serviceCriteriaType === "Optional") {
      return (
        <Stack spacing={2}>
          <TextField
            multiline
            disabled={this.state.isSaving}
            label="Natural Language Criteria"
            value={x.serviceCriteria?.filterSpec}
            onBlur={() => {
              this.updateScalarValue(
                "serviceMetadata",
                this.state.serviceMetadata
              );
            }}
            onChange={(e) => {
              let serviceMetadata = this.state.serviceMetadata;
              serviceMetadata[idx].serviceCriteria.filterSpec = e.target.value;
              this.setState({
                serviceMetadata,
              });
            }}
          />
        </Stack>
      );
    }
  };
  renderServiceCriteria = (_, idx) => {
    return (
      <Stack spacing={2}>
        <FormControlLabel
          control={
            <Select
              disabled={this.state.isSaving}
              sx={{
                minWidth: "250px",
              }}
              value={this.state.serviceMetadata[idx].serviceCriteriaType}
              onChange={(e) => {
                let sm = this.state.serviceMetadata[idx];
                if (sm.serviceCriteriaType === e.target.value) {
                  return;
                }
                sm.serviceCriteriaType = e.target.value;
                sm.serviceCriteria = { filterSpec: null };
                let serviceMetadata = this.state.serviceMetadata;
                serviceMetadata[idx] = sm;
                this.setState(
                  {
                    serviceMetadata: this.state.serviceMetadata,
                  },
                  () => {
                    this.updateScalarValue("serviceMetadata", serviceMetadata);
                  }
                );
              }}
            >
              {this.SERVICE_CRITERIA_OPTIONS.map((each) => {
                return <MenuItem value={each}>{each}</MenuItem>;
              })}
            </Select>
          }
          sx={{
            m: 0,
            width: 1,
            justifyContent: "space-between",
          }}
        />
        {this.renderServiceCriteriaSpec(_, idx)}
      </Stack>
    );
  };
  onAddFrequency = (_, idx) => {
    let serviceMetadata = this.state.serviceMetadata;
    let sm = serviceMetadata[idx];
    sm.serviceFrequency.push({
      frequencyCriteriaType: "",
      frequencyCriteria: { filterSpec: null },
      frequencyValue: null,
      frequencyUnit: null,
    });
    serviceMetadata[idx] = sm;
    this.setState(
      {
        serviceMetadata,
      },
      () => {
        this.updateScalarValue("serviceMetadata", serviceMetadata);
      }
    );
  };
  renderServiceFrequencyArray = (_, idx) => {
    let freqArr = this.state.serviceMetadata[idx].serviceFrequency;
    if (!freqArr || !freqArr.length) {
      return null;
    }
    return freqArr.map((each, freqIdx) => {
      return (
        <Stack
          key={`${_}_${idx}_${freqIdx}`}
          spacing={2}
          sx={{
            p: 2,
            boxShadow: 2,
            borderRadius: 2,
            bgcolor: "background.paper",
          }}
        >
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <FormControlLabel
              label="Criteria Type  "
              labelPlacement="start"
              control={
                <Select
                  disabled={this.state.isSaving}
                  sx={{
                    minWidth: "250px",
                  }}
                  value={each.frequencyCriteriaType}
                  onChange={(e) => {
                    let serviceMetadata = this.state.serviceMetadata;
                    serviceMetadata[idx].serviceFrequency[
                      freqIdx
                    ].frequencyCriteriaType = e.target.value;
                    this.setState(
                      {
                        serviceMetadata,
                      },
                      () => {
                        this.updateScalarValue(
                          "serviceMetadata",
                          serviceMetadata
                        );
                      }
                    );
                  }}
                >
                  {this.FREQUENCY_CRITERIA_OPTIONS.map((each) => {
                    return <MenuItem value={each}>{each}</MenuItem>;
                  })}
                </Select>
              }
              sx={{
                m: 0,
                width: 1,
                justifyContent: "space-between",
              }}
            />
            <Box>
              <Button
                disabled={this.state.isSaving}
                startIcon={<Iconify icon="mingcute:delete-2-line" />}
                variant="contained"
                color="error"
                onClick={(e) => {
                  e.preventDefault();
                  let serviceMetadata = this.state.serviceMetadata;
                  let current = serviceMetadata[idx];
                  let cf = current.serviceFrequency;

                  cf.splice(freqIdx, 1);

                  current.serviceFrequency = cf;
                  serviceMetadata[idx] = current;

                  this.setState(
                    {
                      serviceMetadata,
                    },
                    () => {
                      this.updateScalarValue(
                        "serviceMetadata",
                        serviceMetadata
                      );
                    }
                  );
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            {this.renderFrequencyArrayIndexCriteria(_, idx, freqIdx)}
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                onBlur={() => {
                  this.updateScalarValue(
                    "serviceMetadata",
                    this.state.serviceMetadata
                  );
                }}
                disabled={this.state.isSaving}
                label="Frequency Value (In Numbers)"
                value={each.frequencyValue}
                onChange={(e) => {
                  let serviceMetadata = this.state.serviceMetadata;
                  serviceMetadata[idx].serviceFrequency[
                    freqIdx
                  ].frequencyValue = parseInt(e.target.value);
                  this.setState({
                    serviceMetadata,
                  });
                }}
              />
              <FormControlLabel
                label="Frequency Unit"
                labelPlacement="start"
                control={
                  <Select
                    disabled={this.state.isSaving}
                    sx={{
                      minWidth: "250px",
                    }}
                    value={each.frequencyUnit}
                    onChange={(e) => {
                      let serviceMetadata = this.state.serviceMetadata;
                      serviceMetadata[idx].serviceFrequency[
                        freqIdx
                      ].frequencyUnit = e.target.value;
                      this.setState(
                        {
                          serviceMetadata,
                        },
                        () => {
                          this.updateScalarValue(
                            "serviceMetadata",
                            serviceMetadata
                          );
                        }
                      );
                    }}
                  >
                    {this.FREQUENCY_UNIT_OPTIONS.map((each) => {
                      return <MenuItem value={each}>{each}</MenuItem>;
                    })}
                  </Select>
                }
                sx={{
                  m: 0,
                  width: 1,
                  justifyContent: "space-between",
                }}
              />
            </Box>
          </Box>
        </Stack>
      );
    });
  };
  renderFrequencyArrayIndexCriteria = (_, idx, freqIdx) => {
    let freqArr = this.state.serviceMetadata[idx].serviceFrequency;
    if (!freqArr || !freqArr.length) {
      return null;
    }
    let current = freqArr[freqIdx];
    let type = current.frequencyCriteriaType;
    if (!type) {
      return null;
    }
    let freqC = current.frequencyCriteria;
    let spec = freqC.filterSpec;
    if (type === "Conditional: NL") {
      return (
        <TextField
          onBlur={() => {
            this.updateScalarValue(
              "serviceMetadata",
              this.state.serviceMetadata
            );
          }}
          disabled={this.state.isSaving}
          sx={{
            minWidth: "250px",
          }}
          label="Natural Language Condition"
          multiline
          value={spec}
          onChange={(e) => {
            let serviceMetadata = this.state.serviceMetadata;
            serviceMetadata[idx].serviceFrequency[
              freqIdx
            ].frequencyCriteria.filterSpec = e.target.value;
            this.setState({
              serviceMetadata,
            });
          }}
        />
      );
    } else if (type === "Conditional: Criteria") {
      return (
        <Stack>
          <Box>
            <Chip
              label="Set Criteria"
              disabled={this.state.isSaving}
              onClick={() => {
                this.setState({
                  currentlyInFrequency: true,
                  currentlyEditingMasterServiceId: _,
                  currentlyEditingFrequencyIndex: freqIdx,
                  openCriteriaDrawer: true,
                });
              }}
            />
          </Box>
          {this.renderCriteriaNicely(spec)}
        </Stack>
      );
    } else {
      return null;
    }
  };
  renderSvcFreqHeader = (_, idx) => {
    let freqArr = this.state.serviceMetadata[idx].serviceFrequency;
    let ll = "";
    if (!freqArr || !freqArr.length) {
      ll = "Same as protocol frequency";
    } else {
      ll = " Possible";
      let labelF = freqArr.length === 1 ? "Frequency" : "Frequencies";
      ll = `${freqArr.length} ${labelF} ${ll}`;
    }

    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Chip variant="contained" label={ll} />
          <IconButton
            disabled={this.state.isSaving}
            size="small"
            color="primary"
            sx={{
              width: 24,
              height: 24,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              this.onAddFrequency(_, idx);
            }}
          >
            <Iconify icon="mingcute:add-line" />
          </IconButton>{" "}
        </Stack>

        {this.renderServiceFrequencyArray(_, idx)}
      </Stack>
    );
  };
  renderServiceFrequency = (_, idx) => {
    return <Stack spacing={2}>{this.renderSvcFreqHeader(_, idx)}</Stack>;
  };
  addServiceToScope = (data) => {
    let masterServices = _.cloneDeep(this.state.masterServices);

    let newMS = [];
    let newSM = [];
    if (!data || !data.length) {
      this.setState(
        {
          masterServices: [],
          serviceMetadata: [],
        },
        () => {
          this.updateArrayValue();
        }
      );
      return;
    }

    for (var dataIdx = 0; dataIdx < data.length; dataIdx++) {
      let currentDataElement = data[dataIdx];
      let idxInState = _.findIndex(
        masterServices,
        (x) => x === currentDataElement._id
      );
      if (idxInState > -1) {
        newMS.push(masterServices[idxInState]);
        newSM.push(this.state.serviceMetadata[idxInState]);
      } else {
        newMS.push(currentDataElement._id);
        newSM.push({
          serviceName: currentDataElement.serviceName,
          serviceCode: "",
          serviceFrequency: [],
          serviceCriteriaType: "Always Required",
          serviceCriteria: { filterSpec: null },
          isCostIncluded: true,
          serviceCostModel: "Included In Protocol",
          serviceBaseCostUsd: null,
          serviceFlatCostPlusUsd: null,
          serviceFlatCostPlusPercentage: null,
          isVisibleToCompany: true,
          acceptValidUpto: 0,
          serviceDocumentTypes: currentDataElement.documentTypes,
        });
      }
    }

    this.setState(
      {
        masterServices: newMS,
        serviceMetadata: newSM,
      },
      () => {
        this.updateArrayValue();
      }
    );
  };

  render() {
    let fsToProp = null;
    if (this.state.openCriteriaDrawer) {
      if (this.state.currentlyInFrequency) {
        let c = this.state.currentlyEditingMasterServiceId;
        let idx = _.findIndex(this.state.masterServices, (x) => x === c);
        let sm = this.state.serviceMetadata[idx];
        let f = sm.serviceFrequency[this.state.currentlyEditingFrequencyIndex];
        fsToProp = f.frequencyCriteria.filterSpec;
      } else {
        let c = this.state.currentlyEditingMasterServiceId;
        let idx = _.findIndex(this.state.masterServices, (x) => x === c);
        let sm = this.state.serviceMetadata[idx];
        fsToProp = sm.serviceCriteria.filterSpec;
      }
    }

    if (this.state.isError) {
      return <View500 />;
    }

    return (
      <Stack spacing={3}>
        <Drawer
          anchor="right"
          open={this.state.openServicesDrawer}
          onClose={() => {
            this.setState({
              openServicesDrawer: false,
            });
          }}
        >
          <div
            style={{
              width: "80vw",
            }}
          >
            <Card sx={{ p: 2 }}>
              <ListServices
                linkText={"Add Services To Scope"}
                fromProviderPage={true}
                selectedData={
                  this.state.masterServices && this.state.masterServices.length
                    ? this.state.masterServices.map((each) => {
                        return {
                          _id: each,
                        };
                      })
                    : []
                }
                onSelectData={(data) => {
                  this.setState(
                    {
                      openServicesDrawer: false,
                    },
                    () => {
                      this.addServiceToScope(data);
                    }
                  );
                }}
              />
            </Card>
          </div>
        </Drawer>
        <Drawer
          anchor="right"
          open={
            this.state.openCriteriaDrawer &&
            this.state.currentlyEditingMasterServiceId
          }
          onClose={() => {
            this.setState({
              openCriteriaDrawer: false,
              currentlyEditingMasterServiceId: null,
              currentlyInFrequency: false,
              currentlyEditingFrequencyIndex: null,
            });
          }}
        >
          <div
            style={{
              width: "80vw",
            }}
          >
            <Card sx={{ p: 2 }}>
              <CompanyEmployees
                id={this.props.companyId}
                onSetFilterSpec={(fs) => {
                  cogoToast.loading("Updating Service Criteria");
                  if (this.state.currentlyInFrequency) {
                    let currentId = this.state.currentlyEditingMasterServiceId;
                    let idx = _.findIndex(
                      this.state.masterServices,
                      (x) => x === currentId
                    );
                    let sm = this.state.serviceMetadata[idx];
                    sm.serviceFrequency[
                      this.state.currentlyEditingFrequencyIndex
                    ].frequencyCriteria.filterSpec = fs;
                    let serviceMetadata = this.state.serviceMetadata;
                    serviceMetadata[idx] = sm;
                    this.setState(
                      {
                        serviceMetadata,
                        openCriteriaDrawer: false,
                        currentlyEditingMasterServiceId: null,
                        currentlyInFrequency: false,
                        currentlyEditingFrequencyIndex: null,
                      },
                      () => {
                        this.updateScalarValue(
                          "serviceMetadata",
                          serviceMetadata
                        );
                        cogoToast.success("Service Criteria Updated");
                      }
                    );
                  } else {
                    let currentId = this.state.currentlyEditingMasterServiceId;
                    let idx = _.findIndex(
                      this.state.masterServices,
                      (x) => x === currentId
                    );
                    let sm = this.state.serviceMetadata[idx];
                    sm.serviceCriteria.filterSpec = fs;
                    let serviceMetadata = this.state.serviceMetadata;
                    serviceMetadata[idx] = sm;
                    this.setState(
                      {
                        serviceMetadata,
                        openCriteriaDrawer: false,
                        currentlyEditingMasterServiceId: null,
                      },
                      () => {
                        this.updateScalarValue(
                          "serviceMetadata",
                          serviceMetadata
                        );
                        cogoToast.success("Service Criteria Updated");
                      }
                    );
                  }
                }}
                insideProtocol={true}
                filterSpec={fsToProp}
                protocol={this.state}
              />
            </Card>
          </div>
        </Drawer>
        {this.renderAlert()}
        {this.renderHeader()}
        <Stack spacing={3}>{this.renderServices()}</Stack>
      </Stack>
    );
  }
}

export default WithAPICall(ProtocolServices);

"use client";

import { useState, useEffect } from "react";
import { Tooltip, TooltipProvider } from "@/components/ui/tooltip";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import {
  FileIcon,
  UserIcon,
  MessageSquareIcon,
  ClipboardCheckIcon,
  DatabaseIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AlertTriangle,
  AlertCircle,
} from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

import { useProtectedApi } from "@/hooks/use-apiCall";
import useVisitStore from "@/store/useVisitStore";

import EmployeeInfoCard from "./EmployeeInfoCard";
import { CommentThreadWithDrawer } from "./CommentThread";
import { ClearanceFileSection } from "./ClearanceFileSection";
import { ProtocolClearanceMother } from "./ProtocolClearanceMother";
import { GenericRenderer } from "../../DataFiles/DataRenderers/GenericRenderer";

import _, { set } from "lodash";
import { RedFlagSummary } from "./RedFlagSummary";
import { ParentDataRenderer } from "../../DataFiles/DataRenderers/ParentDataRenderer";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const __summary = `

Headline:
45 year old patient, male, with a history of hypertension and diabetes, currently on medication. ECG normal, FVC reduced, ESR elevated.

Red Flags:
- ESR: 45 mm/hr
- FVC: 2.5 L

`;

function CollapsibleCard({ title, icon, children }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
      <Card className="shadow-md">
        <CardHeader className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold flex items-center">
              {icon}
              {title}
            </h2>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm">
                <TooltipProvider>
                  <Tooltip content={isOpen ? "Collapse" : "Expand"}>
                    {isOpen ? (
                      <ChevronUpIcon className="h-4 w-4" />
                    ) : (
                      <ChevronDownIcon className="h-4 w-4" />
                    )}
                  </Tooltip>
                </TooltipProvider>
              </Button>
            </CollapsibleTrigger>
          </div>
        </CardHeader>
        <CollapsibleContent>
          <CardContent className="p-4 pt-0">{children}</CardContent>
        </CollapsibleContent>
      </Card>
    </Collapsible>
  );
}

function VisitStatusSelect({ visit, onStatusChange }) {
  const [visitStatus, setVisitStatus] = useState(visit?.visitStatus || "");

  useEffect(() => {
    setVisitStatus(visit.visitStatus);
  }, [visit.visitStatus]);

  return (
    <CollapsibleCard
      title="Visit Outcome"
      icon={<UserIcon className="w-5 h-5 mr-2 text-purple-500" />}
    >
      <TooltipProvider>
        <Tooltip content="Set Visit Outcome">
          <Select
            onValueChange={(v) => {
              setVisitStatus(v);
              onStatusChange(v);
            }}
            value={visitStatus}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select visit outcome" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="REQUESTED">Requested</SelectItem>
              <SelectItem value="OUTREACH_FOR_SCHEDULING">
                Outreach for Scheduling
              </SelectItem>
              <SelectItem value="SCHEDULED">Scheduled</SelectItem>
              <SelectItem value="PENDING_DOCUMENTS_FROM_CLINIC">
                Pending Documents from Clinic
              </SelectItem>
              <SelectItem value="PARTIAL_DOCUMENTS_RECEIVED">
                Partial Documents Received
              </SelectItem>
              <SelectItem value="REVIEW_NOT_STARTED">
                Review Not Started
              </SelectItem>
              <SelectItem value="IN_RN_REVIEW">Awaiting RN Review</SelectItem>
              <SelectItem value="IN_MD_REVIEW">Awaiting MD Review</SelectItem>
              <SelectItem value="PENDING_INFORMATION_FROM_EMPLOYEE">
                Awaiting Information from Employee
              </SelectItem>
              <SelectItem value="PENDING_AFFIDAVIT">
                Awaiting Affidavit
              </SelectItem>
              <SelectItem value="RETURN_TO_CLINIC">Return to Clinic</SelectItem>
              <SelectItem value="COMPLETED">Completed</SelectItem>
              <SelectItem value="CANCELLED">Cancelled</SelectItem>
              <SelectItem value="NO_SHOW">No Show</SelectItem>
            </SelectContent>
          </Select>
        </Tooltip>
      </TooltipProvider>
    </CollapsibleCard>
  );
}

export default function ClearanceAreaLayout({ _id, onStatusChange }) {
  const { callApi } = useProtectedApi();
  const { visit, loadVisit } = useVisitStore();
  const [medicalRecords, setMedicalRecords] = useState([]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleVisitStatusChange = async (value) => {
    try {
      const result = await callApi("/visit/updateVisitStatus", {
        visitId: _id,
        status: value,
      });
      onStatusChange(value);
      loadVisit(result);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await callApi("/visit/getVisitById", {
        visitId: _id,
      });
      const medicalRecords = await callApi("/visit/getMedicalRecords", {
        visitId: _id,
      });
      loadVisit(result);
      setMedicalRecords(medicalRecords);
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex h-screen bg-gray-100 p-4 gap-4">
      <div className="flex flex-col flex-grow gap-4">
        <CollapsibleCard
          title="Data Area"
          icon={<DatabaseIcon className="w-5 h-5 mr-2 text-blue-500" />}
        >
          <TooltipProvider>
            <Tooltip content="View and analyze patient data">
              {visit && <RedFlagSummary text={visit.dataSummary} />}
              <div className="bg-gray-200 h-[40vh] rounded-md p-2 text-sm">
                <ScrollArea className="h-full">
                  {medicalRecords.length > 0 && (
                    <ParentDataRenderer
                      listOfData={medicalRecords}
                      onUpdate={null}
                      employeeData={visit.employee}
                    />
                  )}
                  {medicalRecords.length === 0 && (
                    <Alert>
                      <AlertCircle className="w-5 h-5" />
                      <AlertTitle>No Data</AlertTitle>
                      <AlertDescription>
                        No data linked to this visit.
                      </AlertDescription>
                    </Alert>
                  )}
                </ScrollArea>
              </div>
            </Tooltip>
          </TooltipProvider>
        </CollapsibleCard>
        {visit &&
          visit.protocolsInfo.map((protocol, index) => {
            return (
              <CollapsibleCard
                key={index}
                title={`Clearance Area - ${protocol.companyProtocolName}`}
                icon={
                  <ClipboardCheckIcon className="w-5 h-5 mr-2 text-green-500" />
                }
              >
                <TooltipProvider>
                  <Tooltip content="Manage clearance decisions">
                    <ProtocolClearanceMother
                      protocolId={protocol._id}
                      medicalRecords={medicalRecords}
                    />
                  </Tooltip>
                </TooltipProvider>
              </CollapsibleCard>
            );
          })}
        {!visit && (
          <CollapsibleCard
            key={0}
            title={`Clearance Area`}
            icon={
              <ClipboardCheckIcon className="w-5 h-5 mr-2 text-green-500" />
            }
          >
            <TooltipProvider>
              <Tooltip content="Manage clearance decisions">
                <div className="bg-gray-200 h-64 rounded-md p-2 text-sm">
                  Loading Data...
                </div>
              </Tooltip>
            </TooltipProvider>
          </CollapsibleCard>
        )}
      </div>
      <div className="w-1/3 flex flex-col gap-4">
        <CollapsibleCard
          title="Employee Info"
          icon={<UserIcon className="w-5 h-5 mr-2 text-purple-500" />}
        >
          <TooltipProvider>
            <Tooltip content="View employee information">
              <EmployeeInfoCard />
            </Tooltip>
          </TooltipProvider>
        </CollapsibleCard>
        {visit && (
          <VisitStatusSelect
            visit={visit}
            onStatusChange={handleVisitStatusChange}
          />
        )}
        {/* {visit && (
          <CollapsibleCard
            title="Visit Outcome"
            icon={<UserIcon className="w-5 h-5 mr-2 text-purple-500" />}
          >
            <TooltipProvider>
              <Tooltip content="Set Visit Outcome">
                <Select onValueChange={setVisitStatus} value={visitStatus}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select visit outcome" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="IN_RN_REVIEW">
                      Awaiting RN Review
                    </SelectItem>
                    <SelectItem value="IN_MD_REVIEW">
                      Awaiting MD Review
                    </SelectItem>
                    <SelectItem value="COMPLETED">Complete</SelectItem>
                    <SelectItem value="CANCELLED">Cancelled</SelectItem>
                    <SelectItem value="NO_SHOW">No Show</SelectItem>
                  </SelectContent>
                </Select>
              </Tooltip>
            </TooltipProvider>
          </CollapsibleCard>
        )} */}

        <CollapsibleCard
          title="Comment Threads"
          icon={<MessageSquareIcon className="w-5 h-5 mr-2 text-yellow-500" />}
        >
          <TooltipProvider>
            <Tooltip content="View and add comments">
              <ScrollArea className="h-64">
                {visit && (
                  <CommentThreadWithDrawer
                    handleVisitStatusChange={handleVisitStatusChange}
                  />
                )}
                {!visit && (
                  <Alert>
                    <AlertTriangle className="w-5 h-5" />
                    <AlertDescription>Loading data...</AlertDescription>
                  </Alert>
                )}
              </ScrollArea>
            </Tooltip>
          </TooltipProvider>
        </CollapsibleCard>
        <CollapsibleCard
          title="Files Uploaded"
          icon={<FileIcon className="w-5 h-5 mr-2 text-red-500" />}
        >
          <TooltipProvider>
            <Tooltip content="View and manage uploaded files">
              <ClearanceFileSection />
            </Tooltip>
          </TooltipProvider>
        </CollapsibleCard>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import {
  AlertCircle,
  CheckCircle2,
  Clock,
  Loader2,
  UserCheck,
} from "lucide-react";
import {
  Card as MuiCard,
  Input,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  Drawer,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepIcon,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { ScrollArea } from "@/components/ui/scroll-area";
import Iconify from "src/components/iconify";
import { niceDateTimeUS, niceDateUS } from "../../micro/utils/fn";
import { useProtectedApi } from "@/hooks/use-apiCall";
import { DPS1UploadPDF } from "./document-processing-stage-1-upload-pdf";
import { DPS2SplitByEE } from "./document-processing-stage-2-split-by-ee";
import { DPS3DataTagger } from "./document-processing-stage-3-data-tagging";
import { DPS4LinkVisit } from "./document-processing-stage-4-link-visit";
import useDocumentStore from "../../store/useDocumentStore";
import { GenericRenderer } from "@/micro/DataFiles/DataRenderers/GenericRenderer";
import _ from "lodash";
import { Stack } from "@mui/system";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import cogoToast from "cogo-toast";
import Grid from "@mui/system/Unstable_Grid/Grid";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { DocLinking } from "./DocLinking";

function DataFileProcessingStage({
  stage,
  stageId,
  started,
  ended,
  startedAt,
  endedAt,
  verifiedBy,
  verifiedByName,
  verifiedAt,
  errorOccurred,
  errorMessage,
  _id,
  document,
  onVerify,
  allowEdit,
  updateExtractedEEInfo,
  updateDocumentToDone,
}) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const getStatus = () => {
    if (verifiedBy) return "user-verified";
    if (errorOccurred) return "failed";
    if (ended) return "done";
    if (started) return "in-progress";
    return "due";
  };
  const status = getStatus();
  const getStatusColor = (status) => {
    switch (status) {
      case "failed":
        return "bg-red-50 text-red-700 border-red-200";
      case "in-progress":
        return "bg-blue-50 text-blue-700 border-blue-200";
      case "done":
        return "bg-green-50 text-green-700 border-green-200";
      case "due":
        return "bg-yellow-50 text-yellow-700 border-yellow-200";
      case "user-verified":
        return "bg-purple-50 text-purple-700 border-purple-200";
    }
  };
  const getStatusHexBackground = (status) => {
    switch (status) {
      case "failed":
        return "#f87171";
      case "in-progress":
        return "#3b82f6";
      case "done":
        return "#22c55e";
      case "due":
        return "#facc15";
      case "user-verified":
        return "#84cc16";
    }
  };
  const getStatusIcon = (status) => {
    switch (status) {
      case "failed":
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      case "in-progress":
        return <Loader2 className="h-4 w-4 text-blue-500 animate-spin" />;
      case "done":
        return <CheckCircle2 className="h-4 w-4 text-green-500" />;
      case "due":
        return <Clock className="h-4 w-4 text-yellow-500" />;
      case "user-verified":
        return <UserCheck className="h-4 w-4 text-purple-500" />;
    }
  };
  const getStatusText = (status) => {
    switch (status) {
      case "failed":
        return "Failed";
      case "in-progress":
        return "In Progress";
      case "done":
        return "Done";
      case "due":
        return "Due";
      case "user-verified":
        return "Verified";
    }
  };
  const formatDate = (date) => {
    return date ? niceDateTimeUS(new Date(date)) : "N/A";
  };
  const getInnerContent = () => {
    if (errorOccurred) {
      return (
        <Alert severity="error">We ran into an error:{errorMessage}</Alert>
      );
    }
    if (stageId === "PROCESSING") {
      if (ended) return <DPS1UploadPDF status="success" />;
      return <DPS1UploadPDF status="inProgress" />;
    }
    if (stageId === "PARSING_DATA_FILE") {
      if (!started) {
        return <Alert severity="warning">Processing not yet started</Alert>;
      }
      if (started && !ended) {
        let timeTaken = ((new Date() - new Date(startedAt)) / 1000).toFixed(2);
        return (
          <Alert severity="warning">
            We are currently processing this file. It may take a while. We
            started doing this at {niceDateTimeUS(startedAt)}. It has been{" "}
            {timeTaken} seconds so far.
          </Alert>
        );
      }
      if (ended) {
        let timeTaken = 0;
        if (started && ended) {
          timeTaken = (
            (new Date(endedAt) - new Date(startedAt)) /
            1000
          ).toFixed(2);
        }
        return (
          <Alert severity="success">
            This file was processed by AI at {niceDateTimeUS(endedAt)}. AI has
            now ingested the file and extracted the raw text from it. It is now
            ready to be moved to the next stage of the data file processing
            pipeline. It took {timeTaken} seconds to process.
          </Alert>
        );
      }
    }
    if (stageId === "EXTRACTING_EE_NAMES") {
      return <DPS2SplitByEE />;
    }
    if (stageId === "EXTRACTING_DOCUMENT_DATA_TYPES") {
      return <DPS3DataTagger />;
    }
    if (stageId === "EXTRACTING_DOCUMENT_MEDICAL_DATA") {
      // return <div />;
      const employees = document.extractedEEInfo || [];
      if (!employees || employees.length === 0) {
        return <Alert severity="warning">No employees found</Alert>;
      } else {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Stack direction="column" spacing={1}>
                {employees.map((eeInfo, eeIndex) => {
                  let medicalRecords = eeInfo.employeeMedicalData;
                  if (!_.isArray(medicalRecords)) {
                    return (
                      <Alert severity="warning">
                        No medical records found for {eeInfo.employeeFirstName}{" "}
                        {eeInfo.employeeLastName}
                      </Alert>
                    );
                  }
                  if (!medicalRecords || medicalRecords.length === 0) {
                    return (
                      <Alert severity="warning">
                        No medical records found for {eeInfo.employeeFirstName}{" "}
                        {eeInfo.employeeLastName}
                      </Alert>
                    );
                  }
                  let dataTypes = eeInfo.employeeMedicalData.map(
                    (mr) => mr.dataType
                  );
                  if (!dataTypes || dataTypes.length === 0) {
                    return (
                      <Alert severity="warning">
                        No data types found for {eeInfo.employeeFirstName}{" "}
                        {eeInfo.employeeLastName}
                      </Alert>
                    );
                  }
                  return (
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <Iconify icon="fluent-mdl2:double-chevron-down" />
                          }
                        >
                          {eeInfo.employeeFirstName} {eeInfo.employeeLastName}
                        </AccordionSummary>
                        <AccordionDetails>
                          <MuiCard>
                            <Stack
                              direction="column"
                              spacing={2}
                              sx={{
                                mb: 2,
                              }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                flexWrap="wrap"
                              >
                                <Typography variant="overline">
                                  Currently Viewing:
                                </Typography>
                                <Chip
                                  label={`${eeInfo.employeeFirstName} ${eeInfo.employeeLastName}`}
                                />{" "}
                              </Stack>
                            </Stack>
                            <hr
                              style={{
                                borderColor: "#00B8D9",
                                borderWidth: "1px",
                              }}
                            />
                          </MuiCard>
                          <MuiCard
                            sx={{
                              border: "1px solid #00B8D9",
                              borderRadius: "5px",
                              padding: "10px",
                              // minHeight: "230px",
                              maxHeight: "45vh",
                              // height: "100%",
                              //     backgroundColor: "red",
                            }}
                          >
                            <GenericRenderer
                              listOfData={eeInfo.employeeMedicalData}
                              allowEdit={allowEdit}
                              onUpdate={(newEmployeeMedicalData) => {
                                updateExtractedEEInfo(
                                  eeIndex,
                                  newEmployeeMedicalData
                                );
                              }}
                            />
                          </MuiCard>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Iconify icon="mdi:check-circle" />}
                  onClick={() => {
                    onVerify("EXTRACTING_DOCUMENT_MEDICAL_DATA");
                  }}
                  disabled={!!verifiedAt}
                  sx={{ mt: 2, mb: 2 }}
                >
                  {verifiedAt
                    ? `Verified at ${formatDate(verifiedAt)} by ${verifiedByName}`
                    : "I have verified all results"}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ height: "45vh" }}>
                <Viewer
                  fileUrl={document.psUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Box>
            </Grid>
          </Grid>
        );
      }
    }

    if (stageId === "LINKING_VISIT") {
      return (
        <DocLinking
          employees={document.extractedEEInfo || []}
          document={document}
          updateDocumentToDone={updateDocumentToDone}
        />
      );
      // const employees = document.extractedEEInfo || [];
      // return employees.map((eeInfo, index) => (
      //   <DPS4LinkVisit eeIndex={index} />
      // ));
    }
    if (stageId === "DONE") {
      return null;
    }
    return (
      <p className="text-xs text-gray-600">
        {errorOccurred
          ? errorMessage
          : started
            ? `Processing started at ${formatDate(startedAt)}`
            : "Processing not yet started"}
      </p>
    );
  };

  return (
    <MuiCard sx={{ p: 1, m: 1, boxShadow: 1 }}>{getInnerContent()}</MuiCard>
  );
  /*   return (
    <Card className="w-full mb-3 overflow-hidden border shadow-sm hover:shadow-md transition-shadow duration-200">
      <CardHeader
        className={`flex flex-row items-center justify-between py-2 px-3 ${getStatusColor(status)}`}
      >
        <div className="flex items-center space-x-2">
          <div className="bg-white p-1 rounded-full shadow-sm">
            {getStatusIcon(status)}
          </div>
          <h3 className="text-xs font-semibold">{stage}</h3>
        </div>
        <Badge
          variant="secondary"
          className="text-[10px] font-medium px-2 py-0.5"
        >
          {getStatusText(status)}
        </Badge>
      </CardHeader>
      <CardContent className="py-2 px-3">{getInnerContent()}</CardContent>
      <CardFooter className="py-2 px-3 bg-gray-50 flex justify-between items-center">
        <div className="text-[10px] text-gray-500">
          {verifiedBy
            ? `Verified ${formatDate(verifiedAt)} by ${verifiedBy}`
            : ended
              ? `Completed ${formatDate(endedAt)}`
              : ""}
        </div>
        {ended && !errorOccurred && !verifiedBy && (
          <Button
            onClick={onVerify}
            size="sm"
            variant="outline"
            className="text-xs h-6 px-2 py-0 text-green-600 border-green-300 hover:bg-green-50"
          >
            Verify Output
          </Button>
        )}
      </CardFooter>
    </Card>
  ); */
}

export function DataFileProcessingTimeline({
  _id,
  refresh,
  onLegacyTransfer,
  onRestartAIPipeline,
}) {
  const { callApi } = useProtectedApi();
  const { document, loadDocument } = useDocumentStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingToOHM, setIsSendingToOHM] = useState(false);
  const [isSendingToOnCourse, setIsSendingToOnCourse] = useState(false);
  const [isSendingToBacklog, setIsSendingToBacklog] = useState(false);
  const [isDeletingDialogOpen, setIsDeletingDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isAIDialogOpen, setIsAIDialogOpen] = useState(false);
  const [restartStageName, setRestartStageName] = useState("");
  const [isAIPipelineRestarting, setIsAIPipelineRestarting] = useState(false);
  const fetchData = async (setLoading = true) => {
    if (setLoading) {
      setIsLoading(true);
    }
    setError(null);
    try {
      const result = await callApi("/datafiles/getById", {
        dataFileId: _id,
      });
      let processStatus = result.processStatus;
      let processStatusTracker = result.processStatusTracker;
      let processIdx = processStatusTracker.findIndex(
        (stage) => stage.stageId === processStatus
      );
      if (setLoading) {
        setActiveStep(processIdx);
      }
      loadDocument(result);
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    if (refresh) {
      const intervalId = setInterval(() => {
        fetchData(false);
      }, 10000);
      return () => clearInterval(intervalId);
    } else {
      return () => {};
    }
  }, []);

  const updateExtractedEEInfo = async (eeIndex, newEmployeeMedicalData) => {
    try {
      cogoToast.loading("Updating data...");
      const newDoc = await callApi("/datafiles/updateScalars", {
        dataFileId: document._id,
        uops: [
          {
            key: `extractedEEInfo.${eeIndex}.employeeMedicalData`,
            value: newEmployeeMedicalData,
          },
        ],
      });
      loadDocument(newDoc);
      cogoToast.success("Data updated");
    } catch (err) {
      cogoToast.error("Failed to update data");
      console.log(err);
    }
  };

  const updateDocumentToDone = async () => {
    try {
      const resp = await callApi("/datafiles/updateScalars", {
        dataFileId: document._id,
        uops: [
          {
            key: "processStatus",
            value: "DONE",
          },
        ],
      });
      loadDocument(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerify = async (stageId) => {
    try {
      const resp = await callApi("/datafiles/verifyStage", {
        dataFileId: document._id,
        stageId: stageId,
      });
      loadDocument(resp);
    } catch (err) {
      console.log(err);
    }
  };
  const transferToLegacy = async (param) => {
    try {
      cogoToast.loading("Transferring file to legacy system...");
      if (param === "OHM") {
        setIsSendingToOHM(true);
      } else if (param === "OnCourse") {
        setIsSendingToOnCourse(true);
      } else if (param === "BACKLOG") {
        setIsSendingToBacklog(true);
      }
      await callApi("/datafiles/transferToLegacy", {
        dataFileId: document._id,
        recipient: param,
      });
      setIsSendingToOHM(false);
      setIsSendingToOnCourse(false);
      setIsSendingToBacklog(false);
      onLegacyTransfer();
      cogoToast.success("File transferred to legacy system");
    } catch (err) {
      setIsSendingToOHM(false);
      setIsSendingToOnCourse(false);
      setIsSendingToBacklog(false);
      console.log(err);
      cogoToast.error("Failed to transfer file to legacy system");
    }
  };
  const deleteById = async () => {
    try {
      setIsDeleting(true);
      cogoToast.loading("Deleting file...");
      await callApi("/datafiles/deleteById", {
        dataFileId: document._id,
      });
      cogoToast.success("File deleted");
      onLegacyTransfer();
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to delete file");
    } finally {
      setIsDeleting(false);
    }
  };
  const handleRestartAIPipeline = async (param) => {
    let stageName = param;
    let stageId = document.processStatusTracker.find(
      (stage) => stage.stage === stageName
    )?.stageId;
    if (!stageId) {
      cogoToast.error("Invalid stage name");
      return;
    }
    try {
      cogoToast.loading("Restarting AI pipeline...");
      setIsAIPipelineRestarting(true);
      let res = await callApi("/datafiles/rerunPipelineFromStage", {
        dataFileId: document._id,
        stageId: stageId,
      });
      loadDocument(res);
      setIsAIDialogOpen(false);
      setIsAIPipelineRestarting(false);
      onRestartAIPipeline();
      cogoToast.success("AI pipeline restarted");
    } catch (err) {
      setIsAIPipelineRestarting(false);
      console.log(err);
      cogoToast.error("Failed to restart AI pipeline");
    }
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  let fileDispositionControlPanel = null;
  let sendToOHMButton = (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => transferToLegacy("OHM")}
      startIcon={<Iconify icon="mingcute:send-plane-line" />}
      disabled={isSendingToOHM || isSendingToOnCourse}
      loading={isSendingToOHM}
    >
      Send File To OHM
    </Button>
  );
  let sendToOnCourseButton = (
    <Button
      variant="contained"
      color="info"
      onClick={() => transferToLegacy("ONCOURSE")}
      startIcon={<Iconify icon="mingcute:send-plane-line" />}
      disabled={isSendingToOnCourse || isSendingToOHM || isDeleting}
      loading={isSendingToOnCourse}
    >
      Send File To OnCourse
    </Button>
  );
  let sendToBacklogButton = (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => transferToLegacy("BACKLOG")}
      startIcon={<Iconify icon="mingcute:send-plane-line" />}
      disabled={isSendingToOHM || isSendingToOnCourse || isSendingToBacklog}
      loading={isSendingToBacklog}
    >
      Send File To Backlog
    </Button>
  );
  let deleteFileButton = (
    <Button
      variant="contained"
      color="error"
      disabled={isSendingToOnCourse || isSendingToOHM || isDeleting}
      loading={isDeleting}
      onClick={() => {
        setIsDeletingDialogOpen(true);
      }}
      startIcon={<Iconify icon="fluent:delete-12-regular" />}
    >
      Delete File
    </Button>
  );
  let openFileButton = (
    <Button
      startIcon={<Iconify icon="icon-park-outline:share" />}
      variant="contained"
      color="primary"
      onClick={() => {
        window.open(document.psUrl, "_blank");
      }}
    >
      Open File In New Tab
    </Button>
  );
  let restartAIPipelineButton = (
    <Button
      variant="contained"
      color="info"
      startIcon={<Iconify icon="game-icons:processor" />}
      onClick={() => {
        setIsAIDialogOpen(true);
      }}
    >
      Restart AI Pipeline
    </Button>
  );
  let processStatusTracker = document?.processStatusTracker || [];
  let statusSteppers = processStatusTracker.map((stage, index) => {
    return {
      stage: stage.stage,
      disposition: stage.verifiedBy
        ? "verified"
        : stage.errorOccurred
          ? "failed"
          : stage.ended
            ? "done"
            : stage.started
              ? "in-progress"
              : "due",
      verifiedByName: stage.verifiedBy ? stage.verifiedByName : "",
      startedAt: stage.startedAt || "",
      endedAt: stage.endedAt || "",
      verifiedAt: stage.verifiedAt || "",
      errorMessage: stage.errorMessage || "",
    };
  });
  let listOfStagesToRestartFrom = statusSteppers.map((each) => each.stage);
  let dialogBoxForRestartAIPipeline = (
    <Dialog
      open={isAIDialogOpen}
      onClose={() => {
        setIsAIDialogOpen(false);
      }}
    >
      <DialogTitle>Restart AI Pipeline</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please choose the stage you want to restart from.
        </DialogContentText>
        <Select
          fullWidth
          value={restartStageName}
          onChange={(e) => {
            setRestartStageName(e.target.value);
          }}
        >
          {listOfStagesToRestartFrom.map((each, index) => {
            return <MenuItem value={each}>{each}</MenuItem>;
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isAIPipelineRestarting}
          variant="contained"
          color="error"
          onClick={() => {
            setIsAIDialogOpen(false);
          }}
        >
          Cancel
        </Button>{" "}
        <Button
          variant="contained"
          color="primary"
          disabled={!restartStageName || isAIPipelineRestarting}
          onClick={() => {
            handleRestartAIPipeline(restartStageName);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
  let dialogBoxForDeleteFile = (
    <Dialog open={isDeletingDialogOpen} onClose={() => {}}>
      <DialogTitle>Delete File</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this file?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {" "}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsDeletingDialogOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setIsDeletingDialogOpen(false);
            deleteById();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
  fileDispositionControlPanel = (
    <Card>
      <Stack direction="row" spacing={2}>
        {openFileButton}
        {restartAIPipelineButton}
        {sendToOHMButton}
        {sendToOnCourseButton}
        {sendToBacklogButton}
        {deleteFileButton}
      </Stack>
    </Card>
  );

  const getStepIcon = (disposition) => {
    if (disposition === "verified") {
      return <Iconify icon="iconoir:verified-user" color="green" />;
    }
    if (disposition === "failed") {
      return <Iconify icon="ic:twotone-error" color="red" />;
    }
    if (disposition === "done") {
      return <Iconify icon="pajamas:partner-verified" color="green" />;
    }
    if (disposition === "in-progress") {
      return <Iconify icon="grommet-icons:in-progress" color="blue" />;
    }
    if (disposition === "due") {
      return <Iconify icon="material-symbols:circle-outline" color="grey" />;
    }
  };
  const renderStepLabel = (step, index) => {
    let disposition = step.disposition;
    let verifiedByName = step.verifiedByName;
    let startedAt = step.startedAt;
    let endedAt = step.endedAt;
    let verifiedAt = step.verifiedAt;
    let errorMessage = step.errorMessage;
    let stage = step.stage;
    let isActive = index === activeStep;
    let activeElem = null;
    if (isActive) {
      activeElem = (
        <Stack>
          <Chip label="Currently Viewing" size="small" variant="outlined" />
        </Stack>
      );
    }
    if (disposition === "verified") {
      return (
        <StepLabel StepIconComponent={() => getStepIcon(disposition)}>
          <Stack direction="column" spacing={0.5}>
            <Typography variant="overline">{stage}</Typography>
            <Chip label="Verified" color="success" size="small" />{" "}
            <Typography variant="caption">
              Verified by {verifiedByName} at {niceDateTimeUS(verifiedAt)}
            </Typography>
            {activeElem}
          </Stack>
        </StepLabel>
      );
    }
    if (disposition === "failed") {
      return (
        <StepLabel StepIconComponent={() => getStepIcon(disposition)}>
          <Stack direction="column" spacing={0.5}>
            <Typography variant="overline">{stage}</Typography>
            <Chip label="Error" color="error" size="small" />{" "}
            <Typography variant="caption">{errorMessage}</Typography>
            {activeElem}
          </Stack>
        </StepLabel>
      );
    }
    if (disposition === "done") {
      return (
        <StepLabel StepIconComponent={() => getStepIcon(disposition)}>
          <Stack direction="column" spacing={0.5}>
            <Typography variant="overline">{stage}</Typography>
            <Chip
              label="AI Processing Complete"
              color="success"
              size="small"
            />{" "}
            <Typography variant="caption">
              Completed at {niceDateTimeUS(endedAt)}
            </Typography>
            {activeElem}
          </Stack>
        </StepLabel>
      );
    }
    if (disposition === "in-progress") {
      return (
        <StepLabel StepIconComponent={() => getStepIcon(disposition)}>
          <Stack direction="column" spacing={0.5}>
            <Typography variant="overline">{stage}</Typography>
            <Chip label="In Progress" color="info" size="small" />{" "}
            <Typography variant="caption">
              Started at {niceDateTimeUS(startedAt)}
            </Typography>
            {activeElem}
          </Stack>
        </StepLabel>
      );
    }
    if (disposition === "due") {
      return (
        <StepLabel StepIconComponent={() => getStepIcon(disposition)}>
          <Stack direction="column" spacing={0.5}>
            <Typography variant="overline">{stage}</Typography>
            <Chip label="Due" color="warning" size="small" />{" "}
            <Typography variant="caption">Has not started yet</Typography>
            {activeElem}
          </Stack>
        </StepLabel>
      );
    }
  };
  const stepperComponent = (
    <Stepper
      nonLinear
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      alternativeLabel
      connector={
        <StepConnector
          sx={{
            borderColor: "#00B8D9",
            borderWidth: "1px",
          }}
        />
      }
    >
      {statusSteppers.map((step, index) => {
        let isActive = index === activeStep;
        let sx = {};
        return (
          <Stack direction="column" spacing={0.5} sx={sx}>
            <Step
              key={index}
              onClick={() => {
                setActiveStep(index);
              }}
            >
              {renderStepLabel(step, index)}
            </Step>
          </Stack>
        );
      })}
    </Stepper>
  );
  let processStatusTrackerStep = processStatusTracker[activeStep];
  let currentlyActiveStageComponent = (
    <DataFileProcessingStage
      {...processStatusTrackerStep}
      _id={_id}
      document={document}
      onVerify={() => handleVerify(processStatusTrackerStep.stageId)}
      updateExtractedEEInfo={updateExtractedEEInfo}
      updateDocumentToDone={updateDocumentToDone}
    />
  );
  return (
    <div className="w-full mx-auto p-4 space-y-4 bg-white rounded-lg shadow-lg">
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Document Processing Pipeline</Typography>
        {fileDispositionControlPanel}
        {dialogBoxForRestartAIPipeline}
        {dialogBoxForDeleteFile}
      </Stack>
      <Typography variant="caption">
        You can click on any of the stages to view outputs.
      </Typography>
      <hr
        style={{
          borderColor: "#00B8D9",
          borderWidth: "2px",
        }}
      />
      {stepperComponent}
      {/*  <MuiCard sx={{ p: 2, m: 1, boxShadow: 1 }}>
        <Typography variant="h5">{processStatusTrackerStep.stage}</Typography>
      </MuiCard> */}
      <div
        style={{
          height: "90vh",
          overflowY: "auto",
        }}
      >
        {currentlyActiveStageComponent}
      </div>
    </div>
  );
}

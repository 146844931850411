import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/iconify";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WithAPICall } from "../utils/apiUtil";
import { Box, Stack } from "@mui/system";
import { View500 } from "src/sections/error";

import cogoToast from "cogo-toast";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { TextField as MuiTextField } from "@mui/material";
import _ from "lodash";
import { QuickScore } from "quick-score";

class LabPricingTable extends React.Component {
  state = {
    isSearching: false,
    originalList: [],
    list: [],
    page: 0,
    rowsPerPage: 10,
    filterText: "",
    qs: null,
    orderBy: "serviceName",
    order: "asc",
  };
  componentDidMount() {
    this.search();
  }
  search = async () => {
    this.setState({ isSearching: true });
    cogoToast.loading("Loading data, this might take a while!");
    this.setState({
      list: [],
      originalList: [],
      isSearching: true,
    });

    let res = await this.props.apiCallPost(
      "/master-service/masterServiceGetAllLabServices",
      {}
    );

    // Sort the res array alphabetically based on serviceName
    res = _.sortBy(res, "serviceName");
    let qs = new QuickScore(res, [
      "serviceName",
      "crlPanelCode",
      "questPanelCode",
      "pactoxPanelCode",
      "labCorpPanelCode",
      "njPanelCode",
    ]);

    this.setState({
      list: qs.search("").map((each) => each.item),
      qs: qs,
      isSearching: false,
    });
  };

  handleFilterChange = (event) => {
    let res = this.state.qs.search(event.target.value);
    let it = res.map((each) => each.item);
    this.setState({
      list: it,
    });
    this.setState({ filterText: event.target.value, page: 0 });
  };

  renderHeader = () => {
    return (
      <CustomBreadcrumbs
        heading="Lab Pricing Table"
        links={[
          { name: "Dashboard", href: "/" },
          { name: "Lab Pricing Table" },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
    );
  };

  renderFilterField = () => {
    return (
      <MuiTextField
        fullWidth
        label="Filter Lab Services"
        variant="outlined"
        value={this.state.filterText}
        onChange={this.handleFilterChange}
        sx={{ mb: 2 }}
      />
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };
  handleValueChange = (id, value, name) => {
    const newRows = [...this.state.list];

    newRows.forEach((row) => {
      if (row._id === id) {
        row[name] = value;
      }
    });

    this.setState({ list: newRows });
  };
  updateValue = async (value, id, name) => {
    console.log("updateValue", value, id, name);
    let res = await this.props.apiCallPost(
      "/master-service/masterServiceUpdateScalarValue",
      {
        masterServiceId: id,
        key: name,
        value: value,
      }
    );
  };

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  descendingComparator = (a, b, orderBy) => {
    if (orderBy === "serviceName") {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    const costFields = {
      crl: "crlBaseCostUSD",
      quest: "questBaseCostUSD",
      labCorp: "labCorpBaseCostUSD",
      pactox: "pactoxBaseCostUSD",
      nationalJewish: "njBaseCostUSD",
    };
    const costField = costFields[orderBy];
    if (costField) {
      return parseFloat(b[costField] || 0) - parseFloat(a[costField] || 0);
    }
    return 0;
  };

  renderTable = () => {
    const columns = [
      { id: "labService", label: "Lab Service", minWidth: 170, maxWidth: 170 },
      { id: "crl", label: "CRL", minWidth: 100 },
      {
        id: "quest",
        label: "Quest",
        minWidth: 100,
      },
      {
        id: "labCorp",
        label: "Lab Corp",
        minWidth: 100,
      },
      {
        id: "pactox",
        label: "Pactox",
        minWidth: 100,
      },

      {
        id: "nationalJewish",
        label: "National Jewish",
        minWidth: 100,
      },
    ];

    const sortedList = [...this.state.list].sort(
      this.getSorting(this.state.order, this.state.orderBy)
    );

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {this.renderFilterField()}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={this.state.orderBy === column.id}
                      direction={
                        this.state.orderBy === column.id
                          ? this.state.order
                          : "asc"
                      }
                      onClick={() => this.handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedList
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row, page_index) => {
                  const index =
                    page_index + this.state.page * this.state.rowsPerPage;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        style={{
                          maxWidth: 150,
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {row?.serviceName}
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.crlPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "crlPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "crlPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.crlBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "crlBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "crlBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.questPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "questPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "questPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.questBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "questBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "questBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.pactoxPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "pactoxPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "pactoxPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.pactoxBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "pactoxBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "pactoxBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.labCorpPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "labCorpPanelCode"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "labCorpPanelCode"
                            );
                          }}
                          fullWidth
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.labCorpBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "labCorpBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "labCorpBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Panel Code"
                          value={row?.njPanelCode}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "njPanelCode"
                            )
                          }
                          fullWidth
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "njPanelCode"
                            );
                          }}
                        />
                        <TextField
                          placeholder="Panel Cost"
                          value={row?.njBaseCostUSD || ""}
                          onChange={(e) =>
                            this.handleValueChange(
                              row._id,
                              e.target.value,
                              "njBaseCostUSD"
                            )
                          }
                          onBlur={(e) => {
                            this.updateValue(
                              e.target.value,
                              row._id,
                              "njBaseCostUSD"
                            );
                          }}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedList.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  };

  render() {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <div>
        <Stack spacing={3}>
          {this.renderHeader()}
          {this.renderTable()}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(LabPricingTable);

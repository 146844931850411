import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Accordion,
  Autocomplete,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Popover,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Badge,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { NoResultView, View500 } from "src/sections/error";
import cogoToast from "cogo-toast";
import moment from "moment";
import { DatePicker, DateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LoadingButton } from "@mui/lab";
import EachTask from "./EachTask";
import Iconify from "src/components/iconify";
import _, { uniqBy } from "lodash";
import { niceDate, niceDateTime } from "../utils/fn";
import { format } from "date-fns";
import TASK_STATUS from "./data/task-status";
import TokenContext from "src/TokenContext";

class TaskListByProviderGroupUpdated extends React.Component {
  static contextType = TokenContext;
  state = {
    isLoading: true,
    showSearchControls: true,
    // cu
    currentUser: {
      isEmployee: true,
      isExternal: true,
    },
    //employee
    isSearchingEmployee: false,
    employeeSearchResults: [],
    currentlyInEmployee: null,
    // company
    isSearchingCompany: false,
    companySearchResults: [],
    currentlyInCompany: null,
    // provider
    isSearchingProvider: false,
    providerSearchResults: [],
    currentlyInProvider: null,
    //
    taskDueDateIn: [moment(), moment().add(30, "days")],
    taskToUpdateDueDate: moment(),
    taskFinishedIn: [null, null],
    taskStatus: [
      "RECEIVED",
      "AWAITING_DOCUMENTS_PROCESSING",
      "DOCUMENT_PROCESSED",
      "SERVICE_NOT_DONE",
      "POSSIBLE_NO_SHOW",
      "MEDICAL_HOLD",
      "PENDING",
      "REJECTED",
      "NOT_AVAILABLE_YET",
      "B_READ_IMAGE_RECEIVED",
    ],
    taskInterpreter: ["VisitDocumentChase"],
    prefix: "",
    //
    isError: false,
    taskList: [],
    //
    anchorEl: null,
    isPopoverOpen: false,
    popOverId: null,
    //
    isSelectingProviderForAssignment: false,
    selectedProvidersForTaskAssign: [],
    isSearchingUser: false,
    userSearchResults: [],
    currentlyChosenUser: null,
    isEditingAssignee: false,
    groupedTaskList: {},
    isOpenTaskStatusUpdate: false,
    taskToUpdateId: null,
    taskToUpdateStatus: null,
    taskToUpdateNotes: null,

    tasksFilteredForMe: null,
  };
  INTERPRETER_LIST = {
    CompanySetUp: "Set Up Employer Data",
    CAPSetUp: "Set Up Employer Provider Links",
    CompanyProtocolSetUp: "Set Up Employer Protocols",
    VisitSchedule: "Schedule Visit",
    VisitDocumentChase: "Documents Chaser",
    VisitClose: "Close Visit",
    ServiceSummarization: "Summarize Service",
    ProtocolSummarization: "Summarize Protocol",
    ServiceClearance: "Clear Service",
    ProtocolClearance: "Clear Protocol",
    ProcessDocument: "Process Document",
    AssociateWithVisit: "Link Document With Visit",
    VisitCreate: "Create Visit",
    DrawPool: "Create Pool Draw",
  };
  STATUS_LIST = {
    PENDING: "Pending",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
    IN_PROGRESS: "In Progress",
  };
  componentDidMount() {
    this.getCurrentUser();
  }
  getCurrentUser = async () => {
    try {
      const res = await this.props.apiCallPost("/user/getCurrentUser", {});
      let cu = res;
      let isExternal = cu.isExternal;
      this.setState({
        currentUser: cu,
      });
      if (isExternal) {
        this.setState(
          {
            currentUser: cu,
            currentlyInCompany: {
              mainText: res.company.companyName,
              secondaryText: `${res.company.companyInformalName} ${res.companyWebsite}`,
              data: res.company,
            },
            taskDueDateIn: [
              moment().subtract(3, "days"),
              moment().add(7, "days"),
            ],

            isLoading: false,
          },
          () => {
            this.searchTasks();
          }
        );
      } else {
        this.setState(
          {
            isLoading: false,
            taskDueDateIn: [
              moment().subtract(3, "days"),
              moment().add(7, "days"),
            ],
          },
          () => {
            this.searchTasks();
          }
        );
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading User Data");
      this.setState({
        isLoading: false,
        isError: true,
      });
    }
  };
  updateTask = async (taskId, uops) => {
    this.setState({ isDoingAction: true });
    cogoToast.loading("Updating Task");
    try {
      let res = await this.props.apiCallPost("/task/updateTask", {
        taskId,
        uops: uops,
      });
      const index = this.state.taskList.findIndex(
        (each) => each._id === taskId
      );
      let taskList = this.state.taskList;
      taskList[index] = res;
      this.setState({
        taskList: taskList,
        isDoingAction: false,
        isEditingAssignee: false,
        currentlyChosenUser: null,
        userSearchResults: [],
      });
    } catch (err) {
      cogoToast.error("Error Updating Task");
      console.log(err);
    }
  };
  addVisitProviderOutreachAttempt = async (visitId, medium, contact, notes) => {
    this.setState({ isDoingAction: true });
    cogoToast.loading("Updating Visit");
    try {
      let res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEventOutreach",
        {
          visitId,
          providerId: this.state.outreachAttemptProviderId,
          medium,
          contact,
          notes,
        }
      );
      return res;
    } catch (err) {
      cogoToast.error("Error Updating Visit");
      console.log(err);
    }
    this.setState({
      isDoingAction: false,
    });
  };
  searchTasks = async () => {
    let payload = {
      prefix: this.state.prefix || "",
      company: this.state.currentlyInCompany?.data?._id || null,
      provider: this.state.currentlyInProvider?.data?._id || null,
      employee: this.state.currentlyInEmployee?.data?._id || null,
      taskStatus: this.state.taskStatus,
      taskInterpreter: this.state.taskInterpreter,
      taskTags: [],
      taskAssigneeId:
        this.state.tasksFilteredForMe ||
        this.state.currentlyChosenUser?.data?._id ||
        null,
    };
    if (this.state.taskDueDateIn.filter(Boolean).length === 2) {
      payload.taskDueDateIn = this.state.taskDueDateIn.map((each) =>
        each.toDate()
      );
    } else {
      payload.taskDueDateIn = [];
    }
    if (this.state.taskFinishedIn.filter(Boolean).length === 2) {
      payload.taskFinishedIn = this.state.taskFinishedIn.map((each) =>
        each.toDate()
      );
    } else {
      payload.taskFinishedIn = [];
    }
    try {
      this.setState({ isSearching: true });
      let res = await this.props.apiCallPost("/task/searchTasks", payload);
      res = res.filter((x) => x.employee != null);
      if (!res || !res.length) {
        this.setState({
          isLoading: false,
          taskList: [],
          isSearching: false,
        });
        return;
      }
      let resSorted = res.sort((a, b) => {
        const statusOrder = [
          "PENDING",
          "IN_PROGRESS",
          "COMPLETED",
          "CANCELLED",
        ];
        const statusA = statusOrder.indexOf(a.taskStatus);
        const statusB = statusOrder.indexOf(b.taskStatus);

        if (statusA !== statusB) {
          return statusA - statusB;
        }

        if (a.taskDueDate < b.taskDueDate) {
          return -1;
        }
        if (a.taskDueDate > b.taskDueDate) {
          return 1;
        }
        return 0;
      });
      // group by provider._id and then visit._id
      let groupedList = {};
      resSorted.forEach((each) => {
        if (!each.provider) return;
        if (!groupedList[each.provider._id]) {
          groupedList[each.provider._id] = {};
        }
        if (!groupedList[each.provider._id][each.visit?._id]) {
          groupedList[each.provider._id][each.visit?._id] = [];
        }
        groupedList[each.provider._id][each.visit?._id].push(each);
      });
      this.setState({
        isDoingAction: false,
        isLoading: false,
        taskList: resSorted,
        isSearching: false,
        groupedTaskList: groupedList,
      });
    } catch (err) {
      this.setState({ isError: true });
      console.error(err);
      cogoToast.error("Error fetching tasks");
    }
  };
  pushToEventLog = async ({ visitId, header, notes }) => {
    this.setState({ isSaving: true });
    let payload = {
      visitId,
      header,
      notes,
      creator: "User",
    };
    try {
      await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.setState({ isSaving: false });
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Scheduling");
      console.log(err);
    }
    this.setState({ isSaving: false });
  };
  searchCompaniesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/company/search", {
        query: t,
      });
      if (res) {
        this.setState({
          isSearchingCompany: false,
          companySearchResults: res.map((each) => {
            return {
              mainText: each.companyName,
              secondaryText: `${each.companyInformalName} ${each.companyWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingCompany: false,
        companySearchResults: [],
      });
      cogoToast.error("Error Searching Companies");
    }
  };
  searchProvidersFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/provider/providerSearch",
        {
          query: t,
        }
      );
      if (res) {
        this.setState({
          isSearchingProvider: false,
          providerSearchResults: res.map((each) => {
            return {
              mainText: each.providerName,
              secondaryText: `${each.providerInformalName} ${each.providerWebsite}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingProvider: false,
        providerSearchResults: [],
      });
      cogoToast.error("Error Searching Providers");
    }
  };
  searchUsersFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable("/user/searchUser", {
        query: t,
        company: null,
        showDeactivatedAlso: false,
      });
      if (res) {
        this.setState({
          isSearchingUser: false,
          userSearchResults: res.map((each) => {
            return {
              mainText: each.name,
              secondaryText: each.email,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingUser: false,
        userSearchResults: [],
      });
      cogoToast.error("Error Searching Users");
    }
  };
  searchEmployeesFTS = async (t) => {
    try {
      let res = await this.props.apiCallPostCancellable(
        "/employee/data/searchAugmentedFts",
        {
          companyId: this.state.currentlyInCompany.data._id,
          query: t,
        }
      );
      if (res) {
        this.setState({
          isSearchingEmployee: false,
          employeeSearchResults: res.map((each) => {
            return {
              mainText: each.employeeName,
              secondaryText: `${each.employeeNumber ? each.employeeNumber : "No Employee Number"} | ${each.employeeOfficeEmail || "No Employee Email"}`,
              data: each,
            };
          }),
        });
      }
    } catch (err) {
      this.setState({
        isSearchingEmployee: false,
        employeeSearchResults: [],
      });
      cogoToast.error("Error Searching Employees");
    }
  };
  renderEmployeeSearcher = () => {
    if (
      !this.state.currentlyInCompany ||
      !this.state.currentlyInCompany.data ||
      !this.state.currentlyInCompany.data._id
    ) {
      return null;
    }

    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          option ? (typeof option === "string" ? option : option?.mainText) : ""
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingEmployee ? [] : this.state.employeeSearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInEmployee}
        noOptionsText={
          this.state.isSearchingEmployee ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              currentlyInEmployee: null,
              employeeSearchResults: [],
              isSearchingEmployee: false,
            });
          }
          this.setState({
            isSearchingEmployee: false,
            currentlyInEmployee: value,
            employeeSearchResults: [value, ...this.state.employeeSearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          this.setState(
            {
              isSearchingEmployee: true,
            },
            () => {
              this.searchEmployeesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employees" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText || ""}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderTaskStatusUpdateDialog = () => {
    const task = this.state.taskList.find(
      (t) => t._id === this.state.taskToUpdateId
    );
    if (!task) return null;
    let taskToUpdateStatus = this.state.taskToUpdateStatus;
    let dueDateWidget = null;
    let selectWidget = null;
    if (["MEDICAL_HOLD", "NOT_AVAILABLE_YET"].includes(taskToUpdateStatus)) {
      let delta = 45 * 24 * 60 * 60 * 1000;
      if (taskToUpdateStatus === "NOT_AVAILABLE_YET") {
        delta = 3 * 24 * 60 * 60 * 1000;
      }
      dueDateWidget = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disablePast={true}
            minDate={moment(new Date())}
            maxDate={moment(new Date(new Date().getTime() + delta))}
            size="small"
            value={this.state.taskToUpdateDueDate || dueDate}
            onChange={(newValue) => {
              this.setState({ taskToUpdateDueDate: newValue });
            }}
            label="Check Back On"
          />
        </LocalizationProvider>
      );
    }
    if (["SERVICE_NOT_DONE"].includes(taskToUpdateStatus)) {
      let taskSubNoteStatusOptions = [
        "Provider missed the service/component",
        "Specimen lost in transit",
        "Specimen compromised",
        "Employee declined service/component",
        "Employee left prior to service/component completion",
        "Service/component not scheduled",
        "Provider did not have supplies",
        "Provider had equipment failure",
        "Provider did not have appropriate staff",
      ];
      selectWidget = (
        <Stack>
          <Typography variant="caption">Reason</Typography>
          <Select
            sx={{
              minWidth: "200px",
            }}
            value={this.state.taskSubNoteStatus}
            onChange={(e) => {
              this.setState({ taskSubNoteStatus: e.target.value });
            }}
          >
            {taskSubNoteStatusOptions.map((each, index) => {
              return (
                <MenuItem key={index} value={each}>
                  {each}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      );
    }
    let dueDate = task.taskDueDate ? new Date(task.taskDueDate) : null;
    return (
      <Dialog
        open={this.state.isOpenTaskStatusUpdate}
        onClose={() => {
          this.setState({
            isOpenTaskStatusUpdate: false,
            taskToUpdateId: null,
            taskToUpdateStatus: null,
            taskToUpdateNotes: null,
            taskToUpdateDueDate: moment(),
            taskSubNoteStatus: null,
          });
        }}
      >
        <DialogTitle>Update Task Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Setting Status To:{" "}
            <b> {_.startCase(_.lowerCase(this.state.taskToUpdateStatus))} </b>
          </DialogContentText>
          <Stack spacing={1}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Notes"
              fullWidth
              value={this.state.taskToUpdateNotes}
              onChange={(e) => {
                this.setState({ taskToUpdateNotes: e.target.value });
              }}
            />

            {dueDateWidget}
            {selectWidget}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              this.setState({
                isOpenTaskStatusUpdate: false,
                taskToUpdateId: null,
                taskToUpdateStatus: null,
                taskToUpdateNotes: null,

                taskToUpdateDueDate: moment(),
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isDoingAction}
            onClick={async () => {
              let notes = this.state.taskToUpdateNotes || "";
              if (this.state.taskSubNoteStatus) {
                notes += ` - ${this.state.taskSubNoteStatus}`;
              }
              await this.updateTask(this.state.taskToUpdateId, [
                {
                  key: "taskStatus",
                  value: this.state.taskToUpdateStatus,
                },
                {
                  key: "taskStatusNote",
                  value: notes,
                },
              ]);

              let docType = task.linkedDocumentType;
              let serviceName = task.masterService.serviceName;
              const visit = task.visit;

              await this.pushToEventLog({
                visitId: visit._id,
                header: `${serviceName} - ${docType} - Status: ${_.startCase(_.lowerCase(this.state.taskToUpdateStatus))}`,
                notes: notes,
              });

              this.setState({
                isOpenTaskStatusUpdate: false,
                taskToUpdateId: null,
                taskToUpdateStatus: null,
                taskToUpdateNotes: null,
                taskToUpdateDueDate: moment(),
                taskSubNoteStatus: null,
              });
              cogoToast.success("Task Status Updated");
            }}
            size="small"
            variant="contained"
            color="primary"
          >
            Update Task Status
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };
  renderOutreachAttemptDialog = () => {
    return (
      <Dialog
        open={this.state.isOpenOutreachAttempt}
        onClose={() => {
          this.setState({ isOpenOutreachAttempt: false });
        }}
      >
        <DialogTitle>Log Outreach Attempt</DialogTitle>
        <DialogContent>
          <DialogContentText>Mode of Outreach</DialogContentText>
          <Select
            sx={{
              minWidth: "200px",
            }}
            value={this.state.outreachAttemptMedium}
            onChange={(e) => {
              this.setState({ outreachAttemptMedium: e.target.value });
            }}
          >
            <MenuItem value="Phone">Phone</MenuItem>
            <MenuItem value="Email">Email</MenuItem>
            <MenuItem value="In Person">In Person</MenuItem>
            <MenuItem value="Fax">Fax</MenuItem>
            <MenuItem value="Mail">Mail</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          <DialogContentText>Who did you speak to?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Contact At Provider"
            fullWidth
            value={this.state.outreachAttemptTarget}
            onChange={(e) => {
              this.setState({ outreachAttemptTarget: e.target.value });
            }}
          />
          <DialogContentText>Notes</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Notes"
            fullWidth
            value={this.state.outreachAttemptNotes}
            onChange={(e) => {
              this.setState({ outreachAttemptNotes: e.target.value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              this.setState({
                isOpenOutreachAttempt: false,
                outreachAttemptMedium: null,
                outreachAttemptTarget: null,
                outreachAttemptNotes: null,
                outreachAttemptProviderId: null,
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={this.state.isDoingAction}
            onClick={async () => {
              let tl_events_elem = await this.addVisitProviderOutreachAttempt(
                this.state.outreachAttemptVisitId,
                this.state.outreachAttemptMedium,
                this.state.outreachAttemptTarget,
                this.state.outreachAttemptNotes,
                this.state.outreachAttemptProviderId
              );

              let taskList = this.state.taskList;
              let visitTasksForThisProvider = _.filter(
                taskList,
                (each) =>
                  each.provider._id === this.state.outreachAttemptProviderId
              );

              let visitTasksForThisVisit = _.filter(
                visitTasksForThisProvider,
                (each) => each.visit._id === this.state.outreachAttemptVisitId
              );

              let mappedUpdated = visitTasksForThisVisit.map((each) => {
                return {
                  ...each,
                  visit: {
                    ...each.visit,
                    tl_events: [...each.visit.tl_events, tl_events_elem],
                  },
                };
              });
              let updateIndices = mappedUpdated.map((ii) => ii._id);
              let taskListUpdated = taskList.map((each) => {
                let eachTask = each;
                if (updateIndices.includes(each._id)) {
                  eachTask = mappedUpdated.find((ii) => ii._id === each._id);
                }
                return eachTask;
              });

              this.setState({
                taskList: taskListUpdated,
                isOpenOutreachAttempt: false,
                outreachAttemptMedium: null,
                outreachAttemptTarget: null,
                outreachAttemptNotes: null,
                outreachAttemptProviderId: null,
                isDoingAction: false,
              });
              cogoToast.success("Outreach Attempt Logged");
            }}
            size="small"
            variant="contained"
            color="primary"
          >
            Log Provider Outreach Attempt
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };
  renderUserSearcher = () => {
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          option ? (typeof option === "string" ? option : option?.mainText) : ""
        }
        filterOptions={(x) => x}
        options={this.state.isSearchingUser ? [] : this.state.userSearchResults}
        autoComplete
        includeInputInList
        filterSelectedOptions
        disabled={this.state.isDoingAction}
        value={this.state.currentlyChosenUser}
        noOptionsText={
          this.state.isSearchingUser ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              currentlyChosenUser: null,
              userSearchResults: [],
              isSearchingUser: false,
            });
          }
          this.setState({
            currentlyChosenUser: value,
            userSearchResults: [value, ...this.state.userSearchResults],
            isSearchingUser: false,
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;
          if (e.length < 4) return null;
          this.setState(
            {
              isSearchingUser: true,
            },
            () => {
              this.searchUsersFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Users" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderTask = (index) => {
    let task = this.state.taskList[index];
    return (
      <EachTask
        currentUser={this.state.currentUser}
        task={task}
        INTERPRETER_LIST={this.INTERPRETER_LIST}
        STATUS_LIST={this.STATUS_LIST}
        onUpdate={(data) => {
          let taskList = this.state.taskList;
          taskList[index] = data;
          this.setState({
            taskList: taskList,
          });
        }}
      />
    );
  };
  renderTaskList = () => {
    if (this.state.isSearching) {
      return <LinearProgress />;
    }
    let length = this.state.taskList.length;
    if (!length) {
      return <NoResultView />;
    }

    let list = this.state.taskList;
    let groupedList = {};
    list.forEach((each) => {
      if (!each.provider) return;
      if (!groupedList[each.provider._id]) {
        groupedList[each.provider._id] = {};
      }
      if (!groupedList[each.provider._id][each.visit?._id]) {
        groupedList[each.provider._id][each.visit?._id] = [];
      }
      groupedList[each.provider._id][each.visit?._id].push(each);
    });

    let providerKeys = Object.keys(groupedList);

    return (
      <Stack spacing={2}>
        {providerKeys.map((providerId, index) => {
          let visitKeys = Object.keys(groupedList[providerId]);
          let provider = list.find(
            (each) => each.provider?._id === providerId
          ).provider;
          let providerContacts = list.find(
            (each) => each.provider?._id === providerId
          ).providerContacts;
          let providerNotes = list.find(
            (each) => each.provider?._id === providerId
          ).providerNotes;
          let providerPhoneElem = null;
          if (provider.providerPhone) {
            providerPhoneElem = (
              <Button
                href={`tel:${provider.providerPhone}`}
                startIcon={<Iconify icon="ic:outline-phone" />}
              >
                Phone: {provider.providerPhone}
              </Button>
            );
          }
          let providerAddress =
            provider.providerAddress +
            ", " +
            provider.providerCity +
            ", " +
            provider.providerState +
            " " +
            provider.providerPostalCode +
            ", " +
            provider.providerCountry;
          let providerAddressClean = providerAddress
            .split(",")
            .map((x) => _.trim(x))
            .filter(Boolean)
            .join(", ");

          let providerEmailElem = null;
          // if (provider.providerEmail) {
          //   providerEmailElem = (
          //     <Button
          //       size="small"
          //       href={`/emails/?providerId=${provider._id}&sendNewEmail=true`}
          //       startIcon={<Iconify icon="ic:baseline-email" />}
          //       target="_blank"
          //       variant="outlined"
          //     >
          //       {provider.providerEmail}
          //     </Button>
          //   );
          // }
          let providerLink = `/providers/${provider._id}`;

          // calculate the oldest visit scheduledTime
          let oldestVisitDate = null;
          let totalDiffInDays = 0;
          let totalDates = 0;

          visitKeys.forEach((visitId) => {
            let visit = groupedList[providerId][visitId][0];
            if (!visit.visit) return;
            let visitDate = visit.visit.providerEncounterOnTime;
            if (!oldestVisitDate) {
              oldestVisitDate = visitDate;
            }
            if (visitDate < oldestVisitDate) {
              oldestVisitDate = visitDate;
            }
            totalDiffInDays += moment().diff(visitDate, "days");
            totalDates++;
            // for (const psl of visit.visit.clinicPSLs) {
            //   if (!psl.scheduledTime) return;
            //   let visitDate = new Date(psl.scheduledTime);
            //   if (!visitDate) return;
            //   if (!oldestVisitDate) {
            //     oldestVisitDate = visitDate;
            //   }
            //   if (visitDate < oldestVisitDate) {
            //     oldestVisitDate = visitDate;
            //   }
            //   totalDiffInDays += moment().diff(visitDate, "days");
            //   totalDates++;
            // }
          });

          let averageVisitDays =
            totalDates > 0 ? (totalDiffInDays / totalDates).toFixed(1) : null;

          let averageDaysText = "";
          if (averageVisitDays) {
            if (averageVisitDays < 0) {
              averageDaysText = `In ${Math.abs(averageVisitDays)} days`;
            } else {
              averageDaysText = `${averageVisitDays} days`;
            }
          }

          let differenceInDaysText = "";
          // calculate difference from today and handle negatives
          let differenceInDays = moment().diff(oldestVisitDate, "days");
          if (differenceInDays < 0) {
            differenceInDaysText = `In ${Math.abs(differenceInDays)} days`;
          } else {
            differenceInDaysText = `${differenceInDays} days ago`;
          }

          let numberOfUrgentProtocols = 0;
          const protocols = [];
          Object.keys(groupedList[providerId]).forEach((visitId) => {
            groupedList[providerId][visitId].forEach((task) => {
              if (task.protocol && task.protocol.isUrgent) {
                protocols.push(task.protocol);
              }
            });
          });
          const uniqueProtocols = uniqBy(protocols, "_id");
          numberOfUrgentProtocols = uniqueProtocols.length;

          // find the taskAssigneeId for this provider
          let taskAssigneeId = null;
          let taskAssignee = null;
          visitKeys.forEach((visitId) => {
            groupedList[providerId][visitId].forEach((task) => {
              if (task.taskAssigneeId) {
                taskAssigneeId = task.taskAssigneeId;
                taskAssignee = task.taskAssignee;
              }
            });
          });

          return (
            <Accordion
              key={index}
              sx={{
                borderBottom: "3px solid #f7f7f7",
              }}
            >
              <AccordionSummary>
                <Grid container spacing={2} width="100%" alignItems="center">
                  <Grid item xs={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {this.state.isSelectingProviderForAssignment && (
                        <Checkbox
                          checked={this.state.selectedProvidersForTaskAssign.includes(
                            providerId
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                selectedProvidersForTaskAssign: [
                                  ...this.state.selectedProvidersForTaskAssign,
                                  providerId,
                                ],
                              });
                            } else {
                              this.setState({
                                selectedProvidersForTaskAssign:
                                  this.state.selectedProvidersForTaskAssign.filter(
                                    (each) => each !== providerId
                                  ),
                              });
                            }
                          }}
                        />
                      )}
                      <Typography variant="overline">
                        {provider.providerName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      href={`tel:${provider.providerPhone}`}
                      startIcon={<Iconify icon="ic:baseline-phone" />}
                      variant="outlined"
                    >
                      {provider.providerPhone}
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Iconify icon="mdi:location" />
                      <Typography variant="caption">
                        <b>
                          {[
                            provider.providerCity,
                            provider.providerState,
                            provider.providerPostalCode,
                          ]
                            .filter((p) => p)
                            .join(", ")}
                        </b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="end"
                    >
                      {taskAssignee ? (
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          icon={<Iconify icon="mdi:user-tick" />}
                          label={taskAssignee}
                        />
                      ) : (
                        <Chip
                          color="error"
                          variant="contained"
                          size="small"
                          icon={<Iconify icon="mdi:user-question-outline" />}
                          label="Unassigned"
                        />
                      )}
                      <Chip
                        size="small"
                        variant="outlined"
                        label={`${visitKeys.length} visit${visitKeys.length === 1 ? "" : "s"}`}
                      />
                      {numberOfUrgentProtocols > 0 ? (
                        <Chip
                          icon={<Iconify icon="ri:alert-fill" />}
                          color={
                            numberOfUrgentProtocols > 0 ? "error" : "default"
                          }
                          size="small"
                          variant="outlined"
                          label={`Urgent: ${numberOfUrgentProtocols}`}
                        />
                      ) : null}
                      {differenceInDays ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={`Oldest: ${differenceInDaysText}`}
                        />
                      ) : null}
                      {averageVisitDays ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={`Avg Delay: ${averageDaysText}`}
                        />
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  width="100%"
                  sx={{
                    padding: "1rem",
                    background: "#f7f7f7",
                    borderRadius: "1rem",
                  }}
                >
                  <Grid item xs={2}>
                    <Stack
                      direction="column"
                      spacing={1}
                      justifyContent={"center"}
                    >
                      <Typography variant="overline" textAlign={"center"}>
                        {providerAddressClean}
                      </Typography>
                      {providerPhoneElem}
                      {providerContacts?.length > 0 && (
                        <Stack spacing={1}>
                          <Typography variant="subtitle2">
                            Provider Contacts
                          </Typography>
                          {providerContacts?.map((contact, index) => {
                            return (
                              <Stack spacing={0.5} key={index}>
                                <Typography variant="caption">
                                  {contact.contactName}
                                </Typography>
                                {contact.cellPhone && (
                                  <Button
                                    size="small"
                                    href={`tel:${contact.cellPhone} `}
                                    startIcon={
                                      <Iconify icon="ic:baseline-phone" />
                                    }
                                    variant="outlined"
                                  >
                                    {contact.cellPhone}
                                  </Button>
                                )}
                              </Stack>
                            );
                          })}
                        </Stack>
                      )}
                      {providerNotes.length > 0 &&
                        providerNotes.map((note, index) => (
                          <Stack spacing={1} key={index}>
                            <Typography variant="subtitle2">
                              Provider Notes
                            </Typography>
                            <Typography variant="caption">{note}</Typography>
                          </Stack>
                        ))}
                      {providerEmailElem}
                      <Button
                        href={providerLink}
                        target="_blank"
                        startIcon={<Iconify icon="grommet-icons:share" />}
                        variant="outlined"
                        fullWidth
                      >
                        View Provider
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={10}>
                    {visitKeys.map((visitId, index) => {
                      let visitsForThisProvider =
                        groupedList[providerId][visitId];

                      let visit = visitsForThisProvider[0];
                      let company = visit.company;
                      let employee = visit.employee;

                      // calculate the oldest psl scheduledTime
                      let earliestPslDate = null;
                      if (!visit.visit) return;
                      earliestPslDate = visit.visit.providerEncounterOnTime;
                      // for (const psl of visit.visit.clinicPSLs) {
                      //   if (!psl.scheduledTime) return;
                      //   let visitDate = new Date(psl.scheduledTime);
                      //   if (!visitDate) return;
                      //   if (!earliestPslDate) {
                      //     earliestPslDate = visitDate;
                      //   }
                      //   if (visitDate < earliestPslDate) {
                      //     earliestPslDate = visitDate;
                      //   }
                      // }

                      let protocols = [];
                      visitsForThisProvider.forEach((task) => {
                        if (task.protocol) {
                          protocols.push(task.protocol);
                        }
                      });
                      let visitIdElem = (
                        <IconButton
                          sx={{
                            width: 24,
                            height: 24,
                            bgcolor: "primary.error",
                            color: "primary.contrastText",
                            "&:hover": {
                              bgcolor: "primary.dark",
                            },
                          }}
                          href={`/visits/${visitId}`}
                          target="_blank"
                        >
                          <Iconify icon="icon-park-outline:share" />
                        </IconButton>
                      );
                      const uniqProtocols = uniqBy(protocols, "_id");
                      let protocolNamesElem = null;
                      if (uniqueProtocols.length > 0) {
                        protocolNamesElem = (
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignContent={"center"}
                          >
                            {uniqProtocols.map((p, index) => {
                              return (
                                <Chip
                                  key={index}
                                  size="small"
                                  variant={
                                    p.isUrgent ? "contained" : "outlined"
                                  }
                                  color={p.isUrgent ? "error" : "primary"}
                                  label={p.companyProtocolName}
                                />
                              );
                            })}
                          </Stack>
                        );
                      }
                      let lastUpdatedAt = null;
                      visitsForThisProvider.forEach((task) => {
                        // sort taskStatusLog in task to get the
                        // latest taskStatusLog.statusAt
                        (task.taskStatusLog || []).sort((a, b) => {
                          return new Date(b.statusAt) - new Date(a.statusAt);
                        });
                        if (task.taskStatusLog?.length > 0) {
                          if (!lastUpdatedAt) {
                            lastUpdatedAt = task.taskStatusLog[0].statusAt;
                          }
                          if (
                            new Date(task.taskStatusLog[0].statusAt) >
                            new Date(lastUpdatedAt)
                          ) {
                            lastUpdatedAt = task.taskStatusLog[0].statusAt;
                          }
                        }
                      });
                      let lastUpdatedAtText = lastUpdatedAt
                        ? niceDate(lastUpdatedAt)
                        : "NA";
                      // COMPLETED
                      //
                      let numCompleted = visitsForThisProvider.filter(
                        (task) => task.taskStatus === "COMPLETED"
                      ).length;
                      let numTasks = visitsForThisProvider.length;
                      let allCompleted = numCompleted === numTasks;
                      let noneCompleted = numCompleted === 0;
                      let partial = numCompleted > 0 && numCompleted < numTasks;
                      let statusChip = null;
                      if (allCompleted) {
                        statusChip = (
                          <Chip
                            size="small"
                            variant="outlined"
                            icon={<Iconify icon="lets-icons:check-fill" />}
                            color="success"
                            label={`${numCompleted} / ${numTasks} Done`}
                          />
                        );
                      }
                      if (noneCompleted) {
                        statusChip = (
                          <Chip
                            size="small"
                            variant="contained"
                            icon={<Iconify icon="ep:warning-filled" />}
                            color="error"
                            label={`${numCompleted} / ${numTasks} Done`}
                          />
                        );
                      }
                      if (partial) {
                        statusChip = (
                          <Chip
                            size="small"
                            variant="contained"
                            icon={<Iconify icon="ep:warning-filled" />}
                            color="warning"
                            label={`${numCompleted} / ${numTasks} Done`}
                          />
                        );
                      }

                      /**
                       * Visit Level Out Reach Attempt Log
                       */
                      let visitOutreachAttemptLog =
                        visit.visit.tl_events.filter(
                          (vvtl) => vvtl.header === "Provider Outreach Attempt"
                        );
                      let visitOutreachTimeline = null;
                      if (visitOutreachAttemptLog.length > 0) {
                        visitOutreachTimeline = (
                          <List>
                            {visitOutreachAttemptLog.map((vvtl, index) => {
                              let date = new Date(vvtl.createdAt);
                              let niceDate = format(date, "PPpp");
                              return (
                                <ListItem key={index}>
                                  <ListItemText
                                    primary={vvtl.notes}
                                    secondary={niceDate}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        );
                      } else {
                        visitOutreachTimeline = (
                          <Typography variant="caption">
                            No Outreach Attempts Logged
                          </Typography>
                        );
                      }
                      return (
                        <Accordion key={index}>
                          <AccordionSummary sx={{ background: "white" }}>
                            <Grid container width="100%" alignItems="center">
                              <Grid item xs={2}>
                                <Stack
                                  direction="row"
                                  spacing={0.5}
                                  alignItems={"center"}
                                >
                                  <Typography variant="overline">
                                    {employee.employeeName}
                                  </Typography>
                                  {visitIdElem}
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack
                                  direction="row"
                                  spacing={0.5}
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="caption"
                                    fontWeight={600}
                                  >
                                    {employee.employeeSex}
                                  </Typography>
                                  <Typography>|</Typography>
                                  <Typography
                                    variant="caption"
                                    fontWeight={600}
                                  >
                                    {employee.employeeDoB &&
                                      format(
                                        new Date(employee.employeeDoB),
                                        "PP"
                                      )}
                                  </Typography>{" "}
                                  <Typography>|</Typography>
                                  <Typography
                                    variant="caption"
                                    fontWeight={600}
                                  >
                                    {employee.employeeSSN || "SSN: Not On File"}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="end"
                                  spacing={0.5}
                                >
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    label={`${company.companyName}`}
                                  />
                                  <Tooltip title="Visit Date">
                                    <Chip
                                      size="small"
                                      variant="outlined"
                                      icon={
                                        <Iconify icon="fa-solid:clinic-medical" />
                                      }
                                      label={`${earliestPslDate ? format(earliestPslDate, "PP") : "NA"}`}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Protocols">
                                    {protocolNamesElem}
                                  </Tooltip>
                                  <Tooltip title="Last Updated Date">
                                    <Chip
                                      size="small"
                                      icon={<Iconify icon="ic:sharp-update" />}
                                      variant="outlined"
                                      label={`${lastUpdatedAtText}`}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Status">{statusChip}</Tooltip>
                                  <Tooltip title={visitOutreachTimeline}>
                                    <Badge
                                      badgeContent={
                                        visitOutreachAttemptLog.length
                                      }
                                      color="secondary"
                                    >
                                      <IconButton>
                                        <Iconify icon="mdi:timeline-clock-outline" />
                                      </IconButton>
                                    </Badge>
                                  </Tooltip>
                                  <Chip
                                    variant="outlined"
                                    label="Log Outreach Attempt"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        isOpenOutreachAttempt: true,
                                        outreachAttemptVisitId: visitId,
                                        outreachAttemptProviderId: providerId,
                                      });
                                    }}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell size="small">Protocol</TableCell>
                                  <TableCell size="small">
                                    Service Name
                                  </TableCell>
                                  <TableCell size="small">
                                    Document Type
                                  </TableCell>
                                  <TableCell size="small">File</TableCell>
                                  <TableCell size="small">Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {visitsForThisProvider.map((each, index) => {
                                  let attachedFiles = (
                                    each.visit?.linkedPageGroups || []
                                  ).filter(
                                    (fileGroup) =>
                                      fileGroup.serviceId ===
                                        each.masterService._id &&
                                      fileGroup.documentType ===
                                        each.linkedDocumentType
                                  );
                                  let status = each.taskStatus;
                                  let sx = {};
                                  if (
                                    status === "COMPLETED" ||
                                    status === "RECEIVED"
                                  ) {
                                    sx = {
                                      bgcolor: "success.light",
                                    };
                                  }
                                  return (
                                    <TableRow key={index} sx={sx}>
                                      <TableCell>
                                        {each.protocol?.companyProtocolName}
                                      </TableCell>
                                      <TableCell>
                                        {each.masterService?.serviceName}
                                      </TableCell>
                                      <TableCell>
                                        {each.linkedDocumentType.includes(
                                          "_"
                                        ) ? (
                                          <>
                                            {" "}
                                            {_.startCase(
                                              _.lowerCase(
                                                each.linkedDocumentType
                                              )
                                            )}
                                          </>
                                        ) : (
                                          <>{each.linkedDocumentType}</>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <Stack spacing={1}>
                                          {attachedFiles.map(
                                            (fileGroup, index) => (
                                              <Button
                                                href={`/documents/${fileGroup.cfId}`}
                                                key={index}
                                                size="small"
                                                variant="outlined"
                                                target="_blank"
                                                startIcon={
                                                  <Iconify icon="typcn:attachment" />
                                                }
                                              >
                                                Open File
                                              </Button>
                                            )
                                          )}
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Select
                                            value={each.taskStatus}
                                            size="small"
                                            onChange={(e) => {
                                              this.setState({
                                                isOpenTaskStatusUpdate: true,
                                                taskToUpdateId: each._id,
                                                taskToUpdateStatus:
                                                  e.target.value,
                                              });
                                            }}
                                          >
                                            {TASK_STATUS.map((each, index) => (
                                              <MenuItem
                                                key={index}
                                                value={each}
                                              >
                                                {each}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                          {each.taskStatusLog?.length > 0 && (
                                            <>
                                              <Tooltip title="View Status Log">
                                                <Badge
                                                  badgeContent={
                                                    each.taskStatusLog.length
                                                  }
                                                  color="secondary"
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      this.setState({
                                                        anchorEl:
                                                          e.currentTarget,
                                                        isPopoverOpen: true,
                                                        popOverId: each._id,
                                                      });
                                                    }}
                                                  >
                                                    <Iconify icon="mdi:timeline-clock-outline" />
                                                  </IconButton>
                                                </Badge>
                                              </Tooltip>
                                              <Popover
                                                id={each._id}
                                                open={
                                                  this.state.popOverId ===
                                                  each._id
                                                }
                                                anchorEl={this.state.anchorEl}
                                                onClose={() => {
                                                  this.setState({
                                                    anchorEl: null,
                                                    isPopoverOpen: false,
                                                    popOverId: null,
                                                  });
                                                }}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                }}
                                              >
                                                <List>
                                                  {each.taskStatusLog.map(
                                                    (status, index) => {
                                                      return (
                                                        <ListItem key={index}>
                                                          <ListItemText
                                                            primary={_.startCase(
                                                              _.lowerCase(
                                                                status.status
                                                              )
                                                            )}
                                                            secondary={
                                                              <Stack
                                                                spacing={0.5}
                                                              >
                                                                <Typography variant="caption">
                                                                  {
                                                                    status.statusBy
                                                                  }{" "}
                                                                  -{" "}
                                                                  {niceDateTime(
                                                                    status.statusAt
                                                                  )}
                                                                </Typography>
                                                                <Typography variant="overline">
                                                                  {status.note}
                                                                </Typography>
                                                              </Stack>
                                                            }
                                                          />
                                                        </ListItem>
                                                      );
                                                    }
                                                  )}
                                                </List>
                                              </Popover>
                                            </>
                                          )}
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    );
  };
  assignTasksToUser = async (providerIds, userId) => {
    this.setState({ isDoingAction: true });
    let taskIdsArray = [];
    let providerIdsLength = providerIds.length;
    for (var i = 0; i < providerIdsLength; i++) {
      let providerId = providerIds[i];
      let visitIds = Object.keys(this.state.groupedTaskList[providerId]);
      for (var j = 0; j < visitIds.length; j++) {
        let visitId = visitIds[j];
        let tasks = this.state.groupedTaskList[providerId][visitId];
        let idsArray = tasks.map((task) => task._id);
        taskIdsArray = [...taskIdsArray, ...idsArray];
      }
    }
    if (!taskIdsArray.length) {
      this.setState({
        isDoingAction: false,
        isEditingAssignee: false,
        selectedProvidersForTaskAssign: [],
        currentlyChosenUser: null,
        isSelectingProviderForAssignment: false,
      });
      return;
    }
    cogoToast.loading(`Assigning ${taskIdsArray.length} Tasks`);
    try {
      await this.props.apiCallPost("/task/bulkSetTaskAssignee", {
        taskIds: taskIdsArray,
        assigneeId: userId,
      });
      cogoToast.success("Tasks Assigned");
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Assigning Tasks");
    }
    /* 
    this.setState(
      {
        isDoingAction: true,
        isEditingAssignee: false,
        selectedProvidersForTaskAssign: [],
        currentlyChosenUser: null,
        isSelectingProviderForAssignment: false,
      },
      () => {
        this.searchTasks();
      }
    ); */
  };
  renderHeader = () => {
    let txt = "Viewing All Chaser Clinics";
    let color = "info";
    if (this.state.tasksFilteredForMe) {
      txt = "Viewing My Assigned Chaser Clinics";
      color = "primary";
    }
    const myAssignedTasks = (
      <Button
        size="small"
        variant="contained"
        color={color}
        onClick={() => {
          if (this.state.tasksFilteredForMe) {
            this.setState(
              {
                tasksFilteredForMe: null,
              },
              () => {
                this.searchTasks();
              }
            );
          } else {
            this.setState(
              {
                tasksFilteredForMe: this.context.currentUser._id,
              },
              () => {
                this.searchTasks();
              }
            );
          }
        }}
      >
        {txt}
      </Button>
    );
    let providersSelectedLength =
      this.state.selectedProvidersForTaskAssign.length;
    const assignButton = (
      <Button
        size="small"
        variant="contained"
        color={
          this.state.isSelectingProviderForAssignment ? "secondary" : "info"
        }
        onClick={() => {
          this.setState({
            isSelectingProviderForAssignment:
              !this.state.isSelectingProviderForAssignment,
          });
        }}
      >
        {this.state.isSelectingProviderForAssignment
          ? "Currently Assigning Chasers"
          : "Assign Chasers"}
      </Button>
    );
    let searchUsersToAssign = <>{this.renderUserSearcher()}</>;
    let assignToUserButton = (
      <LoadingButton
        loading={this.state.isDoingAction}
        disabled={!providersSelectedLength || !this.state.currentlyChosenUser}
        onClick={async () => {
          await this.assignTasksToUser(
            this.state.selectedProvidersForTaskAssign,
            this.state.currentlyChosenUser.data._id
          );
          this.setState({
            selectedProvidersForTaskAssign: [],
            currentlyChosenUser: null,
          });
        }}
      >
        Assign {providersSelectedLength} To{" "}
        {this.state.currentlyChosenUser?.mainText}
      </LoadingButton>
    );
    if (!providersSelectedLength || !this.state.currentlyChosenUser) {
      assignToUserButton = null;
    }
    return (
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Typography variant="h5">Chaser Tasks</Typography>
        <Button
          onClick={() => {
            this.setState({
              showSearchControls: !this.state.showSearchControls,
            });
          }}
        >
          {!this.state.showSearchControls
            ? "Show Search Controls"
            : "Hide Search Controls"}
        </Button>
        {myAssignedTasks}
        {assignButton}
        {providersSelectedLength > 0 && searchUsersToAssign}
        {assignToUserButton}
      </Stack>
    );
  };
  renderCompanySearcher = () => {
    if (this.state.currentUser.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          option ? (typeof option === "string" ? option : option?.mainText) : ""
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingCompany ? [] : this.state.companySearchResults
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInCompany}
        noOptionsText={
          this.state.isSearchingCompany ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              currentlyInCompany: null,
              companySearchResults: [],
              isSearchingCompany: false,
              employeeSearchResults: [],
              currentlyInEmployee: null,
              isSearchingEmployee: false,
            });
          }
          this.setState({
            isSearchingCompany: false,
            currentlyInCompany: value,
            companySearchResults: [value, ...this.state.companySearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;

          this.setState(
            {
              isSearchingCompany: true,
            },
            () => {
              this.searchCompaniesFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Employers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderProviderSearcher = () => {
    if (this.state.currentUser.isExternal) {
      return null;
    }
    return (
      <Autocomplete
        size="small"
        sx={{
          minWidth: "200px",
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.mainText
        }
        filterOptions={(x) => x}
        options={
          this.state.isSearchingProvider
            ? []
            : this.state.providerSearchResults || []
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={this.state.currentlyInProvider}
        noOptionsText={
          this.state.isSearchingProvider ? "Searching..." : "No results found"
        }
        onChange={(_, value) => {
          if (value === null) {
            this.setState({
              isSearchingProvider: false,
              currentlyInProvider: null,
              providerSearchResults: [],
            });
          }
          this.setState({
            isSearchingProvider: false,
            currentlyInProvider: value,
            providerSearchResults: [value, ...this.state.providerSearchResults],
          });
        }}
        onInputChange={(_, e) => {
          if (e === "") return null;
          if (!e) return null;

          this.setState(
            {
              isSearchingProvider: true,
            },
            () => {
              this.searchProvidersFTS(e);
            }
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Search Providers" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: "break-word" }}>
                  <Box component="span">{option?.mainText}</Box>
                  <Typography variant="body2" color="text.secondary">
                    {option?.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };
  renderTaskDueDateIn = () => {
    const shortcutsItems = [
      {
        label: "Today",
        getValue: () => {
          const today = moment();
          return [today, today];
        },
      },
      {
        label: "Last 3 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(3, "days"), today];
        },
      },
      {
        label: "Next 3 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(3, "days")];
        },
      },
      {
        label: "Last 7 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(7, "days"), today];
        },
      },
      {
        label: "Next 7 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(7, "days")];
        },
      },
      {
        label: "Last 30 Days",
        getValue: () => {
          const today = moment();
          return [today.clone().subtract(30, "days"), today];
        },
      },
      {
        label: "Next 30 Days",
        getValue: () => {
          const today = moment();
          return [today, today.clone().add(30, "days")];
        },
      },
      {
        label: "This Month",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
      },
      {
        label: "Next Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "month").startOf("month"),
            today.clone().add(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "Last Month",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "month").startOf("month"),
            today.clone().subtract(1, "month").endOf("month"),
          ];
        },
      },
      {
        label: "This Week",
        getValue: () => {
          const today = moment();
          return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
      },
      {
        label: "Next Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().add(1, "week").startOf("week"),
            today.clone().add(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Last Week",
        getValue: () => {
          const today = moment();
          return [
            today.clone().subtract(1, "week").startOf("week"),
            today.clone().subtract(1, "week").endOf("week"),
          ];
        },
      },
      {
        label: "Reset",
        getValue: () => [null, null],
      },
    ];
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          size="small"
          slotProps={{
            shortcuts: {
              changeImportance: "accept",
              dense: true,
              subheader: (
                <Typography variant="caption" sx={{ p: 2 }}>
                  Shortcuts
                </Typography>
              ),
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          value={this.state.taskDueDateIn}
          localeText={{
            start: "Due Dates: From",
            end: "Due Dates: To",
          }}
          onChange={(newValue) => {
            this.setState({
              taskDueDateIn: newValue,
            });
          }}
        />
      </LocalizationProvider>
    );
  };
  renderFilterBox = () => {
    if (!this.state.showSearchControls) {
      return null;
    }
    let userSearcher = <>{this.renderUserSearcher()}</>;
    if (this.state.tasksFilteredForMe) {
      userSearcher = null;
    }
    let taskStatus = this.state.taskStatus;
    let text = "Excludes Completed Tasks";
    if (taskStatus.includes("COMPLETED")) {
      text = "Includes Completed Tasks";
    }
    let showCompleted = (
      <Button
        size="small"
        onClick={() => {
          if (taskStatus.includes("COMPLETED")) {
            taskStatus = taskStatus.filter((each) => each !== "COMPLETED");
          } else {
            taskStatus.push("COMPLETED");
          }
          this.setState({ taskStatus });
        }}
      >
        {text}
      </Button>
    );

    return (
      <Stack spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        {userSearcher}
        {this.renderCompanySearcher()}
        {this.renderEmployeeSearcher()}
        {this.renderProviderSearcher()}
        {this.renderTaskDueDateIn()}
        {showCompleted}
        <LoadingButton
          size="small"
          loading={this.state.isSearching}
          onClick={this.searchTasks}
          variant="outlined"
          startIcon={<Iconify icon="material-symbols:search" />}
        >
          Search
        </LoadingButton>
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Stack spacing={2}>
        {this.renderTaskStatusUpdateDialog()}
        {this.renderOutreachAttemptDialog()}
        {this.renderHeader()}
        {this.renderFilterBox()}
        {this.renderTaskList()}
      </Stack>
    );
  }
}

export default WithAPICall(TaskListByProviderGroupUpdated);

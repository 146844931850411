import { Navigate, useRoutes } from "react-router-dom";
import ListCompanies from "src/micro/Company/ListCompanies";
import CompanyByID from "src/micro/Company/CompanyByID";
import ComingSoonView from "src/sections/coming-soon/view";
import EmployeeByID from "src/micro/Employee/EmployeeByID";
import ListProviderNetworks from "src/micro/ProviderNetwork/ListProviderNetworks";
import ProviderNetworkByID from "src/micro/ProviderNetwork/ProviderNetworkByID";
import ListProviders from "src/micro/Provider/ListProviders";
import ProviderByID from "src/micro/Provider/ProviderByID";
import ListServices from "src/micro/Services/ListServices";
import ServiceByID from "src/micro/Services/ServiceByID";
import ProtocolByCompanyID from "src/micro/Protocols/ProtocolByCompanyID";
import TransfersRoot from "src/micro/Transfers/TransfersRoot";
import CreateVisit from "src/micro/Visit/CreateVisit";
import VisitByID from "src/micro/Visit/VisitByID";
import VisitList from "src/micro/Visit/VisitList";
import ListDocuments from "src/micro/Documents/ListDocuments";
import DocumentByID from "src/micro/Documents/DocumentByID";
import ListDocumentGroups from "src/micro/Documents/ListDocumentGroups";
import DocumentGroupById from "src/micro/Documents/DocumentGroupById";
import TaskList from "src/micro/Tasks/TaskList";
import UserList from "src/micro/Users/UserList";
import ListMasterProtocols from "src/micro/MasterProtocol/ListMasterProtocols";
import MasterProtocolByID from "src/micro/MasterProtocol/MasterProtocolByID";
import Emails from "src/micro/Emails/Emails";
import Dashboard from "src/micro/Dashboard/Dashboard";
import ReportDetails from "src/micro/Report/ReportDetails";
// import IncidentInterventionDetails from "src/micro/IncidentIntervention/IncidentInterventionDetails";
// import RNScreen from "src/micro/IncidentIntervention/RNScreen";
// import AuditCriteria from "src/micro/IncidentIntervention/AuditCriteria";
// import ClinicalSOPComponent from "src/micro/IncidentIntervention/ClinicalSOPComponent";
// import CompanySpecificInstruction from "src/micro/IncidentIntervention/CompanySpecificInstruction";
// import RNAllCases from "src/micro/IncidentIntervention/RNAllCases";
import { useContext } from "react";
import TokenContext from "src/TokenContext";
import RequestVisit from "src/micro/Visit/RequestVisit";
import EmployeeRoster from "src/micro/Company/ER/EmployeeRoster";
import VisitListEE from "src/micro/Visit/VisitListEE";
import VisitByIDEE from "src/micro/Visit/EE/VisitByIDEE";
import DashboardER from "src/micro/Dashboard/DashboardER";
import ER_EmployeeByID from "src/micro/Employee/ER/ER_EmployeeByID";
import VisitListER from "src/micro/Visit/ER/VisitListER";
import TaskListByProviderGroupUpdated from "src/micro/Tasks/TaskListByProviderGroupUpdated";
import ClearanceList from "src/micro/Clearances/ClearanceList";
import LoginView from "src/micro/utils/LoginView";
import LabPricingTable from "src/micro/LabPricing/LabPricingTable";
import AuthorizationLetterById from "src/micro/Prints/AuthorizationLetterById";
import FormFoxAuthorizationLetterById from "src/micro/Prints/FormFoxAuthorizationLetterById";
import PatientLetterByID from "src/micro/Prints/PatientLetterByID";
import VisitProtocolClearanceByID from "src/micro/Prints/VisitProtocolClearanceByID";
import VisitDrugClearanceByID from "src/micro/Prints/VisitDrugClearanceByID";
import MedSurveillanceList from "src/micro/MedSurveillance/MedSurveillanceList";
import DataFiles from "../../micro/DataFiles/DataFiles";
import VisitAlcoholClearanceByID from "../../micro/Prints/VisitAlcoholClearanceByID";
import VisitProtocolClearanceForArkemaByID from "../../micro/Prints/VisitProtocolClearanceForArkemaByID";

export default function Router() {
  const { currentUser } = useContext(TokenContext);
  if (!currentUser) {
    return useRoutes([
      {
        path: "/",
        children: [{ index: true, element: <LoginView /> }],
      },
      {
        path: "/upcoming",
        children: [{ index: true, element: <div>Upcoming</div> }],
      },
      {
        path: "visits",
        children: [
          {
            path: ":id",
            element: <div>Visits By ID</div>,
          },
        ],
      },
      {
        path: "*",
        children: [{ element: <Navigate to="/404" replace /> }],
      },
    ]);
  }
  if (currentUser.isEmployee) {
    return useRoutes([
      {
        path: "/",
        children: [{ index: true, element: <VisitListEE /> }],
      },
      {
        path: "/upcoming",
        children: [{ index: true, element: <VisitListEE key="upcoming" /> }],
      },
      {
        path: "/completed",
        children: [{ index: true, element: <VisitListEE key="completed" /> }],
      },
      {
        path: "/in-process",
        children: [{ index: true, element: <VisitListEE key="in-process" /> }],
      },
      {
        path: "visits",
        children: [
          {
            path: ":id",
            element: <VisitByIDEE />,
          },
        ],
      },
    ]);
  }
  if (currentUser.isExternal == false) {
    return useRoutes([
      {
        path: "/",
        children: [{ element: <Dashboard />, index: true }],
      },
      {
        path: "employers",
        children: [
          { element: <ListCompanies />, index: true },
          {
            path: ":id",
            element: <CompanyByID />,
          },
        ],
      },
      {
        path: "protocols",
        children: [
          {
            path: ":id",
            element: <ProtocolByCompanyID />,
          },
        ],
      },
      {
        path: "employees",
        children: [
          {
            path: ":id",
            element: <EmployeeByID />,
          },
        ],
      },
      {
        path: "networks",
        children: [
          { element: <ListProviderNetworks />, index: true },
          {
            path: ":id",
            element: <ProviderNetworkByID />,
          },
        ],
      },
      {
        path: "providers",
        children: [
          { element: <ListProviders />, index: true },
          {
            path: ":id",
            element: <ProviderByID />,
          },
        ],
      },
      {
        path: "documents",
        children: [
          { element: <ListDocuments />, index: true },
          {
            path: ":id",
            element: <DocumentByID />,
          },
        ],
      },
      {
        path: "datafiles",
        children: [
          { element: <DataFiles />, index: true },
          // {
          //   path: ":id",
          //   element: <DataFileByID />,
          // },
        ],
      },
      {
        path: "groups",
        children: [
          { element: <ListDocumentGroups />, index: true },
          {
            path: ":id",
            element: <DocumentGroupById />,
          },
        ],
      },
      {
        path: "services",
        children: [
          { element: <ListServices />, index: true },
          {
            path: ":id",
            element: <ServiceByID />,
          },
        ],
      },
      {
        path: "masterprotocols",
        children: [
          { element: <ListMasterProtocols />, index: true },
          {
            path: ":id",
            element: <MasterProtocolByID />,
          },
        ],
      },

      {
        path: "labpricings",
        children: [{ element: <LabPricingTable />, index: true }],
      },
      {
        path: "authorization-letter-clinic",
        children: [
          {
            path: ":id",
            element: <AuthorizationLetterById />,
            index: true,
          },
        ],
      },
      {
        path: "authorization-letter-formfox",
        children: [
          {
            path: ":id",
            element: <FormFoxAuthorizationLetterById />,
            index: true,
          },
        ],
      },
      {
        path: "patient-letter",
        children: [
          {
            path: ":id",
            element: <PatientLetterByID />,
            index: true,
          },
        ],
      },
      {
        path: "prints/visit-protocol-clearance",
        children: [
          {
            path: ":id",
            element: <VisitProtocolClearanceByID />,
          },
        ],
      },
      {
        path: "prints/visit-protocol-clearance-arkema",
        children: [
          {
            path: ":id",
            element: <VisitProtocolClearanceForArkemaByID />,
          },
        ],
      },
      {
        path: "prints/visit-protocol-drug-clearance",
        children: [
          {
            path: ":id",
            element: <VisitDrugClearanceByID />,
          },
        ],
      },
      {
        path: "prints/visit-protocol-alcohol-clearance",
        children: [
          {
            path: ":id",
            element: <VisitAlcoholClearanceByID />,
          },
        ],
      },
      {
        path: "prints/patient-letter",
        children: [
          {
            path: ":id",
            element: <PatientLetterByID />,
            index: true,
          },
        ],
      },
      {
        path: "transfers",
        children: [{ element: <TransfersRoot />, index: true }],
      },
      {
        path: "visits",
        children: [
          {
            index: true,
            element: <VisitList />,
          },
          {
            path: ":id",
            element: <VisitByID />,
          },
        ],
      },
      {
        path: "visits/new",
        children: [{ element: <CreateVisit />, index: true }],
      },
      {
        path: "surveillance",
        children: [
          {
            index: true,
            element: <MedSurveillanceList />,
          },
        ],
      },
      {
        path: "tasks",
        children: [
          {
            element: <TaskList />,
            index: true,
          },
        ],
      },
      {
        path: "tasks/chaser",
        children: [
          { element: <TaskListByProviderGroupUpdated />, index: true },
        ],
      },
      {
        path: "tasks/admin-chaser",
        children: [
          { element: <TaskListByProviderGroupUpdated />, index: true },
        ],
      },
      {
        path: "tasks/clearances",
        children: [{ element: <ClearanceList />, index: true }],
      },
      {
        path: "users",
        children: [
          {
            element: <UserList />,
            index: true,
          },
        ],
      },
      // {
      //   path: "emails",
      //   children: [{ element: <Emails />, index: true }],
      // },
      {
        path: "reports",
        children: [{ element: <ReportDetails />, index: true }],
      },
      // {
      //   path: "ii",
      //   children: [
      //     {
      //       index: true,
      //       element: <IncidentInterventionDetails />,
      //     },
      //   ],
      // },
      // {
      //   path: "ii-rn",
      //   children: [
      //     {
      //       index: true,
      //       element: <RNScreen />,
      //     },
      //   ],
      // },
      // {
      //   path: "/audit-criteria",
      //   children: [{ index: true, element: <AuditCriteria /> }],
      // },
      // {
      //   path: "/clinical-sop",
      //   children: [{ index: true, element: <ClinicalSOPComponent /> }],
      // },
      // {
      //   path: "/csi",
      //   children: [{ index: true, element: <CompanySpecificInstruction /> }],
      // },
      // {
      //   path: "/cases",
      //   children: [{ index: true, element: <RNAllCases /> }],
      // },
      {
        path: "/",
        element: <ComingSoonView />,
      },
      { path: "*", element: <Navigate to="/404" replace /> },
    ]);
  } else {
    return useRoutes([
      {
        path: "/",
        children: [{ element: <DashboardER />, index: true }],
      },
      {
        path: "protocols",
        children: [
          {
            path: ":id",
            element: <ProtocolByCompanyID />,
          },
        ],
      },
      {
        path: "employee-roster",
        children: [
          {
            index: true,
            element: <EmployeeRoster />,
          },
        ],
      },
      {
        path: "employees",
        children: [
          {
            path: ":id",
            element: <ER_EmployeeByID />,
          },
        ],
      },
      {
        path: "networks",
        children: [
          { element: <ListProviderNetworks />, index: true },
          {
            path: ":id",
            element: <ProviderNetworkByID />,
          },
        ],
      },
      {
        path: "providers",
        children: [
          { element: <ListProviders />, index: true },
          {
            path: ":id",
            element: <ProviderByID />,
          },
        ],
      },
      {
        path: "documents",
        children: [
          { element: <ListDocuments />, index: true },
          {
            path: ":id",
            element: <DocumentByID />,
          },
        ],
      },
      {
        path: "services",
        children: [
          { element: <ListServices />, index: true },
          {
            path: ":id",
            element: <ServiceByID />,
          },
        ],
      },
      {
        path: "masterprotocols",
        children: [
          { element: <ListMasterProtocols />, index: true },
          {
            path: ":id",
            element: <MasterProtocolByID />,
          },
        ],
      },
      {
        path: "transfers",
        children: [{ element: <TransfersRoot />, index: true }],
      },
      {
        path: "visits",
        children: [
          {
            index: true,
            element: <VisitListER key="all" />,
          },
          {
            path: ":id",
            element: <VisitByID />,
          },
        ],
      },
      {
        path: "visits/request",
        children: [{ element: <RequestVisit />, index: true }],
      },
      {
        path: "visits/requested",
        children: [{ element: <VisitListER key="requested" />, index: true }],
      },
      {
        path: "visits/new",
        children: [{ element: <CreateVisit />, index: true }],
      },
    ]);
  }
}

import {
  Card,
  FormControlLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Stack } from "@mui/system";
import cogoToast from "cogo-toast";
import React from "react";

import { formFoxServices } from "../../assets/data/formfox";
import { WithAPICall } from "../utils/apiUtil";
import { isEqual, uniqWith } from "lodash";
import {
  SERVICE_DATA_TYPE_OPTIONS,
  TYPE_OF_DOCUMENT_OPTIONS,
} from "src/assets/data/service-data-type";
import DOCUMENT_TYPES from "./data/document-types";
import { ALL_LAB_NAMES } from "src/assets/data/lab-names";

class ServiceProfile extends React.Component {
  state = { serviceDataType: [], isLoading: true };
  componentDidMount() {
    this.setState({
      ...this.props.data,
      isLoading: false,
    });
    this.loadProviderNetworks();
  }
  SERVICE_TYPE_OPTIONS = [
    "QUESTIONNAIRE",
    "MEDICAL_EXAM",
    "LAB_TEST",
    "ALCOHOL",
    "DRUG",
    "VACCINE",
    "ADMINISTRATIVE",
    "CHART_REVIEW",
    "COLLECTION",
    "OTHER",
  ];
  SERVICE_PROVIDER_TYPE_OPTIONS = ["Lab", "Clinic"];

  FORM_FOX_SERVICES = formFoxServices.map((f) => f.name);

  loadProviderNetworks = async () => {
    try {
      const res = await this.props.apiCallPost("/provider/defaultLabs");
      this.setState({
        defaultLabs: res,
      });
    } catch (error) {
      console.error("could not get provider networks", error);
    }
  };

  updateScalarValue = async (key, value) => {
    if (value === null || value === undefined) {
      return;
    }
    if (this.props.data[key] === value) {
      return;
    }
    try {
      await this.props.apiCallPost(
        "/master-service/masterServiceUpdateScalarValue",
        {
          masterServiceId: this.props.id,
          key: key,
          value: value,
        }
      );
    } catch (err) {
      cogoToast.error("Error Updating Service Data");
      console.log(err);
    }
  };
  renderInstructions = () => {
    return (
      <Stack spacing={3}>
        <ListItemText
          sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: "background.neutral",
          }}
          primary="Instructions"
          secondary="Instructions you enter here will be sent to the provider and the employee."
          primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
          secondaryTypographyProps={{ component: "span" }}
        />{" "}
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <TextField
            label="Provider Instructions"
            value={this.state.providerInstruction}
            multiline
            onChange={(e) => {
              this.setState({
                providerInstruction: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "providerInstruction",
                this.state.providerInstruction
              );
            }}
          />{" "}
          <TextField
            label="Patient Instructions"
            value={this.state.patientInstruction}
            multiline
            onChange={(e) => {
              this.setState({
                patientInstruction: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "patientInstruction",
                this.state.patientInstruction
              );
            }}
          />{" "}
          <TextField
            disabled={this.state.serviceType !== "QUESTIONNAIRE"}
            label="Questionnaire Instructions"
            value={this.state.questionnairePtInsn}
            multiline
            onChange={(e) => {
              this.setState({
                questionnairePtInsn: e.target.value,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(
                "questionnairePtInsn",
                this.state.questionnairePtInsn
              );
            }}
          />
        </Box>
      </Stack>
    );
  };
  getStyles(item) {
    let list = this.state.serviceDataType;
    return {
      fontWeight: list.indexOf(item) === -1 ? "regular" : "bold",
    };
  }
  renderServiceDataType = () => {
    return;
    // return (
    //   <FormControlLabel
    //     label="Service Data Type"
    //     labelPlacement="start"
    //     control={
    //       <Select
    //         value={this.state.serviceDataType}
    //         onChange={(e) => {
    //           let v = e.target.value;
    //           this.setState(
    //             {
    //               serviceDataType: v,
    //             },
    //             () => {
    //               this.updateScalarValue(
    //                 "serviceDataType",
    //                 this.state.serviceDataType
    //               );
    //             }
    //           );
    //         }}
    //         sx={{
    //           minWidth: "250px",
    //         }}
    //       >
    //         {SERVICE_DATA_TYPE_OPTIONS.map((item) => (
    //           <MenuItem key={item} value={item}>
    //             {item}
    //           </MenuItem>
    //         ))}
    //       </Select>
    //     }
    //     sx={{
    //       m: 0,
    //       width: 1,
    //       justifyContent: "space-between",
    //     }}
    //   />
    // );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            <Card sx={{ pt: 3, pb: 3, px: 3, textAlign: "center" }}>
              <Stack spacing={2}>
                <TextField
                  label="Service Name"
                  value={this.state.serviceName}
                  onChange={(e) => {
                    this.setState({
                      serviceName: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "serviceName",
                      this.state.serviceName
                    );
                  }}
                />
                <TextField
                  label="Service Code"
                  value={this.state.serviceCode}
                  disabled={true}
                />
                <FormControlLabel
                  label="Types of Documents"
                  labelPlacement="start"
                  control={
                    <Select
                      multiple
                      value={this.state.documentTypes}
                      sx={{
                        minWidth: "200px",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.setState({
                          documentTypes: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "documentTypes",
                          this.state.documentTypes
                        );
                      }}
                    >
                      {TYPE_OF_DOCUMENT_OPTIONS.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                <FormControlLabel
                  label="Service Category"
                  labelPlacement="start"
                  control={
                    <Select
                      value={this.state.serviceType}
                      sx={{
                        minWidth: "200px",
                      }}
                      onChange={(e) => {
                        this.setState(
                          {
                            serviceType: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "serviceType",
                              this.state.serviceType
                            );
                          }
                        );
                      }}
                    >
                      {this.SERVICE_TYPE_OPTIONS.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
                <FormControlLabel
                  label="Service Documentation (Detailed)"
                  labelPlacement="start"
                  control={
                    <Select
                      value={this.state.serviceDataType}
                      onChange={(e) => {
                        let v = e.target.value;
                        this.setState(
                          {
                            serviceDataType: v,
                          },
                          () => {
                            this.updateScalarValue(
                              "serviceDataType",
                              this.state.serviceDataType
                            );
                          }
                        );
                      }}
                      sx={{
                        minWidth: "250px",
                      }}
                    >
                      {SERVICE_DATA_TYPE_OPTIONS.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
                <Box
                  rowGap={3}
                  columnGap={4}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  }}
                >
                  <TextField
                    multiline
                    label="Documents TAT (Days)"
                    value={this.state.documentsTAT}
                    onChange={(e) => {
                      this.setState({
                        documentsTAT: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      this.updateScalarValue(
                        "documentsTAT",
                        parseInt(this.state.documentsTAT)
                      );
                    }}
                  />
                  <FormControlLabel
                    label={"Is Active"}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={this.state.isActive}
                        onChange={() => {
                          this.setState(
                            {
                              isActive: !this.state.isActive,
                            },
                            () => {
                              this.updateScalarValue(
                                "isActive",
                                this.state.isActive
                              );
                            }
                          );
                        }}
                      />
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  />{" "}
                  <FormControlLabel
                    label={"Is WorkCare Internal"}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={this.state.isHandledByWorkCare}
                        onChange={() => {
                          this.setState(
                            {
                              isHandledByWorkCare:
                                !this.state.isHandledByWorkCare,
                            },
                            () => {
                              this.updateScalarValue(
                                "isHandledByWorkCare",
                                this.state.isHandledByWorkCare
                              );
                            }
                          );
                        }}
                      />
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  />{" "}
                  <FormControlLabel
                    disabled={this.state.isHandledByWorkCare}
                    label="Type of Provider"
                    labelPlacement="start"
                    control={
                      <Select
                        disabled={this.state.isHandledByWorkCare}
                        value={this.state.serviceRequiresProviderType}
                        sx={{
                          minWidth: "200px",
                        }}
                        onChange={(e) => {
                          this.setState(
                            {
                              serviceRequiresProviderType: e.target.value,
                            },
                            () => {
                              this.updateScalarValue(
                                "serviceRequiresProviderType",
                                this.state.serviceRequiresProviderType
                              );
                            }
                          );
                        }}
                      >
                        {this.SERVICE_PROVIDER_TYPE_OPTIONS.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  />
                  <FormControlLabel
                    label={"Custody Form Required"}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={this.state.hasCustodyForm}
                        onChange={() => {
                          this.setState(
                            {
                              hasCustodyForm: !this.state.hasCustodyForm,
                            },
                            () => {
                              this.updateScalarValue(
                                "hasCustodyForm",
                                this.state.hasCustodyForm
                              );
                            }
                          );
                        }}
                      />
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: "space-between",
                    }}
                  />
                </Box>
                <FormControlLabel
                  label="Default Lab"
                  labelPlacement="start"
                  control={
                    <Select
                      value={this.state.defaultLab}
                      sx={{
                        minWidth: "200px",
                      }}
                      onChange={(e) => {
                        this.setState(
                          {
                            defaultLab: e.target.value,
                          },
                          () => {
                            this.updateScalarValue(
                              "defaultLab",
                              e.target.value
                            );
                          }
                        );
                      }}
                    >
                      {ALL_LAB_NAMES.map((ln) => (
                        <MenuItem key={ln.key} value={ln.key}>
                          {ln.value}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    m: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
                {this.state.serviceType === "DRUG" && (
                  <>
                    {/* <FormControlLabel
                      label={"Split Sample"}
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={this.state.formFoxSampleRequestSplit}
                          onChange={() => {
                            this.setState(
                              {
                                formFoxSampleRequestSplit:
                                  !this.state.formFoxSampleRequestSplit,
                              },
                              () => {
                                this.updateScalarValue(
                                  "formFoxSampleRequestSplit",
                                  this.state.formFoxSampleRequestSplit
                                );
                              }
                            );
                          }}
                        />
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    /> */}
                    {/* <FormControlLabel
                      label={"Request Observation"}
                      labelPlacement="start"
                      control={
                        <Switch
                          checked={this.state.formFoxSampleRequestObservation}
                          onChange={() => {
                            this.setState(
                              {
                                formFoxSampleRequestObservation:
                                  !this.state.formFoxSampleRequestObservation,
                              },
                              () => {
                                this.updateScalarValue(
                                  "formFoxSampleRequestObservation",
                                  this.state.formFoxSampleRequestObservation
                                );
                              }
                            );
                          }}
                        />
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    /> */}
                    <TextField
                      multiline
                      label="Substances Tested"
                      value={this.state.substancesTested}
                      onChange={(e) => {
                        this.setState({
                          substancesTested: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        this.updateScalarValue(
                          "substancesTested",
                          this.state.substancesTested
                        );
                      }}
                    />
                  </>
                )}
                <TextField
                  multiline
                  label="Service Description"
                  value={this.state.serviceDescription}
                  onChange={(e) => {
                    this.setState({
                      serviceDescription: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "serviceDescription",
                      this.state.serviceDescription
                    );
                  }}
                />{" "}
                <TextField
                  multiline
                  label="Service Note"
                  value={this.state.serviceNotes}
                  onChange={(e) => {
                    this.setState({
                      serviceNotes: e.target.value,
                    });
                  }}
                  onBlur={() => {
                    this.updateScalarValue(
                      "serviceNotes",
                      this.state.serviceNotes
                    );
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                {this.renderInstructions()}
                {this.renderServiceDataType()}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WithAPICall(ServiceProfile);

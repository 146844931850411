"use client";
import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import _ from "lodash";

import Iconify from "src/components/iconify";
// import {
//   Drawer,
//   DrawerClose,
//   DrawerContent,
//   DrawerDescription,
//   DrawerFooter,
//   DrawerHeader,
//   DrawerTitle,
//   DrawerTrigger,
// } from "@/components/ui/drawer";
// import DocumentDetails from './DocumentDetails'

import { ScrollArea } from "@/components/ui/scroll-area";

import { DataFileProcessingTimeline } from "./datafile-processing-timeline";

import { useProtectedApi } from "@/hooks/use-apiCall";
import {
  Input,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  Drawer,
  Card,
  Button,
} from "@mui/material";
import { Stack } from "@mui/system";
import { RefreshCwIcon } from "lucide-react";

export function DataFileList({ isDone, isFromBacklog }) {
  const [search, setSearch] = useState("");
  const [processStatus, setProcessStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { callApi } = useProtectedApi();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      setIsLoading(true);
      const response = await callApi("/datafiles/list", {
        isDone: isDone,
        isFromBacklog: isFromBacklog,
      });
      const data = response;
      setRowData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };
  const columnDefs = [
    {
      headerName: "Original Name",
      field: "originalName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Uploaded By",
      field: "uploadedByName",
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        const data = params.data;
        if (data.uploadedByName === "documents@workcare.com") {
          return `${data.emailThreadDetails?.sender?.emailAddress?.address} (via documents@workcare.com)`;
        } else if (data.uploadedByName === "backlog@workcare.com") {
          return `${data.emailThreadDetails?.sender?.emailAddress?.address} (via backlog@workcare.com)`;
        } else {
          return data.uploadedByName;
        }
      },
    },
    {
      headerName: "Uploaded At",
      field: "createdAt",
      sortable: true,
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString();
      },
    },
    {
      headerName: "EE(s)",
      field: "extractedEEInfo",
      sortable: true,
      filter: true,
      valueFormatter: ({ value }) => {
        const ls = value || [];
        const names = ls.map((e) => {
          let ln = `${e.employeeFirstName} ${e.employeeLastName}`;
          if (e.employerName) {
            ln = `${ln} (${e.employerName})`;
          }
          return ln;
        });
        return names.join(", ");
      },
    },
    {
      headerName: "File Size",
      field: "sizeBytes",
      sortable: true,
      filter: true,
      valueFormatter: ({ value }) => {
        if (value === null) {
          return "N/A";
        }
        // if < 1 MB, show in KB
        if (value < 1024 * 1024) {
          return `${(value / 1024).toFixed(2)} KB`;
        } else {
          return `${(value / (1024 * 1024)).toFixed(2)} MB`;
        }
      },
    },
    {
      headerName: "Process Status",
      field: "processStatus",
      sortable: true,
      filter: true,
      valueFormatter: ({ value }) => {
        return _.startCase(_.lowerCase(value));
      },
    },
    {
      headerName: "View Data",
      field: "actions",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="icon-park-outline:share" />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRowClicked(params.data);
            }}
          >
            Click
          </Button>
        );
      },
    },
    {
      headerName: "Open PDF",
      field: "actions",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="icon-park-outline:share" />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(params.data.psUrl, "_blank");
            }}
          >
            Open File
          </Button>
        );
      },
    },
    {
      headerName: "Navigator ID",
      field: "_id",
      sortable: true,
      filter: true,
    },
  ];
  const onRowClicked = async (event) => {
    const data = event.data;
    console.log("data", data);
    const response = await callApi(`/datafiles/getById`, {
      dataFileId: data._id,
    });
    setSelectedDocument(response);
    setIsDrawerOpen(true);
  };
  const searchInputBox = (
    <Input
      placeholder="Search by EE name"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
  const processStatusDropdown = (
    <Select
      size="small"
      value={processStatus}
      onChange={(e) => setProcessStatus(e.target.value)}
    >
      <MenuItem value="All">All</MenuItem>
      <MenuItem value="PROCESSING">Processing</MenuItem>
      <MenuItem value="PARSING_DATA_FILE">Parsing Data File</MenuItem>
      <MenuItem value="EXTRACTING_EE_NAMES">Extracting EE Names</MenuItem>
      <MenuItem value="EXTRACTING_EE_PAGE_RANGE">
        Extracting EE Page Range
      </MenuItem>
      <MenuItem value="EXTRACTING_DOCUMENT_DATA_TYPES">
        Extracting Document Data Types
      </MenuItem>
      <MenuItem value="EXTRACTING_DOCUMENT_MEDICAL_DATA">
        Extracting Document Medical Data
      </MenuItem>
      <MenuItem value="LINKING_VISIT">Linking Visit</MenuItem>
      {/* <MenuItem value="DONE">Done</MenuItem> */}
    </Select>
  );
  const refreshButton = (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={fetchDocuments}
      disabled={isLoading}
      startIcon={<Iconify icon="material-symbols:refresh" />}
    >
      Reload File List
    </Button>
  );

  const searchBar = (
    <Stack spacing={2} direction="row" alignItems="center">
      <Typography variant="overline">Search By Employee Name</Typography>
      {searchInputBox}
      <Typography variant="overline">Process Status</Typography>
      {processStatusDropdown}
      {refreshButton}
    </Stack>
  );
  let filteredRowData = rowData;
  if (isLoading) {
    return <LinearProgress />;
  }
  if (search) {
    filteredRowData = filteredRowData.filter((row) => {
      const eeNames = row.extractedEEInfo?.map(
        (e) => `${e.employeeFirstName} ${e.employeeLastName}`
      );
      return eeNames.some((name) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    });
  }
  if (processStatus !== "All") {
    filteredRowData = filteredRowData.filter(
      (row) => row.processStatus === processStatus
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Stack spacing={1} direction="column" alignItems="center">
        <Card
          sx={{
            p: 1,
            width: "100%",
            borderBottom: "1px solid #bcd2d0",
            borderRadius: "0.5rem",
          }}
        >
          {searchBar}
        </Card>{" "}
        <Typography
          variant="caption"
          sx={{
            textAlign: "left",
            width: "100%",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          Click anywhere on the row to view details.
        </Typography>
        <div className="ag-theme-alpine w-full h-[70vh] overflow-y-auto">
          <AgGridReact
            rowData={filteredRowData}
            columnDefs={columnDefs}
            onRowClicked={onRowClicked}
            pagination={true}
            paginationPageSize={500}
            sizeColumnsToFit={true}
          />
        </div>
      </Stack>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div
          style={{
            width: "95vw",
            height: "100vh",
            padding: "1rem",
          }}
        >
          {selectedDocument && (
            <DataFileProcessingTimeline
              _id={selectedDocument._id}
              refresh={true}
              onLegacyTransfer={() => {
                setIsDrawerOpen(false);
                fetchDocuments();
              }}
              onRestartAIPipeline={() => {
                setIsDrawerOpen(false);
                fetchDocuments();
              }}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
}

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import DownloadButton from "./download-button";
import { fileData, fileThumb, fileFormat } from "./utils";
import { Button, ButtonGroup, Typography } from "@mui/material";
import Iconify from "../iconify/iconify";
import { truncate } from "lodash";
import { useMemo, useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import useStateRef from "react-usestateref";
import { WithAPICall } from "src/micro/utils/apiUtil";

function FileThumbnail({
  file,
  onDownload,
  onProcessDocument,
  sx,
  apiCallPost,
}) {
  const [downloading, setDownloading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [cfId, setCfId, cfIdRef] = useStateRef(file.cfId);

  const { name = "" } = fileData(file);
  const format = useMemo(() => fileFormat(name), []);

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const response = await apiCallPost("/email/downloadFile", {
        objectName: file.s3ObjectUrl,
      });
      const fileBuffer = response;
      const fileBlob = new Blob([fileBuffer], {
        type: "application/octet-stream",
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = file.name;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setDownloading(false);
    }
  };

  const handleProcessDocument = async () => {
    if (cfIdRef.current) {
      window.open(`/documents/${cfIdRef.current}`, "non_blank");
    } else {
      setProcessing(true);
      console.log("[file:processing]", true);
      const cfRes = await onProcessDocument();
      console.log("[file:processing]", false, cfRes);
      setCfId(cfRes._id);
      setProcessing(false);
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        background: "white",
        padding: "0.5rem",
        borderRadius: "1rem",
      }}
    >
      <Tooltip title={file.name}>
        <Typography variant="overline">{truncate(file.name)}</Typography>
      </Tooltip>
      <Stack direction="row">
        <Box
          component="img"
          src={fileThumb(format)}
          sx={{
            flexShrink: 0,
            marginRight: "0.5rem",
          }}
        />
        <Stack direction="column">
          <ButtonGroup orientation="vertical" variant="text">
            <Button
              size="small"
              startIcon={<Iconify icon="material-symbols:download" />}
              onClick={handleDownload}
            >
              Download
            </Button>
            {/* <LoadingButton
              size="small"
              loading={processing}
              startIcon={
                <Iconify
                  icon={
                    cfId
                      ? "mdi:arrow-top-right"
                      : "icon-park-outline:process-line"
                  }
                />
              }
              onClick={handleProcessDocument}
            >
              {cfId ? "Open Document" : "Process Document"}
            </LoadingButton> */}
          </ButtonGroup>
        </Stack>
        {/* {onDownload && <DownloadButton onDownload={onDownload} />} */}
      </Stack>
    </Stack>
  );
}

export default WithAPICall(FileThumbnail);

FileThumbnail.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageView: PropTypes.bool,
  imgSx: PropTypes.object,
  onDownload: PropTypes.func,
  sx: PropTypes.object,
  tooltip: PropTypes.bool,
};

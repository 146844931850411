import { useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import axios from "axios";

let cancel = null;

export const useProtectedApi = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiCallPostCancellable = async (path, datum) => {
    if (cancel != null) {
      cancel();
    }
    const token = await getToken();
    try {
      const res = await axios.post(apiUrl + path, datum, {
        credentials: "include",
        headers: { Authorization: token },
        cancelToken: new axios.CancelToken(function executor(c) {
          cancel = c;
        }),
      });
      return res.data;
    } catch (err) {
      if (axios.isCancel(err)) {
      } else return err;
    }
  };

  const callApi = async (endpoint, data = null) => {
    if (!isLoaded || !isSignedIn) {
      throw new Error("User is not authenticated");
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = await getToken();
      const config = {
        method: "POST",
        url: `${baseUrl}${endpoint}`,
        headers: { Authorization: `Bearer ${token}` },
      };

      if (data) {
        config.data = data;
      }

      const response = await axios(config);
      return response.data;
    } catch (err) {
      //@ts-ignore
      setError(err.message || "An error occurred");
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const callUploadApi = async (path, fd, callback) => {
    const token = await getToken();
    try {
      const res = await axios.post(apiUrl + path, fd, {
        credentials: "include",
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          callback(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      });
      return res.data;
    } catch (err) {
      if (axios.isCancel(err)) {
      } else {
        //@ts-ignore
        setError(err.message || "An error occurred");
        return err;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    callApi,
    callUploadApi,
    apiCallPostCancellable,
    isLoading,
    error,
    isAuthenticated: isLoaded && isSignedIn,
  };
};
